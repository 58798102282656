import produce from 'immer';
import { map, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  IdType,
  Query,
  QueryBuilder,
} from '@supy/common';
import { BaseActivity } from '@supy/components';
import { SettingsState } from '@supy/settings';

import { Requisition, RequisitionFilters, RequisitionsQueryProps } from '../../core';
import { RequisitionsService } from '../../services';
import {
  RequisitionsCreate,
  RequisitionsDiscard,
  RequisitionsGetMany,
  RequisitionsGetOne,
  RequisitionsInitFilters,
  RequisitionsPatchFilters,
  RequisitionsPatchRequestMetadata,
  RequisitionsResetDetailed,
  RequisitionsResetFilters,
  RequisitionsResetSequence,
  RequisitionsSetFilters,
  RequisitionsUpdate,
} from '../actions';

const REQUISITIONS_STATE_TOKEN = new StateToken<RequisitionsStateModel>('requisitions');

export interface RequisitionsStateModel extends EntityListStateModel<Requisition> {
  readonly filters: RequisitionFilters;
  readonly detailedRequisition: Requisition;
  readonly requisitions: IdType[];
  readonly requestMetadata: RequisitionsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

export interface RequisitionsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
}

const DEFAULT_FILTERS: RequisitionFilters = {
  locations: [],
  categories: [],
  status: null,
  start: null,
  end: null,
  search: '',
};

const DEFAULT_REQUEST_META: RequisitionsRequestMetadata = {
  limit: 40,
  page: 0,
  retailerId: null,
};

const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<RequisitionsStateModel>({
  name: REQUISITIONS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    detailedRequisition: null,
    requisitions: [],
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_META,
    responseMetadata: RESPONSE_META_DEFAULT,
  },
})
@Injectable()
export class RequisitionsState extends EntityListState {
  readonly #requisitionsService = inject(RequisitionsService);
  readonly #store = inject(Store);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  @Selector()
  static requisitions(state: RequisitionsStateModel) {
    return EntityListState.all(state);
  }

  static requisition(id: string, next?: boolean) {
    return createSelector([RequisitionsState], (state: RequisitionsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static detailedRequisition(state: RequisitionsStateModel) {
    return state.detailedRequisition;
  }

  @Selector()
  static filters(state: RequisitionsStateModel) {
    return EntityListState.currentFilters<RequisitionFilters>(state);
  }

  @Selector()
  static isFirst(state: RequisitionsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: RequisitionsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static requestMetadata(state: RequisitionsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: RequisitionsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static currentRequisitionActivities(state: RequisitionsStateModel) {
    const requisition = RequisitionsState.detailedRequisition(state);

    return requisition?.activities?.reduce<BaseActivity[]>(
      (acc, cur) => [{ action: cur.type, user: cur.user, createdAt: cur.date }, ...acc],
      [],
    );
  }

  @Action(RequisitionsCreate)
  create(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsCreate) {
    return this.#requisitionsService.create(payload).pipe();
  }

  @Action(RequisitionsUpdate)
  update(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsUpdate) {
    return this.#requisitionsService.update(payload).pipe();
  }

  @Action(RequisitionsGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<RequisitionsStateModel>) {
    return this.#requisitionsService.getMany(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({ data: data.map(requisition => Requisition.deserialize(requisition)), metadata })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(RequisitionsGetOne, { cancelUncompleted: true })
  get(ctx: StateContext<RequisitionsStateModel>, { id }: RequisitionsGetOne) {
    return this.#requisitionsService.getDetailed(id).pipe(
      map(requisition => Requisition.deserialize(requisition)),
      tap(requisition => {
        ctx.patchState({ detailedRequisition: requisition });

        const entities = ctx.getState().entities;

        super.setSequence(ctx, {
          id: requisition.id,
          isFirst: entities?.length > 1 ? EntityListState.getIndex(id, entities) === 0 : true,
          isLast: entities?.length > 1 ? EntityListState.getIndex(id, entities) === entities?.length - 1 : true,
        });
      }),
    );
  }

  @Action(RequisitionsResetDetailed)
  resetDetailed(ctx: StateContext<RequisitionsStateModel>) {
    ctx.patchState({ detailedRequisition: null });
  }

  @Action(RequisitionsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(RequisitionsInitFilters)
  initFilters(ctx: StateContext<RequisitionsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(RequisitionsSetFilters)
  setFilters(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsSetFilters) {
    super.setFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new RequisitionsPatchRequestMetadata({ page: 0 }), new RequisitionsGetMany()]);
  }

  @Action(RequisitionsPatchFilters)
  patchFilters(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new RequisitionsPatchRequestMetadata({ page: 0 }), new RequisitionsGetMany()]);
  }

  @Action(RequisitionsResetFilters)
  resetFilters(ctx: StateContext<RequisitionsStateModel>, { payload }: RequisitionsResetFilters) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      ...payload,
    });

    ctx.dispatch([new RequisitionsPatchRequestMetadata({ page: 0 }), new RequisitionsGetMany()]);
  }

  @Action(RequisitionsDiscard)
  discard(ctx: StateContext<RequisitionsStateModel>, { id }: RequisitionsDiscard) {
    return this.#requisitionsService.discard(id).pipe(
      tap(() => {
        ctx.dispatch([new RequisitionsGetMany(), new RequisitionsGetOne(id)]);
      }),
    );
  }

  @Action(RequisitionsResetSequence)
  resetSequenceHandler(ctx: StateContext<RequisitionsStateModel>) {
    return super.resetSequence(ctx);
  }

  getQuery(
    state: RequisitionsStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<RequisitionsQueryProps> {
    const { categories, start, end, locations, search, status } = state.filters;

    const qb = new QueryBuilder<RequisitionsQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'deliveryDate',
          dir: 'desc',
        },
      ],
    });

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'categories.id',
        op: 'in',
        match: categories,
      });
    }

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        op: 'in',
        match: locations,
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        op: 'eq',
        match: status,
      });
    }

    if (start && end && !search) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'deliveryDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'deliveryDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    if (search) {
      qb.filtering.setFilter({
        by: 'number',
        op: 'like',
        match: search,
      });
    }

    if (state.requestMetadata?.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        op: 'eq',
        match: state.requestMetadata.retailerId,
      });
    }

    return qb.build();
  }
}
