import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { Order } from '../../../core';

@Component({
  selector: 'supy-retailer-order-details-drawer-header[order]',
  templateUrl: './retailer-order-details-drawer-header.component.html',
  styleUrls: ['./retailer-order-details-drawer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsDrawerHeaderComponent<T extends Pick<Order, 'id' | 'number' | 'status'>> {
  protected readonly forCk = input<boolean>();
  protected readonly forInventory = input<boolean>();
  protected readonly hidePrices = input<boolean>();
  protected readonly isInitialLoading = input<boolean>();
  protected readonly nextDisabled = input.required<boolean>();
  protected readonly order = input.required<T>();
  protected readonly previousDisabled = input.required<boolean>();
  protected readonly closeClicked = output<void>();
  protected readonly nextClicked = output<T>();
  protected readonly previousClicked = output<T>();
  protected readonly expandClicked = output<T>();
  protected readonly downloadPdfClicked = output<void>();
}
