import { ChannelItem } from '@supy/channel-items';
import { getApiDetailsDecorator, IdType, LocalizedData, PreferredType, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';

import { GrnChannelItemResponse, GrnRetailerItemSnapshot, GrnSupplierItem } from './grn-channel-item.model';

const ApiProperty = getApiDetailsDecorator<GrnChannelItemResponse>();

export abstract class GrnBaseChannelItem {
  protected constructor(args: NonFunctionProperties<GrnBaseChannelItem>) {
    this.id = args.id;
    this.name = args.name;
    this.uom = args.uom;
    this.packaging = args.packaging;
    this.itemCode = args.itemCode;
    this.newPackaging = args.newPackaging;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly itemCode?: string;
  @ApiProperty() readonly uom: Uom;
  @ApiProperty() readonly packaging?: Packaging;
  @ApiProperty() readonly newPackaging?: Packaging;
}

export type GrnChannelItemArgs = Omit<
  NonFunctionProperties<GrnChannelItem>,
  'pricingUnitName' | 'packagingAtomQuantity' | 'pricingUnitAtomQuantity'
>;

export class GrnChannelItem extends GrnBaseChannelItem {
  private constructor(args: GrnChannelItemArgs) {
    super(args);
    this.retailerItem = args.retailerItem;
    this.supplierItem = args.supplierItem;
    this.preferredType = args.preferredType;
    this.inventoryItem = args.inventoryItem;
    this.createdAt = args.createdAt;
    this.pricingUnitName = this.supplierItem?.invoiceUnit?.name ?? null;
    this.packagingAtomQuantity = this.packaging.quantity.baseUnit * this.packaging.baseUnit.conversionToAtom;
    this.pricingUnitAtomQuantity = this.supplierItem?.invoiceUnit?.conversionToAtom ?? null;
  }

  @ApiProperty() readonly retailerItem?: GrnRetailerItemSnapshot;
  @ApiProperty() readonly supplierItem?: GrnSupplierItem;
  @ApiProperty() readonly preferredType?: PreferredType;
  @ApiProperty() readonly inventoryItem?: IdType;
  @ApiProperty() readonly createdAt?: Date;
  readonly pricingUnitName: string;
  readonly packagingAtomQuantity: number;
  readonly pricingUnitAtomQuantity: number;

  static deserialize(data: GrnChannelItemResponse): GrnChannelItem {
    return new GrnChannelItem({
      id: data.id,
      name: data.name,
      itemCode: data.itemCode,
      uom: data.uom,
      packaging: data.packaging,
      newPackaging: data.newPackaging,
      retailerItem: data.retailerItem,
      supplierItem: data.supplierItem,
      preferredType: data.preferredType,
      inventoryItem: data.inventoryItem,
      createdAt: data.createdAt,
    });
  }

  static fromInventory(data: ChannelItem, { uom }: InventoryDeserializedArgs): GrnChannelItem {
    return new GrnChannelItem({
      id: data.id,
      name: data.name,
      itemCode: data.itemCode,
      uom,
      packaging: data.packaging,
      retailerItem: data.retailerItem,
      supplierItem: data.supplierItem,
      preferredType: data.preferredType,
      inventoryItem: data.inventoryItem,
      createdAt: data.createdAt,
    });
  }
}

interface InventoryDeserializedArgs {
  readonly uom: Uom;
}
