import { BranchRegion, getApiDetailsDecorator, SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  RequisitionByPackagingLocationsGridRowType,
  RequisitionChannelItem,
  RequisitionReplaceGroupedPackagingSupplierRequest,
  RequisitionReplacePackagingSupplierRequest,
  RequisitionsByPackagingLocationResponse,
  SimpleRequisition,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionsByPackagingLocationResponse>();

export const requisitionByPackagingLocationRowType = RequisitionByPackagingLocationsGridRowType.Location;

export class RequisitionByPackagingLocation {
  constructor(args: NonFunctionProperties<RequisitionByPackagingLocation>) {
    this.id = args.id;
    this.name = args.name;
    this.branch = args.branch;
    this.quantity = args.quantity;
    this.selectedChannelItem = args.selectedChannelItem;
    this.channelItems = args.channelItems;
    this.requisitions = args.requisitions;
    this.rowType = args.rowType;
    this.subTotal = args.subTotal;
    this.region = args.region;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly branch: SkeletonObjectType;
  @ApiProperty() readonly channelItems: RequisitionChannelItem[];
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly requisitions: SimpleRequisition[];
  @ApiProperty() readonly selectedChannelItem: RequisitionChannelItem;
  readonly subTotal: number;
  readonly region: BranchRegion;
  readonly rowType: RequisitionByPackagingLocationsGridRowType;

  static deserialize(
    data: RequisitionsByPackagingLocationResponse,
    context: DesrializeArgs,
  ): RequisitionByPackagingLocation {
    return new RequisitionByPackagingLocation({
      id: data.id,
      name: `${data.branch.name} - ${data.name}`,
      branch: data.branch,
      quantity: data.quantity,
      selectedChannelItem: data.selectedChannelItem,
      channelItems: data.channelItems,
      requisitions: data.requisitions,
      subTotal: data.quantity * (data.selectedChannelItem?.price ?? 0),
      region: context.region,
      rowType: requisitionByPackagingLocationRowType,
    });
  }

  static serialize(
    data: RequisitionByPackagingLocation,
    packagingId: string,
  ): RequisitionReplacePackagingSupplierRequest {
    return {
      packagingId,
      requisitionIds: data.requisitions.map(requisition => requisition.id),
      selectedChannelItemId: data.selectedChannelItem.id,
    };
  }

  static serializeGrouped(
    data: RequisitionByPackagingLocation[],
    packagingId: string,
  ): RequisitionReplaceGroupedPackagingSupplierRequest {
    return {
      groups: data.map(row => RequisitionByPackagingLocation.serialize(row, packagingId)),
    };
  }
}

interface DesrializeArgs {
  readonly region: BranchRegion;
}
