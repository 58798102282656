<div class="retailer-linked-grns">
  @if (grns()?.length > 0) {
    @for (grn of grns(); track grn?.id) {
      <div class="retailer-linked-grns__row">
        <h6 (click)="navigateToGrn(grn)">{{ grn.document.number }}</h6>
        <supy-icon-status-badge
          width="100%"
          weight="normal"
          [status]="statusBadgeMapper[grn.status]"
          [text]="statusNameMapper[grn.status]"
        />
      </div>
    }
  } @else {
    <div class="retailer-linked-grns__empty-template">
      <supy-icon name="exclamation-hex" size="medium" />
      <span i18n="@@orders.grns.noGrns">No GRNs</span>
    </div>
  }
</div>
