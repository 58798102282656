import { ChannelItem } from '@supy/channel-items';
import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { Packaging } from '@supy/packaging';
import { getLocalizedName } from '@supy/settings';

import { SupplierReturnItemResponse } from './supplier-return-item.model';
import { SupplierReturnItemTaxRate } from './supplier-return-item-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<SupplierReturnItemResponse>();

export abstract class BaseSupplierReturnItem {
  protected constructor(args: NonFunctionProperties<BaseSupplierReturnItem>) {
    this.channelItemId = args.channelItemId;
    this.comment = args.comment;
    this.id = args.id;
    this.price = args.price;
    this.quantity = args.quantity;
    this.taxRate = args.taxRate;
  }

  @ApiProperty() readonly comment?: string;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly quantity: number;
  @ApiProperty({ key: 'appliedTax' }) readonly taxRate: SupplierReturnItemTaxRate | null;
  readonly channelItemId: string;
}

export class SupplierReturnItem extends BaseSupplierReturnItem {
  private constructor(args: NonFunctionProperties<SupplierReturnItem>) {
    super(args);

    this.code = args.code ?? null;
    this.name = args.name;
    this.inventoryItemId = args.inventoryItemId;
    this.packaging = args.packaging;
    this.newPackaging = args.newPackaging;
  }

  readonly code: Nullable<string>;
  readonly name: string;
  readonly inventoryItemId: string;
  readonly packaging?: Packaging;
  readonly newPackaging?: Packaging;

  static deserialize(data: SupplierReturnItemResponse): SupplierReturnItem {
    return new SupplierReturnItem({
      channelItemId: data.channelItem.id,
      code: data.ckItem ? data.ckItem.packaging.code : data.channelItem.supplierItem?.code,
      comment: data.comment,
      id: data.id,
      inventoryItemId: data.ckItem ? data.ckItem.inventoryItem : data.channelItem.inventoryItem,
      name: getLocalizedName(data.channelItem.name),
      price: data.price ?? 0,
      quantity: data.quantity ?? 0,
      taxRate: data.appliedTax ? SupplierReturnItemTaxRate.deserialize(data.appliedTax) : null,
      packaging: data.channelItem?.packaging,
      newPackaging: data.channelItem?.newPackaging,
    });
  }

  static fromInventoryItem({
    id,
    price,
    quantity,
    itemCode,
    name,
    taxRate,
    inventoryItem,
  }: Pick<ChannelItem, 'id' | 'price' | 'quantity' | 'itemCode' | 'name' | 'inventoryItem'> & {
    readonly taxRate: SupplierReturnItemTaxRate;
  }): SupplierReturnItem {
    return new SupplierReturnItem({
      channelItemId: id,
      code: itemCode,
      comment: null,
      id: crypto.randomUUID(),
      inventoryItemId: inventoryItem.id,
      name: getLocalizedName(name),
      price,
      quantity,
      taxRate,
    });
  }
}
