import {
  ApproveRequisitionsRequest,
  DiscardRequisitionsRequest,
  ProcessRequisitionsRequest,
  RequisitionFilters,
  RequisitionsByLocationGetDetailedRequest,
} from '../../core';
import { RequisitionsByLocationRequestMetadata } from '../state';

export class AggregatedRequisitionGetMany {
  static readonly type = '[AggregatedRequisitions] Get Many';
}

export class RequisitionByLocationGetDetailed {
  static readonly type = '[AggregatedRequisitions] Get Detailed';

  constructor(readonly payload: RequisitionsByLocationGetDetailedRequest) {}
}

export class AggregatedRequisitionInitFilters {
  static readonly type = '[AggregatedRequisitions] Init Filters';
}

export class AggregatedRequisitionSetFilters {
  static readonly type = '[AggregatedRequisitions] Set Filters';

  constructor(readonly payload: RequisitionFilters) {}
}

export class AggregatedRequisitionPatchFilters {
  static readonly type = '[AggregatedRequisitions] Patch Filters';

  constructor(readonly payload: Partial<RequisitionFilters>) {}
}

export class AggregatedRequisitionResetFilters {
  static readonly type = '[AggregatedRequisitions] Reset Filters';

  constructor(
    readonly payload: Partial<RequisitionFilters>,
    readonly skipFetching?: boolean,
  ) {}
}

export class AggregatedRequisitionPatchRequestMetadata {
  static readonly type = '[AggregatedRequisitions] Patch Request Metadata';

  constructor(readonly payload: Partial<RequisitionsByLocationRequestMetadata>) {}
}

export class AggregatedRequisitionDiscard {
  static readonly type = '[AggregatedRequisitions] Discard';

  constructor(readonly payload: DiscardRequisitionsRequest) {}
}

export class AggregatedRequisitionApprove {
  static readonly type = '[AggregatedRequisitions] Approve';

  constructor(readonly payload: ApproveRequisitionsRequest) {}
}

export class AggregatedRequisitionProcess {
  static readonly type = '[AggregatedRequisitions] Process';

  constructor(readonly payload: ProcessRequisitionsRequest) {}
}

export class AggregatedRequisitionResetSequence {
  static readonly type = '[AggregatedRequisitions] Reset Sequence';
}
