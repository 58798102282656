import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import {
  CkOrderStatusNameMapper,
  InventoryOrderStatusNameMapper,
  OrderStatus,
  OrderStatusBadgeMapper,
  ProcurementOrderStatusNameMapper,
} from '../../../core';

@Component({
  selector: 'supy-retailer-order-status-badge',
  template: ` <supy-icon-status-badge [status]="badgeStatus()" [text]="badgeText()" width="100%" weight="normal" /> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderStatusBadgeComponent {
  protected readonly status = input.required<OrderStatus>();
  protected readonly forInventory = input<boolean>();
  protected readonly forCk = input<boolean>();
  protected readonly badgeStatus = computed(() => OrderStatusBadgeMapper[this.status()]);

  protected readonly badgeText = computed<string>(() => {
    const status = this.status();

    return this.forInventory()
      ? InventoryOrderStatusNameMapper[status]
      : this.forCk()
        ? CkOrderStatusNameMapper[status]
        : ProcurementOrderStatusNameMapper[status];
  });
}
