import { map, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, QueryBuilder } from '@supy/common';

import { Order, OrderRequestProps } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class OrdersResolver {
  readonly #ordersService = inject(OrdersService);

  resolve(): Promise<IQueryResponse<Order>> | Observable<IQueryResponse<Order>> {
    const qb = new QueryBuilder<OrderRequestProps>({
      filtering: [],
      paging: { offset: 0, limit: 50 },
    });

    return this.#ordersService.getMany(qb.build()).pipe(
      map(({ data, metadata }) => ({
        data: Order.deserializeList(data),
        metadata,
      })),
    );
  }
}
