import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { GrnStatusBadgeMapper, GrnStatusNameMapper, SimpleGrn } from '../../../core';

@Component({
  selector: 'supy-retailer-linked-grns',
  templateUrl: './retailer-linked-grns.component.html',
  styleUrls: ['./retailer-linked-grns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerLinkedGrnsComponent {
  protected readonly grns = input.required<SimpleGrn[]>();

  protected readonly statusBadgeMapper = GrnStatusBadgeMapper;
  protected readonly statusNameMapper = GrnStatusNameMapper;

  protected navigateToGrn(grn: SimpleGrn): void {
    window.open(`/accounting/grns/${grn.id}/details`, '_blank');
  }
}
