import { formatDate } from '@angular/common';

import { getApiDetailsDecorator, isOneOf, SimpleUser, SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { RequisitionItemByLocation } from './requisition-item-by-location.entity';
import {
  RequisitionByLocationComment,
  RequisitionByLocationResponse,
  RequisitionCategory,
  RequisitionStatus,
  RequisitionTotals,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionByLocationResponse>();

type RequisitionByLocationArgs = Omit<
  RequisitionByLocation,
  'formattedUpdatedAt' | 'formattedExpectedDeliveryDate' | 'isPending' | 'isApproved' | 'isEditable' | 'isProcessable'
>;

export class RequisitionByLocation {
  private constructor(args: NonFunctionProperties<RequisitionByLocationArgs>) {
    this.id = args.id;
    this.branch = args.branch;
    this.categories = args.categories;
    this.comment = args.comment;
    this.deliveryDate = args.deliveryDate;
    this.hasDeletedItems = args.hasDeletedItems;
    this.items = args.items;
    this.location = args.location;
    this.numItems = args.numItems;
    this.status = args.status;
    this.totals = args.totals;
    this.updatedAt = args.updatedAt;
    this.users = args.users;
    this.comments = args.comments ?? [];

    // Computed properties
    this.formattedExpectedDeliveryDate = this.deliveryDate ? formatDate(this.deliveryDate, 'dd/M/yyyy', 'en-GB') : null;
    this.formattedUpdatedAt = this.updatedAt ? formatDate(this.updatedAt, 'hh:mm a - dd/M/yyyy', 'en-GB') : null;
    this.isApproved = this.status === RequisitionStatus.Approved;
    this.isEditable = isOneOf(this.status, [RequisitionStatus.Pending, RequisitionStatus.Approved]);
    this.isPending = this.status === RequisitionStatus.Pending;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly branch: SkeletonObjectType;
  @ApiProperty() readonly categories: RequisitionCategory[];
  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly hasDeletedItems: boolean;
  @ApiProperty() readonly items: RequisitionItemByLocation[];
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly numItems: number;
  @ApiProperty() readonly status: RequisitionStatus;
  @ApiProperty() readonly totals: RequisitionTotals;
  @ApiProperty() readonly updatedAt: Date;
  @ApiProperty() readonly users: SimpleUser[];
  @ApiProperty() readonly comments: RequisitionByLocationComment[];

  // Computed properties
  readonly formattedExpectedDeliveryDate: Nullable<string>;
  readonly formattedUpdatedAt: Nullable<string>;
  readonly isApproved: boolean;
  readonly isEditable: boolean;
  readonly isPending: boolean;
  readonly isProcessable: boolean;

  static deserialize(data: RequisitionByLocationResponse): RequisitionByLocation {
    return new RequisitionByLocation({
      id: data.id,
      branch: data.branch,
      categories: data.categories,
      comment: data.comment,
      deliveryDate: data.deliveryDate,
      hasDeletedItems: data.hasDeletedItems,
      items: data.items?.map(RequisitionItemByLocation.deserialize) ?? [],
      location: data.location,
      numItems: data.numItems,
      status: data.status,
      totals: data.totals,
      updatedAt: data.updatedAt,
      users: data.users ?? [],
      comments: data.comments,
    });
  }
}
