import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query, UploadFileResponse } from '@supy/common';

import { ORDERS_BFF_URI, ORDERS_URI } from '../../config';
import {
  AggregatedOrdersResponse,
  CreateOrderRequest,
  DetailedOrderResponse,
  DownloadDeliveryNoteResponse,
  DownloadOrderPdfResponse,
  GetOrderByEmailResponse,
  ModifyOrderRequest,
  Order,
  OrderInvoice,
  OrderRequestProps,
  OrderResponse,
  RejectOrderRequest,
  ReturnOrderItemsRequest,
  ShipManyRequest,
  UpdateOrderRequest,
} from '../../core';

@Injectable()
export class OrdersService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(ORDERS_URI);
  readonly #uriBff = inject(ORDERS_BFF_URI);

  get(id: string): Observable<OrderResponse> {
    return this.#httpClient.get<OrderResponse>(`${this.#uriBff}/${id}`);
  }

  getDetailed(id: string): Observable<DetailedOrderResponse> {
    return this.#httpClient.get<DetailedOrderResponse>(`${this.#uriBff}/${id}/detailed`);
  }

  getMany(
    query: Query<OrderRequestProps>,
    options?: { readonly excludeItems: boolean },
  ): Observable<IQueryResponse<OrderResponse>> {
    return this.#httpClient.post<IQueryResponse<OrderResponse>>(
      `${this.#uriBff}/get`,
      query,
      options?.excludeItems
        ? {
            headers: { 'X-Version': '2' },
          }
        : undefined,
    );
  }

  getManyWithStats(query: Query<OrderRequestProps>): Observable<IQueryResponse<OrderResponse>> {
    return this.#httpClient.post<IQueryResponse<OrderResponse>>(`${this.#uriBff}/get-with-stats`, query);
  }

  getManyAggregated({ orders }: { orders: IdType[] }): Observable<AggregatedOrdersResponse> {
    return this.#httpClient.post<AggregatedOrdersResponse>(`${this.#uriBff}/aggregated`, { orders });
  }

  update(id: string, body: UpdateOrderRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${id}/updates`, body);
  }

  close(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${id}/close`, null);
  }

  closeMany(ids: IdType[]): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/close`, { orders: ids });
  }

  returnItems(orderId: string, body: ReturnOrderItemsRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${orderId}/return`, body);
  }

  getEmailed(emailId: string): Observable<GetOrderByEmailResponse> {
    return this.#httpClient.get<GetOrderByEmailResponse>(`${this.#uri}/email/${emailId}/info`);
  }

  updateEmailed(emailId: string, body: ModifyOrderRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/email/${emailId}/modify`, body);
  }

  confirmEmailed(emailId: string, payload?: { products: { productId: string; quantity: number }[] }): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/email/${emailId}/confirm`, payload);
  }

  rejectEmailed(emailId: string, reason: RejectOrderRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/email/${emailId}/reject`, reason);
  }

  downloadPdf(orderId: string): Observable<DownloadOrderPdfResponse> {
    return this.#httpClient.get<DownloadOrderPdfResponse>(`${this.#uriBff}/${orderId}/download/lpo`);
  }

  downloadDeliveryNote(orderId: string): Observable<DownloadDeliveryNoteResponse> {
    return this.#httpClient.get<DownloadDeliveryNoteResponse>(`${this.#uriBff}/${orderId}/download/delivery-note`);
  }

  downloadManyDeliveryNotes(orders: IdType[]): Observable<DownloadDeliveryNoteResponse> {
    return this.#httpClient.post<DownloadDeliveryNoteResponse>(`${this.#uriBff}/download/delivery-notes`, {
      orders,
    });
  }

  confirmMany(ids: IdType[]) {
    return this.#httpClient.post(`${this.#uriBff}/confirm`, { orderIds: ids });
  }

  shipMany({ orderIds, updateStock, shipDate }: ShipManyRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/ship`, { orderIds, updateStock, shipDate });
  }

  rejectMany(orderIds: IdType[]): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/reject`, { orderIds });
  }

  create(body: CreateOrderRequest): Observable<Order> {
    return this.#httpClient.post<Order>(`${this.#uriBff}`, body);
  }

  updateInvoice(orderId: string, invoice: OrderInvoice): Observable<void> {
    return this.#httpClient.put<void>(`${this.#uriBff}/${orderId}/invoice`, invoice);
  }

  uploadFile(file: FormData, orderId: string): Observable<UploadFileResponse> {
    return this.#httpClient.post<UploadFileResponse>(`${this.#uriBff}/${orderId}/invoice/image`, file);
  }

  modify(id: string, body: ModifyOrderRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uriBff}/${id}/modify`, body);
  }

  confirm(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${id}/confirm`, undefined);
  }

  reject(id: string, reason: RejectOrderRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${id}/reject`, reason);
  }

  reopen(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uriBff}/${id}/reopen`, null);
  }
}
