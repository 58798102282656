import { NonFunctionProperties } from '@supy/core';

import { RequisitionByPackagingLocationsGridRowType, RequisitionChannelItem } from './requisitions.model';

export const requisitionByPackagingLocationGroupRowType = RequisitionByPackagingLocationsGridRowType.Region;

export class RequisitionByPackagingLocationsGroup {
  constructor(args: NonFunctionProperties<RequisitionByPackagingLocationsGroup>) {
    this.id = args.id;
    this.name = args.name;
    this.channelItems = args.channelItems;
    this.selectedChannelItem = args.selectedChannelItem;
    this.aggregatedQuantity = args.aggregatedQuantity;
    this.price = args.price;
    this.rowType = args.rowType;
    this.isExpanded = args.isExpanded;
  }

  readonly id: string;
  readonly name: string;
  readonly channelItems: RequisitionChannelItem[];
  readonly selectedChannelItem: RequisitionChannelItem;
  readonly aggregatedQuantity: number;
  readonly price: number | string;
  readonly rowType: RequisitionByPackagingLocationsGridRowType;

  isExpanded: boolean;

  static deserialize(context: DeserializeArgs): RequisitionByPackagingLocationsGroup {
    return new RequisitionByPackagingLocationsGroup({
      id: context.id,
      name: context.name,
      channelItems: context.channelItems,
      selectedChannelItem: context.selectedChannelItem,
      aggregatedQuantity: context.aggregatedQuantity,
      price: this.getAggregatedPrice(context.prices),
      rowType: requisitionByPackagingLocationGroupRowType,
      isExpanded: true,
    });
  }

  static getAggregatedPrice(prices: number[]): number | string {
    const firstPrice = prices.at(0);

    return prices?.every(price => price === firstPrice)
      ? firstPrice
      : $localize`:@@common.multiplePrices:Multiple Prices`;
  }
}

interface DeserializeArgs {
  readonly id: string;
  readonly name: string;
  readonly channelItems: RequisitionChannelItem[];
  readonly selectedChannelItem: RequisitionChannelItem;
  readonly aggregatedQuantity: number;
  readonly prices: number[];
}
