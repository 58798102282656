import produce from 'immer';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { Nullable } from '@supy/core';
import { CurrentRetailerState } from '@supy/retailers';
import { RetailerSettingsState, SettingsState } from '@supy/settings';

import { AggregatedOrders, DetailedOrder, Order, OrderRequestProps, OrderStatus } from '../../core';
import { downloadProcurementOrdersList } from '../../helpers';
import { OrdersService } from '../../services';
import {
  AddOrderItems,
  OrdersClose,
  OrdersCloseMany,
  OrdersCreate,
  OrdersDownloadDeliveryNote,
  OrdersDownloadDeliveryNoteMany,
  OrdersDownloadLPO,
  OrdersExport,
  OrdersGetAggregated,
  OrdersGetDetailed,
  OrdersGetMany,
  OrdersGetUnlinked,
  OrdersInitFilters,
  OrdersPatchFilter,
  OrdersPatchRequestMeta,
  OrdersReopen,
  OrdersReset,
  OrdersResetAggregated,
  OrdersResetDetailed,
  OrdersResetFilter,
  OrdersResetUnlinked,
  OrdersSetFilter,
  OrdersSetSequence,
  OrdersUpdate,
  OrdersUpdateInvoice,
  OrdersUploadInvoiceImage,
  ReturnOrderItems,
} from '../actions';

const ORDERS_STATE_TOKEN = new StateToken<OrdersStateModel>('orders');

export interface OrdersStateModel extends EntityListStateModel<Order> {
  readonly filters: OrdersFilters;
  readonly requestMetadata: OrdersRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly detailedOrder: DetailedOrder;
  readonly aggregatedOrders: AggregatedOrders | null;
  readonly unlinkedOrders: Order[];
}

export interface OrdersFilters {
  readonly status: Nullable<OrderStatus>;
  readonly branches: Nullable<string[]>;
  readonly start: Nullable<Date | number | string>;
  readonly end: Nullable<Date | number | string>;
  readonly suppliers: Nullable<string[]>;
  readonly lpoRestaurant: Nullable<string>;
}

export interface OrderSorting {
  readonly value: string;
  readonly field: string;
}

export interface OrdersRequestMetadata extends BaseRequestMetadata {
  readonly branchIds: Nullable<string[]>;
  readonly supplierIds: Nullable<string[]>;
  readonly channelIds: Nullable<string[]>;
  readonly retailerId: Nullable<string>;
}

const FILTERS_DEFAULT: OrdersFilters = {
  status: null,
  branches: [],
  start: null,
  end: null,
  suppliers: [],
  lpoRestaurant: null,
};

const REQUEST_META_DEFAULT: OrdersRequestMetadata = {
  page: 0,
  limit: 25,
  branchIds: [],
  supplierIds: [],
  channelIds: [],
  retailerId: null,
};
const RESPONSE_META_DEFAULT: BaseResponseMetadata = { count: 0, total: 0 };

@State<OrdersStateModel>({
  name: ORDERS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    detailedOrder: null,
    aggregatedOrders: null,
    unlinkedOrders: [],
  },
})
@Injectable()
export class OrdersState extends EntityListState {
  readonly #ordersService = inject(OrdersService);
  readonly #store = inject(Store);
  readonly #route = inject(ActivatedRoute);
  readonly #queryParamMap = toSignal(this.#route.queryParamMap);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  @Selector()
  static orders(state: OrdersStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentOrder(state: OrdersStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: OrdersStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: OrdersStateModel) {
    return EntityListState.isLast(state);
  }

  static order(id: string, next?: boolean) {
    return createSelector([OrdersState], (state: OrdersStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: OrdersStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: OrdersStateModel) {
    return EntityListState.currentFilters<OrdersFilters>(state);
  }

  @Selector()
  static requestMetadata(state: OrdersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: OrdersStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static detailedOrder(state: OrdersStateModel) {
    return state.detailedOrder;
  }

  @Selector()
  static aggregatedOrders(state: OrdersStateModel) {
    return state.aggregatedOrders;
  }

  @Selector()
  static linkableOrders(state: OrdersStateModel) {
    return state.unlinkedOrders.filter(order => order?.linkedGrnIds?.length === 0);
  }

  @Action(OrdersInitFilters)
  initFilters(ctx: StateContext<OrdersStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(OrdersSetFilter)
  setFilters(ctx: StateContext<OrdersStateModel>, { payload }: OrdersSetFilter) {
    super.setFilter(ctx, payload, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersPatchFilter)
  patchFilters(ctx: StateContext<OrdersStateModel>, { payload }: OrdersPatchFilter) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersResetFilter)
  resetFilters(ctx: StateContext<OrdersStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<OrdersStateModel>, { payload }: OrdersPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(OrdersGetDetailed, { cancelUncompleted: true })
  getDetailed(ctx: StateContext<OrdersStateModel>, action: OrdersGetDetailed) {
    return this.#ordersService.getDetailed(action.payload.id).pipe(
      withLatestFrom(
        this.#store.selectOnce(CurrentRetailerState.suppliers),
        this.#store.selectOnce(RetailerSettingsState.defaultOnReceivingTax),
        this.#store.selectOnce(RetailerSettingsState.taxes),
      ),
      tap(([response, suppliers, defaultOnReceivingTax, taxRates]) => {
        const detailedOrder = DetailedOrder.deserialize(response, {
          suppliers,
          defaultOnReceivingTax,
          taxRates,
        });

        super.setOne(ctx, detailedOrder);

        ctx.patchState({
          detailedOrder,
        });
      }),
    );
  }

  @Action(OrdersExport)
  exportOrders(ctx: StateContext<OrdersStateModel>) {
    const state = ctx.getState();
    const query = this.getQuery(state, { noLimit: true });
    const currencyPrecision = this.#store.selectSignal(SettingsState.currencyPrecision);
    const currency = this.#store.selectSignal(SettingsState.currency);

    return this.#ordersService.getMany(query, { excludeItems: true }).pipe(
      tap(ordersData => {
        const orders = Order.deserializeList(ordersData.data);

        void downloadProcurementOrdersList(orders, currencyPrecision(), currency());
      }),
    );
  }

  @Action(OrdersResetDetailed)
  resetDetailed(ctx: StateContext<OrdersStateModel>) {
    return ctx.patchState({ detailedOrder: null });
  }

  @Action(OrdersReset)
  reset(ctx: StateContext<OrdersStateModel>) {
    return super.setMany(ctx, []);
  }

  @Action(OrdersGetAggregated)
  getAggregatedOrders(ctx: StateContext<OrdersStateModel>, action: OrdersGetAggregated) {
    return this.#ordersService.getManyAggregated(action.payload).pipe(
      tap(response => {
        ctx.patchState({
          aggregatedOrders: AggregatedOrders.deserialize(response),
        });
      }),
    );
  }

  @Action(OrdersResetAggregated)
  resetAggregatedOrders(ctx: StateContext<OrdersStateModel>) {
    return ctx.patchState({
      aggregatedOrders: null,
    });
  }

  @Action(OrdersGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<OrdersStateModel>, { excludeItems }: OrdersGetMany) {
    return this.#ordersService.getMany(this.getQuery(ctx.getState()), { excludeItems }).pipe(
      map(({ data, metadata }) => ({
        data: Order.deserializeList(data),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(AddOrderItems)
  addItems(ctx: StateContext<OrdersStateModel>, { items }: AddOrderItems) {
    ctx.setState(
      produce(draft => {
        draft.detailedOrder = {
          ...draft.detailedOrder,
          items: [...draft.detailedOrder.items, ...items],
        };
      }),
    );
  }

  @Action(OrdersCreate)
  create(ctx: StateContext<OrdersStateModel>, { payload }: OrdersCreate) {
    return this.#ordersService.create(payload).pipe(
      tap(order => {
        ctx.setState(
          produce(draft => {
            draft.entities.push(order);
            draft.sequence.id = order.id;
          }),
        );
      }),
    );
  }

  @Action(OrdersUpdate, { cancelUncompleted: true })
  update(ctx: StateContext<OrdersStateModel>, { payload: { body, id } }: OrdersUpdate) {
    return this.#ordersService.update(id, body).pipe(
      switchMap(() => ctx.dispatch(new OrdersGetDetailed({ id }))),
      tap(() => {
        ctx.setState(
          produce(draft => {
            if (this.#queryParamMap().get('status')) {
              draft.entities = draft.entities.filter(entity => entity.id !== id);
            } else {
              draft.entities = draft.entities.map(entity =>
                entity.id === id
                  ? {
                      ...entity,
                      status: body.type as unknown as OrderStatus,
                    }
                  : entity,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(OrdersUpdateInvoice, { cancelUncompleted: true })
  updateInvoice(_: StateContext<OrdersStateModel>, { payload: { body, id } }: OrdersUpdateInvoice) {
    return this.#ordersService.updateInvoice(id, body);
  }

  @Action(OrdersUploadInvoiceImage, { cancelUncompleted: true })
  uploadInvoiceImage(_: StateContext<OrdersStateModel>, { payload: { formData, id } }: OrdersUploadInvoiceImage) {
    return this.#ordersService.uploadFile(formData, id);
  }

  @Action(OrdersDownloadDeliveryNote, { cancelUncompleted: true })
  downloadDeliveryNote(_: StateContext<OrdersStateModel>, { orderId }: OrdersDownloadDeliveryNote) {
    return this.#ordersService.downloadDeliveryNote(orderId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(OrdersDownloadDeliveryNoteMany, { cancelUncompleted: true })
  downloadDeliveryNoteMany(_: StateContext<OrdersStateModel>, { orders }: OrdersDownloadDeliveryNoteMany) {
    return this.#ordersService.downloadManyDeliveryNotes(orders).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(OrdersDownloadLPO, { cancelUncompleted: true })
  downloadLPO(_: StateContext<OrdersStateModel>, { orderId }: OrdersDownloadLPO) {
    return this.#ordersService.downloadPdf(orderId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(OrdersClose, { cancelUncompleted: true })
  close(ctx: StateContext<OrdersStateModel>, { payload: { id } }: OrdersClose) {
    return this.#ordersService.close(id).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            if (this.#queryParamMap().get('status')) {
              draft.entities = draft.entities.filter(entity => entity.id !== id);
            } else {
              draft.entities = draft.entities.map(entity =>
                entity.id === id
                  ? {
                      ...entity,
                      status: OrderStatus.Received,
                    }
                  : entity,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(OrdersCloseMany, { cancelUncompleted: true })
  closeMany(_: StateContext<OrdersStateModel>, { payload: { orders } }: OrdersCloseMany) {
    return this.#ordersService.closeMany(orders);
  }

  @Action(ReturnOrderItems, { cancelUncompleted: true })
  returnItems(_: StateContext<OrdersStateModel>, { payload: { id, body } }: ReturnOrderItems) {
    return this.#ordersService.returnItems(id, body);
  }

  @Action(OrdersReopen)
  reopen(_: StateContext<OrdersStateModel>, { payload: { id } }: OrdersReopen) {
    return this.#ordersService.reopen(id);
  }

  @Action(OrdersSetSequence)
  setOrderSequence(ctx: StateContext<OrdersStateModel>, { payload }: OrdersSetSequence) {
    ctx.setState(
      produce(draft => {
        draft.sequence = { ...draft.sequence, ...payload };
      }),
    );
  }

  @Action(OrdersGetUnlinked, { cancelUncompleted: true })
  getManyUnlinked(ctx: StateContext<OrdersStateModel>, { payload }: OrdersGetUnlinked) {
    const query = new Query<OrderRequestProps>({
      filtering: [
        {
          by: 'status',
          op: 'in',
          match: [OrderStatus.Submitted, OrderStatus.Confirmed, OrderStatus.PartialReceived],
        },
        {
          by: 'supplier.id',
          op: 'eq',
          match: payload.supplierId,
        },
        {
          by: 'branch.id',
          op: 'eq',
          match: payload.locationId,
        },
      ],
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
      paging: {
        limit: 50,
        offset: 0,
      },
    });

    return this.#ordersService
      .getMany(query, { excludeItems: true })
      .pipe(tap(({ data }) => ctx.patchState({ unlinkedOrders: Order.deserializeList(data) })));
  }

  @Action(OrdersResetUnlinked)
  resetManyUnlinked(ctx: StateContext<OrdersStateModel>) {
    ctx.patchState({ unlinkedOrders: [] });
  }

  private getQuery(state: OrdersStateModel, options?: { noLimit: boolean }): Query<OrderRequestProps> {
    const { branches, start, end, status, suppliers, lpoRestaurant } = state.filters;
    const qb = new QueryBuilder<OrderRequestProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (state.requestMetadata.channelIds?.length) {
      qb.filtering.setFilter({
        by: 'channelId',
        match: state.requestMetadata.channelIds,
        op: 'in',
      });
    }

    if (status) {
      switch (status) {
        case OrderStatus.Received: {
          qb.filtering.setFilter({
            by: 'status',
            match: [OrderStatus.Received, OrderStatus.PartialReceived],
            op: 'in',
          });
          break;
        }
        case OrderStatus.Confirmed: {
          qb.filtering.setFilter({
            by: 'status',
            match: [OrderStatus.Confirmed, OrderStatus.Shipped],
            op: 'in',
          });
          break;
        }
        default:
          qb.filtering.setFilter({
            by: 'status',
            match: status,
            op: 'eq',
          });
      }
    }

    if (lpoRestaurant) {
      qb.filtering.setFilter({
        by: 'number',
        match: lpoRestaurant,
        op: 'like',
      });

      return qb.build();
    }

    if (branches?.length) {
      qb.filtering.setFilter({
        by: 'branch.id',
        match: branches,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplierId',
        match: suppliers,
        op: 'in',
      });
    }

    if (start && end) {
      if (start.toString() === end.toString()) {
        const nextDay = new Date();

        nextDay.setDate(nextDay.getDate() + 1);

        qb.filtering.withFiltering([
          { by: 'createdAt', op: 'lte', match: getShiftedDate(new Date(nextDay), this.#utcOffset()).getTime() },
          { by: 'createdAt', op: 'gte', match: getShiftedDate(new Date(Number(start)), this.#utcOffset()).getTime() },
        ]);
      } else {
        qb.filtering.withFiltering([
          { by: 'createdAt', op: 'lte', match: getShiftedDate(new Date(Number(end)), this.#utcOffset()).getTime() },
          { by: 'createdAt', op: 'gte', match: getShiftedDate(new Date(Number(start)), this.#utcOffset()).getTime() },
        ]);
      }
    }

    return qb.build();
  }
}
