import {
  RequisitionFilters,
  RequisitionReplaceGroupedPackagingSupplierRequest,
  RequisitionsByPackagingGetDetailedRequest,
} from '../../core';
import { RequisitionsByPackagingRequestMetadata } from '../state';

export class RequisitionsByPackagingGetMany {
  static readonly type = '[RequisitionsByPackaging] Get Many';
}

export class RequisitionsByPackagingGetDetailed {
  static readonly type = '[RequisitionsByPackaging] Get Detailed';

  constructor(readonly payload: RequisitionsByPackagingGetDetailedRequest) {}
}

export class RequisitionsByPackagingInitFilters {
  static readonly type = '[RequisitionsByPackaging] Init Filters';
}

export class RequisitionsByPackagingSetFilters {
  static readonly type = '[RequisitionsByPackaging] Set Filters';

  constructor(readonly payload: RequisitionFilters) {}
}

export class RequisitionsByPackagingPatchFilters {
  static readonly type = '[RequisitionsByPackaging] Patch Filters';

  constructor(readonly payload: Partial<RequisitionFilters>) {}
}

export class RequisitionsByPackagingResetFilters {
  static readonly type = '[RequisitionsByPackaging] Reset Filters';

  constructor(
    readonly payload: Partial<RequisitionFilters>,
    readonly skipFetching?: boolean,
  ) {}
}

export class RequisitionsByPackagingPatchRequestMetadata {
  static readonly type = '[RequisitionsByPackaging] Patch Request Metadata';

  constructor(readonly payload: Partial<RequisitionsByPackagingRequestMetadata>) {}
}

export class RequisitionsByPackagingReplacePackagingSupplier {
  static readonly type = '[RequisitionsByPackaging] Replace Packaging Supplier';

  constructor(readonly payload: RequisitionReplaceGroupedPackagingSupplierRequest) {}
}

export class RequisitionsByPackagingResetSequence {
  static readonly type = '[RequisitionsByPackaging] Reset Sequence';
}
