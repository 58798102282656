import { Currency } from '@supy.api/dictionaries';

import {
  Branch,
  Channel,
  IdType,
  LocalizedData,
  OutletData,
  PartyInfo,
  PreferredType,
  Retailer,
  SkeletonObjectType,
  Supplier,
  SupplierItem,
  TaxRate,
  Uom,
  User,
} from '@supy/common';
import { Country } from '@supy/countries';
import { Packaging } from '@supy/packaging';

import { SimpleGrnResponse } from '../grn';
import { OrderItemQuantity, ProductRetailerItem, SimpleChannel, StatefulOrderEntity } from './common-order.model';

export enum OrderStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  Confirmed = 'confirmed',
  Received = 'received',
  PartialReceived = 'partial-received',
  Discarded = 'discarded',
  Rejected = 'rejected',
  Shipped = 'shipped',
}

export enum OrderUpdateStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  Confirmed = 'confirmed',
  Received = 'received',
  PartialReceived = 'partial-received',
  Comment = 'comment',
  Shipped = 'shipped',
  Unshipped = 'unshipped',
  Discarded = 'discarded',
  Rejected = 'rejected',
  Modified = 'modified',
}

export enum CreationSourceEnum {
  Location = 'location',
  CentralKitchen = 'central-kitchen',
}

export enum DiscountType {
  Value = 'value',
  Percentage = 'percentage',
}

export interface OrderActivityResponse {
  readonly type: OrderUpdateStatus;
  readonly comment?: string;
  readonly user: User;
  readonly date: Date;
}

export interface OrderResponse {
  readonly activities: OrderActivityResponse[];
  readonly appliedTax: number;
  readonly channel: SimpleChannel;
  readonly channelPreferredType: PreferredType;
  readonly comment: string | null;
  readonly country: Country;
  readonly createdAt: Date;
  readonly creationSource: CreationSourceEnum;
  readonly deliveryDate: Date;
  readonly id: string;
  readonly invoice: OrderInvoice;
  readonly items?: OrderItemResponse[];
  readonly linkedGrns: IdType[];
  readonly modified: OrderItemResponse[];
  readonly modifiedTotal?: number;
  readonly number: string;
  readonly ordered: OrderItemResponse[];
  readonly orderedTotal: number;
  readonly partyInfo: PartyInfo;
  readonly preferredType?: PreferredType;
  readonly received?: OrderItemResponse[];
  readonly receivedTotal?: number;
  readonly retailerId: string;
  readonly status: OrderStatus;
  readonly submittedAt: Date;
  readonly supplierId: string;
  readonly updatedAt: Date;
  readonly vat?: number;
  readonly externalDocNumber?: string;
  readonly metadata?: {
    readonly closed?: boolean;
  };
}

export interface OrderItemResponse {
  readonly currency?: Currency;
  readonly itemCode?: string;
  readonly name?: string;
  readonly name_ar?: string;
  readonly orderedQty?: number;
  readonly preferredType?: PreferredType;
  readonly price?: number;
  readonly productId: string;
  readonly quantity: number;
  readonly unit?: string;
}

export interface DetailedOrderResponse {
  readonly activities: OrderActivityResponse[];
  readonly appliedTax?: TaxRate;
  readonly branch: Branch;
  readonly channel: SimpleChannel;
  readonly channelOrderIndex?: number;
  readonly ckItems?: DetailedOrderItemResponse[];
  readonly comment?: string;
  readonly country: Country;
  readonly createdAt: Date;
  readonly shipDate?: Date;
  readonly creationSource?: CreationSourceEnum;
  readonly deliveryDate?: Date;
  readonly discount?: number;
  readonly fees?: number;
  readonly id: string;
  readonly invoice?: OrderInvoice;
  readonly items: DetailedOrderItemResponse[];
  readonly linkedGrns: SimpleGrnResponse[];
  readonly number: string;
  readonly outlet?: OutletData;
  readonly retailer: Retailer;
  readonly status: OrderStatus;
  readonly submittedAt?: Date;
  readonly supplier: Supplier;
  readonly total: StatefulOrderEntity;
  readonly updatedAt: number;
  readonly user: User;
  readonly vat?: number;
  readonly metadata: {
    readonly closed?: boolean;
  };
}

export interface DetailedOrderItemResponse {
  readonly appliedTax?: TaxRate;
  readonly channelItem: string;
  readonly ckItem?: string;
  readonly comment?: string;
  readonly createdAt: string;
  readonly id: string;
  readonly inventoryItem?: IdType;
  readonly isCreatedByCk?: boolean;
  readonly itemCode?: string;
  readonly name: LocalizedData;
  readonly packaging: Packaging;
  readonly price: StatefulOrderEntity;
  readonly quantity: OrderItemQuantity;
  readonly retailerItem: ProductRetailerItem;
  readonly supplierItem?: SupplierItem;
  readonly unit: string;
}

export interface InvoiceNumberResponse {
  readonly prefixId: string;
  readonly value: string;
}

export interface DiscountResponse {
  readonly value: number;
  readonly type: DiscountType;
}

export interface GetOrderByEmailResponse {
  readonly channel: Channel;
  readonly order: Omit<OrderResponse, 'channel' | 'country'> & {
    channelId: string;
    countryId: string;
  };
  readonly products: EmailedOrderItemResponse[];
  readonly retailer: Branch;
  readonly supplier: Supplier;
  readonly user: User;
}

export interface EmailedOrderItemResponse extends OrderItemResponse {
  readonly receivedQty?: number;
  readonly modifiedQty?: number;
  readonly supplierItemName?: string;
  readonly appliedTax?: TaxRate;
  readonly orderedQty: number;
}

export interface DownloadOrderPdfResponse {
  signedUrl: string;
}

export interface DownloadDeliveryNoteResponse {
  signedUrl: string;
}

export interface ModifyOrderRequest {
  readonly products: ModifiedOrderItemRequest[];
}

export interface RejectOrderRequest {
  readonly message: string;
}

export interface ReturnOrderItemsRequest {
  readonly referenceId?: string;
  readonly products: ModifiedOrderItemRequest[];
}

export interface OrderModificationHistory {
  readonly name: string;
  readonly productId: string;
  readonly oldValue: number;
  readonly newValue: number;
  readonly unit: string;
}

export interface ModifiedOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface UpdateOrderRequest {
  readonly comment?: string;
  readonly deliveryDate?: Date;
  readonly invoice?: OrderInvoice;
  readonly modified?: UpdateOrderItemRequest[];
  readonly ordered?: UpdateOrderItemRequest[];
  readonly received?: UpdateOrderItemRequest[];
  readonly retailerId?: string;
  readonly supplierId?: string;
  readonly type: OrderUpdateStatus;
  readonly updateStock?: boolean;
  readonly shipDate?: Date;
}

export interface OrderInvoice {
  readonly numbers: string[];
  readonly images: string[];
}

export interface UpdateOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface CreateOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface CreateOrderRequest {
  readonly supplierId: string;
  readonly locationId: string;
  readonly channelId: string;
  readonly deliveryDate: Date;
  readonly comment?: string;
  readonly status: OrderStatus;
  readonly ordered: CreateOrderItemRequest[];
}

export interface OrderRequestProps {
  readonly 'branch.id': string;
  readonly 'branch.name': string;
  readonly 'channel.preferredType': PreferredType;
  readonly 'country.id': string;
  readonly 'customer.id': string;
  readonly 'products.preferredType': PreferredType;
  readonly 'supplier.id': string;
  readonly 'supplier.metadata.retailerId': string;
  readonly 'total.ordered': string;
  readonly 'total.received': string;
  readonly channelId: string;
  readonly createdAt: number;
  readonly id: string;
  readonly number: string;
  readonly retailerId: string;
  readonly status: OrderStatus;
  readonly supplierId: string;
}

export interface DatePeriodResponse {
  readonly startDate: Date;
  readonly endDate: Date;
}

export interface BranchQuantity {
  readonly quantity: number;
  readonly branch: SkeletonObjectType;
}

export interface OrderInvoice {
  readonly numbers: string[];
  readonly images: string[];
}

export interface AggregatedSupplierOrderItemResponse {
  readonly itemCode: string;
  readonly name: LocalizedData;
  readonly unit: Uom;
  readonly totalQuantity: number;
  readonly branchQuantities: BranchQuantity[];
}

export interface AggregatedSupplierOrderResponse extends OrderResponse {
  readonly confirmationLink: string;
}

export interface AggregatedSupplierOrdersResponse {
  readonly retailers: Retailer[];
  readonly orderPeriod: DatePeriodResponse;
  readonly deliveryPeriod: DatePeriodResponse;
  readonly branches: Branch[];
  readonly groupedItems: AggregatedSupplierOrderItemResponse[];
  readonly aggregatedOrders: AggregatedSupplierOrderResponse[];
}

export interface DownloadOrderPdfResponse {
  signedUrl: string;
}

export interface ShipManyRequest {
  readonly orderIds: IdType[];
  readonly updateStock: boolean;
  readonly shipDate: Date;
}
