<supy-tabview-select [items]="tabs()" [formControl]="tabFormControl" (selectedChanged)="onSelectTab($event)" />
<div class="retailer-orders-grid-filters__header">
  <supy-filter-group
    [filterGroup]="filtersGroup"
    [latency]="400"
    [predefinedDateRanges]="predefinedDateRanges"
    (filtersChange)="onFiltersChange($event)"
    (filtersReset)="filtersCleared.emit()"
  />
  @if (isB2b()) {
    <div class="retailer-orders-grid-filters__legend">
      <div class="retailer-orders-grid-filters__legend-item">
        <supy-icon name="shop-add" color="primary" size="small" />
        <span i18n="@@orders.inventory.grid.orderCreatedByCk">Order Created by CK</span>
      </div>
    </div>
  }
</div>
