import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { GetOrderByEmailResponse } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class SubmitOrderResolver {
  readonly #ordersService = inject(OrdersService);

  resolve(route: ActivatedRouteSnapshot): Observable<GetOrderByEmailResponse> {
    return this.#ordersService.getEmailed(route.paramMap.get('id'));
  }
}
