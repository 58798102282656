import produce from 'immer';
import { catchError, EMPTY, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, Query, QueryBuilder, QueryPaging } from '@supy/common';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import { CreditNote, CreditNoteQueryParams, CreditNoteStatus, GrnCreditNoteType } from '../../core';
import { downloadCreditNotesList } from '../../helpers';
import { CreditNoteService } from '../../services';
import {
  CreditNoteArchiveMany,
  CreditNoteGet,
  CreditNoteGetMany,
  CreditNoteGetManyBrGrnPayload,
  CreditNoteGetManyByGrn,
  CreditNoteInitFilters,
  CreditNoteListExport,
  CreditNotePatchFilter,
  CreditNotePatchRequestMeta,
  CreditNoteResetFilter,
  CreditNoteSetFilter,
  CreditNoteUpdate,
} from '../actions';

const CREDIT_NOTES_STATE_TOKEN = new StateToken<CreditNotesStateModel[]>('creditNotes');

export interface CreditNotesStateModel extends EntityListStateModel<CreditNote> {
  filters: CreditNotesFilters;
  requestMetadata: CreditNotesRequestMetadata;
  responseMetadata: CreditNotesResponseMetadata;
  readonly exportLoading: boolean;
}

export interface CreditNotesFilters {
  status: CreditNoteStatus;
  creditType: GrnCreditNoteType;
  locations: string[];
  suppliers: string[];
  documentNumber: string;
}

export interface CreditNotesRequestMetadata {
  readonly page: number;
  readonly limit: number;
  readonly retailerId: string | null;
}

export interface CreditNotesResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const FILTERS_DEFAULT = {
  status: null,
  locations: [],
  creditType: null,
  documentNumber: null,
  suppliers: [],
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, channelIds: [], retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<CreditNotesStateModel>({
  name: CREDIT_NOTES_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    exportLoading: false,
  },
})
@Injectable()
export class CreditNotesState extends EntityListState {
  private readonly creditNoteService = inject(CreditNoteService);
  private readonly store = inject(Store);

  @Selector()
  static creditNotes(state: CreditNotesStateModel) {
    return EntityListState.all(state).filter(note => note.status !== CreditNoteStatus.Draft);
  }

  @Selector()
  static currentCreditNote(state: CreditNotesStateModel) {
    return EntityListState.current(state);
  }

  static creditNote(id: string, next?: boolean) {
    return createSelector([CreditNotesState], (state: CreditNotesStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static isFirst(state: CreditNotesStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: CreditNotesStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static filters(state: CreditNotesStateModel) {
    return EntityListState.currentFilters<CreditNotesFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: CreditNotesStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static requestMetadata(state: CreditNotesStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: CreditNotesStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static exportLoading(state: CreditNotesStateModel) {
    return state.exportLoading;
  }

  @Action(CreditNoteInitFilters)
  grnsInitFilter(ctx: StateContext<CreditNotesStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(CreditNoteSetFilter)
  setCreditNoteFilter(ctx: StateContext<CreditNotesStateModel>, action: CreditNoteSetFilter) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new CreditNotePatchRequestMeta({ page: 0 }), new CreditNoteGetMany()]);
  }

  @Action(CreditNotePatchFilter)
  patchCreditNoteFilter(ctx: StateContext<CreditNotesStateModel>, action: CreditNotePatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new CreditNotePatchRequestMeta({ page: 0 }), new CreditNoteGetMany()]);
  }

  @Action(CreditNoteResetFilter)
  resetFilters(ctx: StateContext<CreditNotesStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new CreditNotePatchRequestMeta({ page: 0 }), new CreditNoteGetMany()]);
  }

  @Action(CreditNoteGet)
  get(ctx: StateContext<CreditNotesStateModel>, action: CreditNoteGet) {
    return this.creditNoteService.getCreditNote(action.payload.id).pipe(
      tap(creditNote => {
        super.setOne(ctx, CreditNote.deserialize(creditNote));
      }),
    );
  }

  @Action(CreditNoteUpdate)
  update(ctx: StateContext<CreditNotesStateModel>, { payload: { id, body } }: CreditNoteUpdate) {
    return this.creditNoteService
      .updateCreditNote(id, body)
      .pipe(switchMap(() => ctx.dispatch(new CreditNoteGet({ id }))));
  }

  @Action(CreditNoteGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<CreditNotesStateModel>, { ids }: CreditNoteGetMany) {
    return this.creditNoteService.getCreditNotes(this.getQuery(ctx.getState(), { ids })).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(
          ctx,
          data.map(creditNote => CreditNote.deserialize(creditNote)),
        );
      }),
    );
  }

  @Action(CreditNoteGetManyByGrn, { cancelUncompleted: true })
  getManyByGrn(ctx: StateContext<CreditNotesStateModel>, { payload }: CreditNoteGetManyByGrn) {
    return this.creditNoteService.getCreditNotes(this.getQueryByGrn(payload)).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(
          ctx,
          data.map(creditNote => CreditNote.deserialize(creditNote)),
        );
      }),
    );
  }

  @Action(CreditNoteArchiveMany)
  archiveCreditNotes(ctx: StateContext<CreditNotesStateModel>, { payload }: CreditNoteArchiveMany) {
    return this.creditNoteService
      .archiveCreditNotes(payload.creditNotes)
      .pipe(switchMap(() => ctx.dispatch([new CreditNoteGetMany()])));
  }

  @Action(CreditNotePatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<CreditNotesStateModel>, { payload }: CreditNotePatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(CreditNoteListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<CreditNotesStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      noLimit: true,
    });

    return this.creditNoteService.getCreditNotes(query).pipe(
      withLatestFrom(this.store.select(SettingsState.currency), this.store.select(SettingsState.ianaTimeZone)),
      tap(([{ data }, currency, ianaTimeZone]) => {
        ctx.patchState({
          exportLoading: false,
        });

        const hidePrices = this.store.selectSignal(CurrentUserState.hideApproxPrice);

        void downloadCreditNotesList(
          data.map(creditNote => CreditNote.deserialize(creditNote)),
          currency,
          ianaTimeZone,
          hidePrices(),
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(
    state: CreditNotesStateModel,
    options?: Partial<{ readonly ids: string[]; readonly noLimit: boolean }>,
  ): Query<CreditNoteQueryParams> {
    const { locations, status, suppliers, creditType, documentNumber } = state.filters;

    const qb = new QueryBuilder<CreditNoteQueryParams>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
    });

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (options?.ids?.length > 0) {
      qb.filtering.setFilter({
        by: 'id',
        match: options.ids,
        op: 'in',
      });
    }

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: locations,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: suppliers,
        op: 'in',
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (creditType) {
      qb.filtering.setFilter({
        by: 'creditType',
        match: creditType,
        op: 'eq',
      });
    }

    if (documentNumber) {
      qb.filtering.withGroup('or', [
        { by: 'id', op: 'like', match: documentNumber },
        { by: 'item.id', op: 'like', match: documentNumber },
        { by: 'item.itemCode', op: 'like', match: documentNumber },
        { by: 'item.name.en', op: 'like', match: documentNumber },
      ]);
    }

    qb.withOrdering([
      { by: 'createdAt', dir: 'desc' },
      { by: 'id', dir: 'desc' },
    ]);

    return qb.build();
  }

  private getQueryByGrn({
    id,
    supplier,
    location,
    creditNoteItems,
    channel,
    retailerId,
  }: CreditNoteGetManyBrGrnPayload): Query<CreditNoteQueryParams> {
    const qb = new QueryBuilder<CreditNoteQueryParams>({
      filtering: {
        filtering: [
          {
            by: 'retailer.id',
            match: retailerId,
            op: 'eq',
          },
          {
            by: 'status',
            match: CreditNoteStatus.Open,
            op: 'eq',
          },
        ],
        condition: 'and',
        groups: [],
      },
      paging: QueryPaging.NoLimit,
    });

    if (id) {
      qb.filtering.withFiltering([
        {
          by: 'originDocument.id',
          match: id,
          op: 'neq',
        },
      ]);
    }

    if (channel) {
      qb.filtering.withFiltering([
        {
          by: 'channel.id',
          match: channel.id,
          op: 'eq',
        },
      ]);
    }

    if (supplier) {
      qb.filtering.withFiltering([
        {
          by: 'supplier.id',
          match: supplier.id,
          op: 'eq',
        },
      ]);
    }

    if (location) {
      qb.filtering.withFiltering([
        {
          by: 'location.id',
          match: location.id,
          op: 'eq',
        },
      ]);
    }

    if (creditNoteItems?.length) {
      qb.filtering.withFiltering([
        {
          by: 'id',
          match: creditNoteItems.map(({ allocatedCreditNote }) => allocatedCreditNote.id),
          op: 'not-in',
        },
      ]);
    }

    return qb.build();
  }
}
