import { IANATimezone } from '@supy.api/dictionaries';

import { ChannelItem } from '@supy/channel-items';
import { Channel, getDateInTimeZone, hasCutOffPassedForDelivery } from '@supy/common';

export function isOrderLessThanSupplierMinimumLimitChecker({
  items,
  channel,
}: {
  readonly items: ChannelItem[];
  readonly channel: Channel;
}): boolean {
  return (
    items.reduce<number>((acc, { price, quantity }) => acc + price * (quantity ?? 0), 0) <
    channel.orderPlacementConfiguration?.minimumOrderLimit
  );
}

export function isOrderAfterSupplierCutOffTimeChecker({
  deliveryDate,
  ianaTimeZone,
  channel,
}: {
  readonly deliveryDate: Date;
  readonly ianaTimeZone: IANATimezone;
  readonly channel: Channel;
}): boolean {
  const schedules = channel?.orderPlacementConfiguration.cutOffSchedule || [];
  const shiftedDeliveryDate = getDateInTimeZone(new Date(deliveryDate), ianaTimeZone);
  const now = getDateInTimeZone(new Date(), ianaTimeZone);

  return hasCutOffPassedForDelivery(shiftedDeliveryDate, schedules, now);
}

export function isOrderOnClosedDeliveryDayChecker({
  deliveryDate,
  ianaTimeZone,
  channel,
}: {
  readonly deliveryDate: Date;
  readonly ianaTimeZone: IANATimezone;
  readonly channel: Channel;
}): boolean {
  const schedules = channel?.orderPlacementConfiguration.cutOffSchedule ?? [];
  const shiftedDeliveryDate = getDateInTimeZone(new Date(deliveryDate), ianaTimeZone);
  const dayOfWeek = shiftedDeliveryDate.getDay() - 1;
  const day = schedules[dayOfWeek === -1 ? 6 : dayOfWeek];

  return !day?.isActive ?? true;
}
