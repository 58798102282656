import { ChannelItem } from '@supy/channel-items';
import { getApiDetailsDecorator, LocalizedData, SupplierItem } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';
import { TaxRate } from '@supy/settings';

import { OrderItemQuantity, ProductRetailerItem, StatefulOrderEntity } from './common-order.model';
import { DetailedOrderItemResponse } from './order.model';

const ApiProperty = getApiDetailsDecorator<DetailedOrderItemResponse>();

export class DetailedOrderItem {
  private constructor(args: NonFunctionProperties<Omit<DetailedOrderItem, 'originalRemainingQty' | 'remainingQty'>>) {
    this.appliedTax = args.appliedTax;
    this.ckItemId = args.ckItemId;
    this.comment = args.comment;
    this.id = args.id;
    this.inventoryItemId = args.inventoryItemId;
    this.itemCode = args.itemCode;
    this.name = args.name;
    this.packaging = args.packaging;
    this.price = args.price;
    this.quantity = args.quantity;
    this.retailerItem = args.retailerItem;
    this.supplierItem = args.supplierItem;
    this.unit = args.unit;
    this.totalBeforeTaxRate = args.totalBeforeTaxRate;
    this.totalAfterTaxRate = args.totalAfterTaxRate;
    this.taxRateAmount = args.taxRateAmount;

    // Computed properties
    this.originalRemainingQty = (this.quantity.modified ?? this.quantity.ordered) - (this.quantity.received ?? 0);
    this.remainingQty = this.originalRemainingQty < 0 ? 0 : this.originalRemainingQty;
    this.isCreatedByCk = args.isCreatedByCk;
  }

  @ApiProperty() readonly appliedTax: TaxRate | null;
  @ApiProperty() readonly comment: string | null;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly itemCode: string | null;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly packaging: Packaging;
  @ApiProperty() readonly price: StatefulOrderEntity;
  @ApiProperty() readonly quantity: OrderItemQuantity;
  @ApiProperty() readonly retailerItem: ProductRetailerItem;
  @ApiProperty() readonly supplierItem: SupplierItem | null;
  @ApiProperty() readonly unit: string;
  @ApiProperty({ key: 'ckItem' }) readonly ckItemId: string | null;
  @ApiProperty({ key: 'inventoryItem' }) readonly inventoryItemId: string | null;
  readonly totalBeforeTaxRate: number;
  readonly totalAfterTaxRate: number;
  readonly taxRateAmount: number;

  // Computed properties
  readonly originalRemainingQty: number;
  readonly remainingQty: number;
  readonly isCreatedByCk: boolean;

  static deserialize(
    data: DetailedOrderItemResponse,
    { taxRates, supplierTaxRate }: DeserializeArgs,
  ): DetailedOrderItem {
    const retailerTaxRate = taxRates.find(({ taxCode }) => taxCode === data.retailerItem.taxCode);
    const taxRate = data.appliedTax?.rate ?? retailerTaxRate?.rate ?? supplierTaxRate?.rate ?? 0;
    const totalBeforeTaxRate =
      (data.price.modified ?? data.price.ordered) * (data.quantity.modified ?? data.quantity.ordered);
    const taxRateAmount = totalBeforeTaxRate * (taxRate / 100);
    const totalAfterTaxRate = totalBeforeTaxRate + taxRateAmount;

    return new DetailedOrderItem({
      appliedTax: data.appliedTax ?? null,
      ckItemId: data.ckItem ?? null,
      comment: data.comment ?? null,
      id: data.id,
      inventoryItemId: data.inventoryItem?.id ?? null,
      isCreatedByCk: data.isCreatedByCk ?? false,
      itemCode: data.itemCode ?? null,
      name: data.name,
      packaging: data.packaging,
      price: data.price,
      quantity: data.quantity,
      retailerItem: data.retailerItem,
      supplierItem: data.supplierItem ?? null,
      taxRateAmount,
      totalAfterTaxRate,
      totalBeforeTaxRate,
      unit: data.unit ?? null,
    });
  }

  static deserializeList(data: DetailedOrderItemResponse[], args: DeserializeArgs): DetailedOrderItem[] {
    return data.map(item => this.deserialize(item, args));
  }

  static deserializeFromChannelItem(data: ChannelItem): DetailedOrderItem {
    return new DetailedOrderItem({
      id: data.id,
      supplierItem: null,
      appliedTax: null,
      inventoryItemId: null,
      ckItemId: null,
      isCreatedByCk: false,
      itemCode: data.itemCode ?? null,
      name: data.name,
      unit: data.packaging?.unitName,
      comment: null,
      packaging: data.packaging,
      taxRateAmount: null,
      totalBeforeTaxRate: null,
      totalAfterTaxRate: null,
      quantity: {
        ordered: data.quantity,
      },
      price: {
        ordered: data.price,
      },
      retailerItem: {
        id: data.retailerItem.id,
        code: data.itemCode,
      },
    });
  }
}

interface DeserializeArgs {
  readonly taxRates: TaxRate[];
  readonly supplierTaxRate: TaxRate;
}
