import produce from 'immer';
import { map, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
} from '@supy/common';
import { SettingsState } from '@supy/settings';

import {
  RequisitionByPackaging,
  RequisitionFilters,
  RequisitionsByPackagingQueryProps,
  RequisitionStatus,
} from '../../core';
import { RequisitionsService } from '../../services';
import {
  RequisitionsByPackagingGetDetailed,
  RequisitionsByPackagingGetMany,
  RequisitionsByPackagingInitFilters,
  RequisitionsByPackagingPatchFilters,
  RequisitionsByPackagingPatchRequestMetadata,
  RequisitionsByPackagingReplacePackagingSupplier,
  RequisitionsByPackagingResetFilters,
  RequisitionsByPackagingResetSequence,
  RequisitionsByPackagingSetFilters,
} from '../actions';

const AGGREGATED_REQUISITIONS_BY_PACKAGING_STATE_TOKEN = new StateToken<RequisitionsByPackagingStateModel>(
  'aggregatedRequisitionsByPackaging',
);

export interface RequisitionsByPackagingStateModel extends EntityListStateModel<RequisitionByPackaging> {
  readonly filters: RequisitionFilters;
  readonly requestMetadata: RequisitionsByPackagingRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly detailedRequisition: RequisitionByPackaging;
}

export interface RequisitionsByPackagingRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
}

const DEFAULT_FILTERS: RequisitionFilters = {
  locations: [],
  categories: [],
  status: RequisitionStatus.Pending,
  start: null,
  end: null,
  search: null,
};

const DEFAULT_REQUEST_META: RequisitionsByPackagingRequestMetadata = {
  limit: 40,
  page: 0,
  retailerId: null,
};

const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<RequisitionsByPackagingStateModel>({
  name: AGGREGATED_REQUISITIONS_BY_PACKAGING_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    detailedRequisition: null,
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_META,
    responseMetadata: RESPONSE_META_DEFAULT,
  },
})
@Injectable()
export class RequisitionsByPackagingState extends EntityListState {
  readonly #requisitionsService = inject(RequisitionsService);
  readonly #store = inject(Store);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  static requisition(
    { status, packaging, deliveryDate }: Pick<RequisitionByPackaging, 'packaging' | 'deliveryDate' | 'status'>,
    next?: boolean,
  ) {
    return createSelector([RequisitionsByPackagingState], (state: RequisitionsByPackagingStateModel) => {
      let index = RequisitionsByPackagingState.findRequisitoinIndex(
        { status, packaging, deliveryDate },
        state.entities,
      );

      return state.entities?.[next === undefined ? index : next ? ++index : --index];
    });
  }

  @Selector()
  static detailedRequisition(state: RequisitionsByPackagingStateModel) {
    return state.detailedRequisition;
  }

  @Selector()
  static requisitions(state: RequisitionsByPackagingStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static filters(state: RequisitionsByPackagingStateModel) {
    return EntityListState.currentFilters<RequisitionFilters>(state);
  }

  @Selector()
  static isFirst(state: RequisitionsByPackagingStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: RequisitionsByPackagingStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static requestMetadata(state: RequisitionsByPackagingStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: RequisitionsByPackagingStateModel) {
    return state.responseMetadata;
  }

  @Action(RequisitionsByPackagingGetMany)
  getMany(ctx: StateContext<RequisitionsByPackagingStateModel>) {
    return this.#requisitionsService.getByPackagingMany(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({
        data: data.map(requisition => RequisitionByPackaging.deserialize(requisition)),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(RequisitionsByPackagingGetDetailed)
  getDetailed(ctx: StateContext<RequisitionsByPackagingStateModel>, { payload }: RequisitionsByPackagingGetDetailed) {
    return this.#requisitionsService.getDetailedByPackaging(payload).pipe(
      map(requisition => RequisitionByPackaging.deserialize(requisition)),
      tap(requisition => {
        ctx.patchState({ detailedRequisition: requisition });

        const entities = ctx.getState().entities ?? [];

        super.setSequence(ctx, {
          id: requisition.id,
          isFirst:
            entities?.length > 1
              ? RequisitionsByPackagingState.findRequisitoinIndex(requisition, entities) === 0
              : true,
          isLast:
            entities?.length > 1
              ? RequisitionsByPackagingState.findRequisitoinIndex(requisition, entities) === entities?.length - 1
              : true,
        });
      }),
    );
  }

  @Action(RequisitionsByPackagingInitFilters)
  initFilters(ctx: StateContext<RequisitionsByPackagingStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(RequisitionsByPackagingSetFilters)
  setFilters(ctx: StateContext<RequisitionsByPackagingStateModel>, { payload }: RequisitionsByPackagingSetFilters) {
    super.setFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new RequisitionsByPackagingPatchRequestMetadata({ page: 0 }), new RequisitionsByPackagingGetMany()]);
  }

  @Action(RequisitionsByPackagingPatchFilters)
  patchFilters(ctx: StateContext<RequisitionsByPackagingStateModel>, { payload }: RequisitionsByPackagingPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new RequisitionsByPackagingPatchRequestMetadata({ page: 0 }), new RequisitionsByPackagingGetMany()]);
  }

  @Action(RequisitionsByPackagingResetFilters)
  resetFilters(
    ctx: StateContext<RequisitionsByPackagingStateModel>,
    { payload, skipFetching }: RequisitionsByPackagingResetFilters,
  ) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      ...payload,
    });

    if (skipFetching) {
      return;
    }

    ctx.dispatch([new RequisitionsByPackagingPatchRequestMetadata({ page: 0 }), new RequisitionsByPackagingGetMany()]);
  }

  @Action(RequisitionsByPackagingPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<RequisitionsByPackagingStateModel>,
    { payload }: RequisitionsByPackagingPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(RequisitionsByPackagingResetSequence)
  resetSequenceHandler(ctx: StateContext<RequisitionsByPackagingStateModel>) {
    return super.resetSequence(ctx);
  }

  @Action(RequisitionsByPackagingReplacePackagingSupplier)
  replacePackagingSupplier(
    ctx: StateContext<RequisitionsByPackagingStateModel>,
    { payload }: RequisitionsByPackagingReplacePackagingSupplier,
  ) {
    return this.#requisitionsService.replacePackagingSupplier(payload).pipe(
      tap(() => {
        ctx.dispatch([new RequisitionsByPackagingGetMany()]);
      }),
    );
  }

  static findRequisitoinIndex(
    { deliveryDate, status, packaging: { id } }: Pick<RequisitionByPackaging, 'status' | 'packaging' | 'deliveryDate'>,
    entities: RequisitionByPackaging[],
  ): number {
    return (
      entities?.findIndex(
        ({ packaging: { id: packagingId }, deliveryDate: date, status: reqStatus }) =>
          packagingId === id && new Date(date).getTime() === new Date(deliveryDate).getTime() && reqStatus === status,
      ) ?? 0
    );
  }

  private getQuery(
    state: RequisitionsByPackagingStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<RequisitionsByPackagingQueryProps> {
    const { start, end, status, locations, categories, search } = state.filters;

    const qb = new QueryBuilder<RequisitionsByPackagingQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'deliveryDate',
          dir: 'desc',
        },
      ],
    });

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'locations.id',
        op: 'in',
        match: locations,
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        op: 'eq',
        match: status,
      });
    }

    if (start && end) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'deliveryDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'deliveryDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        op: 'in',
        match: categories,
      });
    }

    if (search) {
      qb.filtering.setFilter({
        by: 'packaging.fullPackageName',
        op: 'like',
        match: search,
      });
    }

    if (state.requestMetadata?.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        op: 'eq',
        match: state.requestMetadata.retailerId,
      });
    }

    return qb.build();
  }
}
