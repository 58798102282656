import {
  getApiDetailsDecorator,
  IdType,
  isOneOf,
  PartyInfo,
  PreferredType,
  SimpleUser,
  SupplierType,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { Country } from '@supy/countries';
import { getLocalizedName } from '@supy/settings';

import { SimpleChannel } from './common-order.model';
import {
  CreationSourceEnum,
  OrderActivityResponse,
  OrderInvoice,
  OrderResponse,
  OrderStatus,
  OrderUpdateStatus,
} from './order.model';
import { OrderItem } from './order-item.entity';

const ApiProperty = getApiDetailsDecorator<OrderResponse>();

type OrderArgs = Omit<
  Order,
  | 'displayedLocationName'
  | 'displayedSupplierName'
  | 'draftedBy'
  | 'hasCkSupplier'
  | 'isClosable'
  | 'isCreatedByCk'
  | 'isReceived'
  | 'isRevenueSharing'
  | 'isUnreceiavable'
  | 'notes'
  | 'preferredType'
  | 'receivedAt'
  | 'submittedBy'
>;

export class Order {
  private constructor(args: NonFunctionProperties<OrderArgs>) {
    this.activities = args.activities;
    this.appliedTax = args.appliedTax;
    this.channel = args.channel;
    this.channelPreferredType = args.channelPreferredType;
    this.comment = args.comment;
    this.country = args.country;
    this.createdAt = args.createdAt;
    this.creationSource = args.creationSource;
    this.deliveryDate = args.deliveryDate;
    this.id = args.id;
    this.invoice = args.invoice;
    this.items = args.items;
    this.linkedGrnIds = args.linkedGrnIds;
    this.modifiedItems = args.modifiedItems;
    this.modifiedItemsTotal = args.modifiedItemsTotal;
    this.number = args.number;
    this.orderedItems = args.orderedItems;
    this.orderedItemsTotal = args.orderedItemsTotal;
    this.partyInfo = args.partyInfo;
    this.receivedItems = args.receivedItems;
    this.receivedItemsTotal = args.receivedItemsTotal;
    this.retailerId = args.retailerId;
    this.status = args.status;
    this.supplierId = args.supplierId;
    this.updatedAt = args.updatedAt;
    this.submittedAt = args.submittedAt;
    this.vat = args.vat;
    this.externalDocNumber = args.externalDocNumber;
    this.isClosed = args.isClosed;

    // Computed properties
    this.draftedBy = this.activities.find(({ type }) => type === OrderUpdateStatus.Draft)?.user ?? null;
    this.hasCkSupplier = this.partyInfo.supplier.type === SupplierType.centralKitchen;
    this.receivedAt = this.activities.find(({ type }) => type === OrderUpdateStatus.Received)?.date ?? null;
    this.submittedBy = this.activities.find(({ type }) => type == OrderUpdateStatus.Submitted)?.user ?? null;
    this.isClosable =
      !this.isClosed &&
      (this.hasCkSupplier
        ? isOneOf(this.status, [OrderStatus.PartialReceived, OrderStatus.Shipped])
        : isOneOf(this.status, [OrderStatus.Confirmed, OrderStatus.PartialReceived, OrderStatus.Submitted]));
    this.isReceived = this.status === OrderStatus.Received;
    this.displayedLocationName = `${getLocalizedName(this.partyInfo.retailer?.outlet?.name)} - ${
      this.partyInfo.retailer?.name
    }`;
    this.displayedSupplierName = this.channel?.displayName ?? this.partyInfo.supplier.name;
    this.isCreatedByCk = this.creationSource === CreationSourceEnum.CentralKitchen;
    this.notes = (this.comment ? [this.comment] : []).concat(
      this.activities.filter(({ comment }) => comment).map(({ comment }) => comment),
    );
    this.preferredType = this.orderedItems.some(({ preferredType }) => preferredType === PreferredType.RevenueSharing)
      ? PreferredType.RevenueSharing
      : PreferredType.Regular;
    this.isRevenueSharing = this.preferredType === PreferredType.RevenueSharing;
  }

  @ApiProperty() readonly activities: OrderActivityResponse[];
  @ApiProperty() readonly appliedTax: number;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly channelPreferredType: PreferredType;
  @ApiProperty() readonly comment: Nullable<string>;
  @ApiProperty() readonly country: Country;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly creationSource: CreationSourceEnum;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly invoice: OrderInvoice;
  @ApiProperty() readonly items: OrderItem[];
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly partyInfo: PartyInfo;
  @ApiProperty() readonly retailerId: string;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty() readonly supplierId: string;
  @ApiProperty() readonly updatedAt: Date;
  @ApiProperty() readonly submittedAt: Date;
  @ApiProperty() readonly vat: number;
  readonly isClosed: boolean;

  @ApiProperty({ key: 'linkedGrns' }) readonly linkedGrnIds: IdType[];
  @ApiProperty({ key: 'modified' }) readonly modifiedItems: OrderItem[];
  @ApiProperty({ key: 'modifiedTotal' }) readonly modifiedItemsTotal: Nullable<number>;
  @ApiProperty({ key: 'ordered' }) readonly orderedItems: OrderItem[];
  @ApiProperty({ key: 'orderedTotal' }) readonly orderedItemsTotal: number;
  @ApiProperty({ key: 'received' }) readonly receivedItems: OrderItem[];
  @ApiProperty({ key: 'receivedTotal' }) readonly receivedItemsTotal: Nullable<number>;

  // Computed properties
  readonly draftedBy: SimpleUser | null;
  readonly receivedAt: Date | null;
  readonly submittedBy: SimpleUser | null;
  readonly hasCkSupplier: boolean;
  readonly isClosable: boolean;
  readonly isUnreceiavable: boolean;
  readonly displayedLocationName: string;
  readonly displayedSupplierName: string;
  readonly isCreatedByCk: boolean;
  readonly isReceived: boolean;
  readonly notes: string[];
  readonly preferredType: PreferredType;
  readonly isRevenueSharing: boolean;
  readonly externalDocNumber?: string;

  static deserialize(data: OrderResponse): Order {
    return new Order({
      activities: data.activities ?? [],
      appliedTax: data.appliedTax,
      channel: data.channel,
      channelPreferredType: data.channelPreferredType,
      comment: data.comment ?? null,
      country: data.country,
      createdAt: new Date(data.createdAt),
      creationSource: data.creationSource,
      deliveryDate: new Date(data.deliveryDate),
      id: data.id,
      invoice: data.invoice,
      items: data.items ? OrderItem.deserializeList(data.items) : [],
      linkedGrnIds: data.linkedGrns,
      modifiedItems: data.modified ? OrderItem.deserializeList(data.modified) : [],
      modifiedItemsTotal: data.modifiedTotal ?? null,
      number: data.number,
      orderedItems: data.ordered ? OrderItem.deserializeList(data.ordered) : [],
      orderedItemsTotal: data.orderedTotal,
      partyInfo: data.partyInfo,
      receivedItems: data.received ? OrderItem.deserializeList(data.received) : [],
      receivedItemsTotal: data.receivedTotal ?? null,
      retailerId: data.retailerId,
      status: data.status,
      supplierId: data.supplierId,
      updatedAt: new Date(data.updatedAt),
      vat: data.vat ?? 0,
      submittedAt: data.submittedAt ? new Date(data.submittedAt) : new Date(data.createdAt),
      isClosed: data.metadata?.closed ?? false,
    });
  }

  static deserializeList(data: OrderResponse[]): Order[] {
    return data.map(this.deserialize);
  }
}

export const OrderStatusBadgeMapper: Record<OrderStatus, BadgeStatus> = {
  [OrderStatus.Confirmed]: 'primary',
  [OrderStatus.Discarded]: 'light-error',
  [OrderStatus.Draft]: 'grey',
  [OrderStatus.PartialReceived]: 'light-warn',
  [OrderStatus.Received]: 'success',
  [OrderStatus.Rejected]: 'error',
  [OrderStatus.Shipped]: 'primary',
  [OrderStatus.Submitted]: 'info',
};

export const ProcurementOrderStatusNameMapper: Record<OrderStatus, string> = {
  [OrderStatus.Confirmed]: $localize`:@@statusConfirmed:Confirmed`,
  [OrderStatus.Discarded]: $localize`:@@statusDiscarded:Discarded`,
  [OrderStatus.Draft]: $localize`:@@statusDrafted:Drafted`,
  [OrderStatus.PartialReceived]: $localize`:@@orderPartiallyReceived:Partially Received`,
  [OrderStatus.Received]: $localize`:@@statusClosed:Closed`,
  [OrderStatus.Rejected]: $localize`:@@statusRejected:Rejected`,
  [OrderStatus.Shipped]: $localize`:@@statusShipped:Shipped`,
  [OrderStatus.Submitted]: $localize`:@@statusSubmitted:Submitted`,
};

export const InventoryOrderStatusNameMapper: Record<OrderStatus, string> = {
  ...ProcurementOrderStatusNameMapper,
  [OrderStatus.Received]: $localize`:@@statusClosed:Closed`,
};

export const CkOrderStatusNameMapper: Record<OrderStatus, string> = {
  ...ProcurementOrderStatusNameMapper,
  [OrderStatus.Received]: $localize`:@@statusDelivered:Delivered`,
  [OrderStatus.Submitted]: $localize`:@@newOrder:New Order`,
};
