import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { CentralKitchenOrderActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Submit extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Submit,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Discard extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Discard,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Draft extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Draft,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Ship extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Ship,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Confirm extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Confirm,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Reject extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Reject,
      resource,
      principal,
    });
  }
}

export class CkOrderPermissionStrategy {
  static Draft = Draft;
  static Discard = Discard;
  static Submit = Submit;
  static Create = Create;
  static Ship = Ship;
  static Confirm = Confirm;
  static Reject = Reject;
}
