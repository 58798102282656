import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getDateInTimeZone,
  getDatePart,
  PrecisionPipe,
  saveWorkbook,
} from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { EmailedOrderItem, Order, OrderItem, OrderStatus } from '../core';

export function compareModifiedWithOrdered(order: Order): OrderItem[] {
  return order.orderedItems.map(item => {
    const modified = isItemModified(order, item);

    return modified ? { ...modified } : { ...item };
  });
}

export function isItemModified(order: Order, orderItem: OrderItem): OrderItem {
  return order?.modifiedItems?.find((item: OrderItem) => item.id === orderItem.id);
}

export function getOrdersProducts(orders: Order[]): Map<string, OrderItem[]> {
  return orders.reduce((acc, order) => {
    const orderReceivedProducts: Map<string, OrderItem> = order.receivedItems?.reduce(
      (acc, received) => acc.set(received.id, received),
      new Map<string, OrderItem>(),
    );
    const orderModifiedProducts: Map<string, OrderItem> = order.modifiedItems?.reduce(
      (acc, modified) => acc.set(modified.id, modified),
      new Map<string, OrderItem>(),
    );
    const orderOrderedQtys: Map<string, number> = order.orderedItems?.reduce(
      (acc, ordered) => acc.set(ordered.id, ordered.quantity),
      new Map<string, number>(),
    );
    const orderProducts = order.status === OrderStatus.Received ? order.receivedItems : order.orderedItems;
    const products = sortOrderItems<OrderItem>(
      orderProducts
        .map(product => {
          return orderModifiedProducts.has(product.id)
            ? { ...product, ...orderModifiedProducts.get(product.id) }
            : product;
        })
        .map(product =>
          orderReceivedProducts.has(product.id) ? { ...product, ...orderReceivedProducts.get(product.id) } : product,
        )
        .map(product => {
          return { ...product, orderedQty: orderOrderedQtys.get(product.id) };
        }),
    );

    acc.set(order.id, products);

    return acc;
  }, new Map<string, OrderItem[]>());
}

export function sortOrderItems<T extends OrderItem | EmailedOrderItem>(items: T[]): T[] {
  return items.sort((a, b) => {
    if (a.name.toLowerCase() && b.name.toLowerCase()) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    } else if (a.itemCode && b.itemCode) {
      return a.itemCode.localeCompare(b.itemCode);
    } else {
      return 1;
    }
  });
}

export async function downloadProcurementOrdersList(
  orders: Order[],
  currencyPrecision: number,
  currency: string,
): Promise<void> {
  const precisionPipe = new PrecisionPipe();

  const columns: DataColumn[] = [
    { header: $localize`:@@orders.header.po:PO #`, key: 'number' },
    { header: $localize`:@@location:Location`, key: 'location' },
    { header: $localize`:@@supplier:Supplier`, key: 'supplier' },
    { header: $localize`:@@common.submittedOn:Submitted On`, key: 'submittedAt' },
    { header: $localize`:@@common.expDeliveryDate:Exp. Delivery Date`, key: 'deliveryDate' },
    { header: $localize`:@@orders.export.total:Total (${currency})`, key: 'total' },
    { header: $localize`:@@status:Status`, key: 'status' },
  ];

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();
  const data = orders.map(order => ({
    number: order.number,
    location: `${getLocalizedName(order.partyInfo.retailer?.outlet?.name)} - ${order.partyInfo.retailer?.name}`,
    supplier: order.channel?.displayName ?? order.partyInfo.supplier.name,
    submittedAt: `${getDatePart(getDateInTimeZone(order.submittedAt, DEFAULT_IANA_TIMEZONE))} ${new Date(
      getDateInTimeZone(order.submittedAt, DEFAULT_IANA_TIMEZONE),
    ).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`,
    deliveryDate: getDatePart(getDateInTimeZone(order.deliveryDate, DEFAULT_IANA_TIMEZONE)),
    total: precisionPipe.transform(order.modifiedItemsTotal || order.orderedItemsTotal, currencyPrecision, true),
    status: order.status,
  }));

  await createWorksheet(workbook, 'Procurement Orders', { data, columns });

  const fileName = `procurement-orders-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
