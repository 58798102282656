import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { CustomerActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Customer,
      action: CustomerActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class ViewList extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Customer,
      action: CustomerActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Customer,
      action: CustomerActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Update extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Customer,
      action: CustomerActionEnum.Update,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Delete extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Customer,
      action: CustomerActionEnum.Delete,
      resource,
      principal,
    });
  }
}

export class CkCustomerPermissionStrategy {
  static Create = Create;
  static ViewList = ViewList;
  static View = View;
  static Update = Update;
  static Delete = Delete;
}
