import { Channel, LocalizedData, SimpleUser, SkeletonObjectType } from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { BaseItem } from '@supy/inventory';
import { Packaging } from '@supy/packaging';

export enum RequisitionStatus {
  Pending = 'pending',
  Approved = 'approved',
  Discarded = 'discarded',
  Processed = 'processed',
}

export enum RequisitionActivityType {
  Created = 'created',
  Updated = 'updated',
  Discarded = 'discarded',
  Processed = 'processed',
}

export enum ProcessRequisitionsType {
  Draft = 'draft',
  Submit = 'submit',
}

export enum RequisitionByPackagingLocationsGridRowType {
  Region = 'region',
  Location = 'location',
}

export interface CreateRequisitionItemRequest {
  readonly packagingId: string;
  readonly quantity: number;
}

export interface CreateRequisitionRequest {
  readonly locationId: string;
  readonly deliveryDate: Date;
  readonly items: CreateRequisitionItemRequest[];
  readonly comment?: string;
}

export interface UpdateRequisitionItemRequest {
  readonly id: string;
  readonly packagingId: string;
  readonly quantity: number;
  readonly selectedChannelItemId?: string;
}

export interface UpdateRequisitionRequest {
  readonly id: string;
  readonly items: UpdateRequisitionItemRequest[];
  readonly comment?: string;
  readonly deliveryDate?: Date;
}

export interface ProcessRequisitionsRequest {
  readonly type: ProcessRequisitionsType;
  readonly items: RequisitionItemByLocationRequest[];
  readonly deliveryDate?: Date;
}

export interface DiscardRequisitionsRequest {
  readonly requisitionIds: string[];
}

export interface CreateRequisitionItem {
  readonly packagingId: string;
  readonly quantity: number;
  readonly selectedChannelItemId?: string;
}

export interface RequisitionItemByLocationRequest {
  readonly id: string;
  readonly requisitionId: string;
  readonly selectedChannelItemId: string;
  readonly quantity: number;
}

export interface ApproveRequisitionsRequest {
  readonly items: RequisitionItemByLocationRequest[];
  readonly deliveryDate?: Date;
  readonly processAs?: ProcessRequisitionsType;
}

export interface RequisitionResponse {
  readonly id: string;
  readonly number: string;
  readonly location: SkeletonObjectType;
  readonly branch: SkeletonObjectType;
  readonly categories: RequisitionCategory[];
  readonly deliveryDate: Date;
  readonly status: RequisitionStatus;
  readonly totals: RequisitionTotals;
  readonly items: RequisitionItemResponse[];
  readonly hasDeletedItems: boolean;
  readonly activities: RequisitionActivityResponse[];
  readonly comment?: string;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly approvedAt?: Date;
  readonly approvedBy?: SimpleUser;
}

export type SimpleRequisition = Pick<RequisitionResponse, 'id' | 'number' | 'categories'>;

export interface RequisitionItemResponse {
  readonly id: string;
  readonly packaging: Packaging;
  readonly retailerItem: BaseItem;
  readonly category: RequisitionCategory;
  readonly selectedChannelItem: RequisitionChannelItem;
  readonly channelItems: RequisitionChannelItem[];
  readonly quantity: number;
  readonly totals: RequisitionTotals;
  readonly supplierReplaced?: boolean;
}

export interface RequisitionsByPackagingLocationResponse {
  readonly id: string;
  readonly name: string;
  readonly branch: SkeletonObjectType;
  readonly quantity: number;
  readonly selectedChannelItem: RequisitionChannelItem;
  readonly channelItems: RequisitionChannelItem[];
  readonly requisitions: SimpleRequisition[];
}

export interface RequisitionChannelItem {
  readonly id: string;
  readonly supplier: SkeletonObjectType;
  readonly channel: Channel;
  readonly name: LocalizedData;
  readonly price?: number;
}

export interface RequisitionChannelItemPerPackaging {
  readonly packaging: Packaging;
  readonly channelItems: RequisitionChannelItem[];
  readonly selectedChannelItem: RequisitionChannelItem;
}

export interface RequisitionByLocationResponse
  extends Omit<RequisitionResponse, 'items' | 'number' | 'activities' | 'createdAt' | 'updatedBy' | 'createdBy'> {
  readonly numItems: number;
  readonly items: RequisitionItemByLocationResponse[];
  readonly hasDeletedItems: boolean;
  readonly users: SimpleUser[];
  readonly comments: RequisitionByLocationComment[];
}

export interface RequisitionByLocationComment {
  readonly requisitionId: string;
  readonly comment: string;
}

export interface RequisitionByPackagingResponse {
  readonly id: string;
  readonly retailerId: string;
  readonly category: RequisitionCategory;
  readonly packaging: Packaging;
  readonly deliveryDate: Date;
  readonly businessDeliveryDate: string;
  readonly status: RequisitionStatus;
  readonly locations: RequisitionsByPackagingLocationResponse[];
  readonly users: SimpleUser[];
  readonly updatedAt: Date;
}

export interface RequisitionItemByLocationResponse {
  readonly id: string;
  readonly packaging: Packaging;
  readonly retailerItem: RequisitionRetailerItem;
  readonly category: RequisitionCategory;
  readonly selectedChannelItem: RequisitionChannelItem;
  readonly channelItems: RequisitionChannelItem[];
  readonly requisition: SimpleRequisition;
  readonly quantity: number;
  readonly totals: RequisitionTotals;
  readonly parLevel: number;
  readonly stockLevel: number;
  readonly quantityLevels: RequisitionItemByLocationQuantityLevels;
  readonly supplierReplaced?: boolean;
}

export interface RequisitionItemByLocationQuantityLevels {
  readonly par: number;
  readonly stock: number;
  readonly min?: number;
}

export type RequisitionRetailerItem = Pick<BaseItem, 'id' | 'name' | 'code' | 'taxCode'>;

export interface RequisitionsByLocationGetDetailedRequest {
  readonly locationId: string;
  readonly deliveryDate: Date | number;
  readonly status: RequisitionStatus;
}

export interface RequisitionsByPackagingGetDetailedRequest {
  readonly packagingId: string;
  readonly businessDeliveryDate: Date | number;
  readonly status: RequisitionStatus;
}

export interface RequisitionReplacePackagingSupplierRequest {
  readonly requisitionIds: string[];
  readonly packagingId: string;
  readonly selectedChannelItemId: string;
}

export interface RequisitionReplaceGroupedPackagingSupplierRequest {
  readonly groups: RequisitionReplacePackagingSupplierRequest[];
}

export interface RequisitionCategory {
  readonly id: string;
  readonly name: LocalizedData;
  readonly parent?: RequisitionCategory;
}

export interface RequisitionTotals {
  readonly subTotal: number;
  readonly taxAmount: number;
  readonly total: number;
}

export interface RequisitionActivityResponse {
  readonly date: Date;
  readonly user: SimpleUser;
  readonly type: RequisitionActivityType;
}

export interface AggregatedRequisitionQueryProps {
  readonly status: RequisitionStatus;
  readonly deliveryDate: number;
  readonly retailerId: string;
  readonly 'location.id': string;
}

export interface RequisitionsByPackagingQueryProps extends AggregatedRequisitionQueryProps {
  readonly 'category.id': string;
  readonly 'packaging.fullPackageName': string;
  readonly 'locations.id': string;
}

export interface RequisitionsQueryProps extends AggregatedRequisitionQueryProps {
  readonly number: string;
  readonly 'categories.id': string;
}

export interface RequisitionStatusMapper {
  readonly status: BadgeStatus;
  readonly label: string;
}

export interface RequisitionFilters {
  readonly locations: string[];
  readonly categories?: string[];
  readonly status: RequisitionStatus;
  readonly start?: number;
  readonly end?: number;
  readonly search?: string;
}

export interface RequisitionsByLocationSuppliersRowData {
  readonly supplier: SkeletonObjectType;
  readonly channel: Channel;
  itemCount: number;
  total: number;
}

export const requisitionStatusMapper: Record<RequisitionStatus, RequisitionStatusMapper> = {
  [RequisitionStatus.Pending]: {
    status: 'warn',
    label: $localize`:@@statusPending:Pending`,
  },
  [RequisitionStatus.Approved]: {
    status: 'primary',
    label: $localize`:@@statusApproved:Approved`,
  },
  [RequisitionStatus.Discarded]: {
    status: 'error',
    label: $localize`:@@statusDiscarded:Discarded`,
  },
  [RequisitionStatus.Processed]: {
    status: 'success',
    label: $localize`:@@statusProcessed:Processed`,
  },
};
