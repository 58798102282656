import { Currency } from '@supy.api/dictionaries';

import { ChannelItemResponse } from '@supy/channel-items';
import { getApiDetailsDecorator, PreferredType } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { OrderItemResponse } from './order.model';

const ApiProperty = getApiDetailsDecorator<OrderItemResponse>();

export class OrderItem {
  private constructor(args: NonFunctionProperties<OrderItem>) {
    this.currency = args.currency;
    this.id = args.id;
    this.itemCode = args.itemCode;
    this.name = args.name;
    this.orderedQty = args.orderedQty;
    this.preferredType = args.preferredType;
    this.price = args.price;
    this.quantity = args.quantity;
    this.unit = args.unit;
    this.nameAr = args.nameAr;
  }

  @ApiProperty() readonly currency: Nullable<Currency>;
  @ApiProperty() readonly itemCode: Nullable<string>;
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly orderedQty: number;
  @ApiProperty() readonly preferredType: Nullable<PreferredType>;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly unit: Nullable<string>;
  @ApiProperty({ key: 'productId' }) readonly id: string;
  readonly nameAr: Nullable<string>;

  static deserialize(data: OrderItemResponse): OrderItem {
    return new OrderItem({
      currency: data.currency ?? null,
      id: data.productId,
      itemCode: data.itemCode ?? null,
      name: data.name,
      nameAr: data.name_ar ?? null,
      orderedQty: data.orderedQty ?? 0,
      preferredType: data.preferredType ?? null,
      price: data.price ?? 0,
      quantity: data.quantity ?? 0,
      unit: data.unit ?? null,
    });
  }

  static deserializeList(data: OrderItemResponse[]): OrderItem[] {
    return data.map(this.deserialize);
  }

  static deserializeFromChannelItem(data: ChannelItemResponse): OrderItem {
    return new OrderItem({
      currency: null,
      id: data.id,
      itemCode: data.itemCode ?? null,
      name: data.name.en,
      nameAr: data.name.ar,
      orderedQty: 0,
      preferredType: data.preferredType ?? null,
      price: data.price ?? 0,
      quantity: 0,
      unit: data.packaging?.unitName ?? null,
    });
  }
}
