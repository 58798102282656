import { BehaviorSubject, map, switchMap, takeUntil, tap } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { GridPagingMode, GridSelectionMode, IClipboardOptions, RowType } from '@infragistics/igniteui-angular';

import { ChannelItemRequestProps, ChannelItemsService, UpdateChannelItemRequest } from '@supy/channel-items';
import {
  BaseResponseMetadata,
  Destroyable,
  isOneOf,
  PreferredType,
  Query,
  QueryPaging,
  SupplierPartnershipTypeEnum,
  trackByIndex,
  User,
} from '@supy/common';
import { DialogService, GridComponent, InputComponent, SnackbarService } from '@supy/components';
import { Country } from '@supy/countries';
import { Span } from '@supy/opentelemetry';
import { getLocalizedName } from '@supy/settings';

import {
  ModifyOrderRequest,
  Order,
  OrderItem,
  OrderStatus,
  OrderUpdateStatus,
  UpdateOrderRequest,
} from '../../../../core';
import { getOrdersProducts } from '../../../../helpers';
import { IModifyOrder, ISaveOrder, OrderListColumn, OrderListColumnType, PartialReceivedOrderItem } from '../../models';
import { AddOrderItemsDialogComponent } from '../add-order-items-dialog';
import { OrdersRequestMetadata } from './../../../../store';

@Component({
  selector: 'supy-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class OrdersListComponent extends Destroyable implements OnInit {
  private readonly dialogService = inject(DialogService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly renderer = inject(Renderer2);
  private readonly channelItemsService = inject(ChannelItemsService);
  private readonly snackbarService = inject(SnackbarService);
  @ViewChild(GridComponent, { static: true }) readonly grid: GridComponent;

  @Input() readonly canEditOrder: boolean;
  @Input() readonly canConfirmRejectOrder: boolean;
  @Input() readonly isAdmin: boolean;
  @Input() readonly currencyPrecision: number;

  @Input() set rowSelection(selection: GridSelectionMode) {
    this.#rowSelection = selection;
  }

  get rowSelection() {
    return this.#rowSelection;
  }

  get gridRowSelection() {
    return this.#rowSelection === GridSelectionMode.multiple ? GridSelectionMode.none : this.#rowSelection;
  }

  @Input() readonly submitDisabled: Record<string, boolean>;
  @Input() readonly showInvoices: boolean;
  @Input() readonly isLoading: boolean;
  @Input() readonly productsLoading: boolean;
  @Input() readonly columns: OrderListColumn[] = [];
  @Input() readonly allowedSelectionStatuses: Set<OrderStatus> = new Set([
    OrderStatus.Confirmed,
    OrderStatus.Submitted,
  ]);

  @Input() readonly showPreferredFlag: boolean;
  @Input() readonly mergeParams: boolean;
  @Input() readonly countries: Country[];

  @Input() set data(value: Order[]) {
    const preferredMappedOrders = value?.map(order => {
      if (!this.ordersMap.has(order.id)) {
        this.ordersMap.set(order.id, order);
      }

      return {
        ...order,
        preferredType: order.orderedItems.some(order => order.preferredType === PreferredType.RevenueSharing)
          ? PreferredType.RevenueSharing
          : PreferredType.Regular,
      };
    });

    if (preferredMappedOrders) {
      this.ordersChange$.next(preferredMappedOrders);
    }
  }

  @Input() protected readonly requestMetadata: OrdersRequestMetadata;
  @Input() protected readonly responseMetadata: BaseResponseMetadata;
  @Input() protected readonly paginationMode: GridPagingMode;

  @Output() readonly submitOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly saveOrder: EventEmitter<ISaveOrder> = new EventEmitter<ISaveOrder>();
  @Output() readonly deleteDraft: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly confirmOrder: EventEmitter<IModifyOrder> = new EventEmitter<IModifyOrder>();
  @Output() readonly rejectOrder: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly modifyOrder: EventEmitter<IModifyOrder> = new EventEmitter<IModifyOrder>();
  @Output() readonly downloadPdf: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly expandRow: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly orderSelectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() readonly pageChange = new EventEmitter<number>();

  readonly clipboardOptions: IClipboardOptions = {
    enabled: false,
    copyHeaders: false,
    separator: '\t',
    copyFormatters: false,
  };

  readonly ordersChange$ = new BehaviorSubject<Order[]>([]);
  readonly orders$ = this.ordersChange$.asObservable();
  readonly #orderItemsChange = new BehaviorSubject<Map<string, OrderItem[]>>(new Map());
  readonly orderItems$ = this.#orderItemsChange.asObservable();
  readonly isAddItemsLoadingChanges$ = new BehaviorSubject(false);
  readonly isAddItemsLoading$ = this.isAddItemsLoadingChanges$.asObservable();
  readonly preferredType = PreferredType;
  readonly listColumnType = OrderListColumnType;
  readonly changedReceivedItemsPrice = new Map<string, number>();
  readonly ordersMap = new Map<string, Order>();
  readonly changedReceivedItemsQuantity = new Map<string, number>();
  readonly changedPartialReceivedItems = new Map<string, PartialReceivedOrderItem>();
  readonly selectedOrders = new Set<string>();

  isEditingReceivedItems = false;
  isReceivedItemsTouched = false;
  orderStatus = OrderStatus;
  #rowSelection: GridSelectionMode = GridSelectionMode.none;

  protected readonly trackByIndex = trackByIndex();
  protected readonly trackColumnsByIndex = trackByIndex<OrderListColumn>();

  readonly orderStatusClosure = (status: OrderStatus) => {
    return (row: RowType): boolean => {
      return (row.data as Order).status === status;
    };
  };

  ngOnInit(): void {
    this.orders$.pipe(takeUntil(this.destroyed$)).subscribe(orders => {
      this.#orderItemsChange.next(getOrdersProducts(orders || []));
    });
  }

  getCurrency(countryId: string): string {
    return this.countries?.find(({ id }) => id === countryId)?.currency;
  }

  getUpdatedBy(order: Order): string {
    const updateActions = order.activities.find(
      update => update.type === OrderUpdateStatus.Submitted || update.type === OrderUpdateStatus.Draft,
    );

    return updateActions ? `${new User(updateActions.user).fullName} - ${new User(updateActions.user).phone}` : '';
  }

  @Span()
  onToggleRow(data: Order): void {
    const { status } = data;

    this.expandRow.emit(data);

    const gridContainer = this.grid.nativeElement.querySelector('.igx-grid__tr-container');

    if (gridContainer) {
      this.renderer.addClass(gridContainer, `igx-grid__tr-container--${status}`);
    }

    this.isEditingReceivedItems = false;
    this.cdr.detectChanges();
  }

  getOrderedTotal(order: Order, orderItemId: string): number {
    const product =
      order.modifiedItems.find(({ id }) => id === orderItemId) ??
      order.orderedItems.find(({ id }) => id === orderItemId) ??
      this.changedPartialReceivedItems.get(orderItemId);

    return product ? product.price * product.quantity : 0;
  }

  getItemsCount(order: Order) {
    return order.status === OrderStatus.Received ? order.receivedItems.length : order.orderedItems.length;
  }

  @Span()
  onSubmitOrder(order: Order): void {
    this.submitOrder.emit(order);
  }

  @Span()
  onDeleteDraft(order: Order): void {
    this.deleteDraft.emit(order);
  }

  @Span()
  onConfirmOrder(order: Order): void {
    const modifyOrderRequest: ModifyOrderRequest = {
      products: order.orderedItems.map(item => {
        const modifiedItem = order.modifiedItems?.find(({ id }) => id == item.id);

        return {
          productId: item.id,
          quantity: this.changedReceivedItemsQuantity.has(item.id)
            ? this.changedReceivedItemsQuantity.get(item.id)
            : modifiedItem
              ? modifiedItem.quantity
              : item.quantity,
        };
      }),
    };

    this.confirmOrder.emit({
      orderId: order.id,
      modifyOrderRequest: this.changedReceivedItemsQuantity.size > 0 ? modifyOrderRequest : null,
    });

    this.resetModifyState();
  }

  @Span()
  onRejectOrder(orderId: string): void {
    this.rejectOrder.emit(orderId);
  }

  @Span()
  onModifyOrder(orderId: string, productId: string, inStock = 0): void {
    const modifyOrderRequest: ModifyOrderRequest = {
      products: [{ productId, quantity: inStock }],
    };

    this.modifyOrder.emit({ orderId, modifyOrderRequest });
  }

  @Span()
  onDownloadPdf(orderId: string): void {
    this.downloadPdf.emit(orderId);
  }

  getOrderTotalAfterVAT(order: Order): number {
    const value =
      order.status === OrderStatus.Received
        ? order.receivedItemsTotal
        : order.modifiedItemsTotal > 0
          ? order.modifiedItemsTotal
          : order.orderedItemsTotal;

    return value * (1 + order.vat / 100);
  }

  @Span()
  onEditClick() {
    this.isEditingReceivedItems = true;
  }

  @Span()
  onSaveClick(order: Order, pushAfterSave: boolean): void {
    this.isEditingReceivedItems = false;

    const channelItems: UpdateChannelItemRequest[] = Array.from(this.changedReceivedItemsPrice.entries()).map(
      ([id, price]) => ({ id, price }),
    );

    const updateOrderRequest: UpdateOrderRequest = {
      type: OrderUpdateStatus.Received,
      received: order.receivedItems.map(item => ({
        productId: item.id,
        quantity: this.changedReceivedItemsQuantity.has(item.id)
          ? this.changedReceivedItemsQuantity.get(item.id)
          : item.quantity,
      })),
    };

    if (this.isReceivedItemsTouched || pushAfterSave) {
      this.saveOrder.emit({
        orderId: order.id,
        channelItems,
        updateOrderRequest,
        pushAfterSave,
        branchId: order.retailerId,
      });
    }

    this.resetModifyState();
  }

  @Span()
  handleRowSelection() {
    this.orderSelectionChange.emit([...this.selectedOrders]);
  }

  resetModifyState(): void {
    this.isReceivedItemsTouched = false;
    this.changedReceivedItemsPrice.clear();
    this.changedReceivedItemsQuantity.clear();
  }

  onChangePartialOrderItem(
    orderItem: OrderItem,
    value: number,
    inputElement: InputComponent<number>,
    changedProperty: 'quantity' | 'price',
  ): void {
    this.isReceivedItemsTouched = true;

    const targetOrderItem = this.changedPartialReceivedItems.get(orderItem.id);

    if (value > 0) {
      this.changedPartialReceivedItems.set(orderItem.id, { ...targetOrderItem, [changedProperty]: value });
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(orderItem.id, value)
        : this.changedReceivedItemsPrice.set(orderItem.id, value);
    } else {
      this.changedPartialReceivedItems.set(orderItem.id, { ...targetOrderItem, [changedProperty]: 0 });
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(orderItem.id, 0)
        : this.changedReceivedItemsPrice.set(orderItem.id, 0);
      inputElement.writeValue(0);
    }
  }

  onCheckPartialReceivedOrderItem(checked: boolean, orderItem: OrderItem): void {
    const targetOrderItem = this.changedPartialReceivedItems.get(orderItem.id);

    this.changedPartialReceivedItems.set(orderItem.id, {
      ...targetOrderItem,
      checked,
    });
  }

  @Span()
  onEditAndReceiveClick(order: Order): void {
    const currentOrderItems = this.#orderItemsChange.getValue();

    currentOrderItems.get(order.id).forEach(item =>
      this.changedPartialReceivedItems.set(item.id, {
        ...item,
        checked:
          order.status === OrderStatus.Submitted || order.status === OrderStatus.Confirmed
            ? true
            : order.receivedItems.findIndex(({ id }) => id === item.id) > -1,
        sealed:
          order.status === OrderStatus.PartialReceived &&
          order.receivedItems.findIndex(({ id }) => id === item.id) > -1,
      }),
    );

    this.isEditingReceivedItems = true;
  }

  @Span()
  onReceiveClick(order: Order): void {
    const selectedPartialReceivedOrderItems = Array.from(this.changedPartialReceivedItems.values()).reduce<
      PartialReceivedOrderItem[]
    >((acc, { checked, ...props }) => {
      if (checked) {
        acc.push(props);
      }

      return acc;
    }, []);

    const channelItems = Array.from(selectedPartialReceivedOrderItems).map<UpdateChannelItemRequest>(
      ({ id, price }) => ({
        id,
        price,
      }),
    );

    const updateOrderRequest = {
      type: OrderUpdateStatus.Received,
      received: selectedPartialReceivedOrderItems.map(({ id, quantity }) => ({ productId: id, quantity })),
    };

    this.saveOrder.emit({
      orderId: order.id,
      channelItems,
      updateOrderRequest,
      branchId: order.retailerId,
    });

    this.isEditingReceivedItems = false;
    this.changedPartialReceivedItems.clear();
  }

  onChangeReceivedOrderItem(
    { id }: OrderItem,
    value: number,
    input: InputComponent<number>,
    changedProperty: 'quantity' | 'price',
  ): void {
    this.isReceivedItemsTouched = true;

    if (value > 0) {
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(id, value)
        : this.changedReceivedItemsPrice.set(id, value);
    } else {
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(id, 0)
        : this.changedReceivedItemsPrice.set(id, 0);

      input.writeValue(0);
    }
  }

  getOrderStatusForSupplier(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.Submitted:
        return 'New Order';
      case OrderStatus.Received:
        return 'Delivered';
      case OrderStatus.PartialReceived:
        return 'Partially Delivered';
      default:
        return status;
    }
  }

  getRejectedReason(order: Order): string {
    return order.activities.find(item => item.type === OrderUpdateStatus.Rejected)?.comment;
  }

  canShowSupplierAction(order: Order): boolean {
    return this.canConfirmRejectOrder && order.status === OrderStatus.Submitted;
  }

  outOfStock(item: OrderItem): boolean {
    return item && item.quantity === 0;
  }

  getOrderQuantityForItem(order: Order, orderItemId: string): number {
    return order?.orderedItems?.find(({ id }) => id === orderItemId)?.quantity;
  }

  @Span()
  markInStock(order: Order, productId: string): void {
    this.onModifyOrder(order.id, productId, this.getOrderQuantityForItem(order, productId));
  }

  get isPartialReceiveButtonDisabled(): boolean {
    return Array.from(this.changedPartialReceivedItems.values()).every(({ checked }) => !checked);
  }

  showModified({ status, modifiedItems }: Order): boolean {
    return modifiedItems.length && status === OrderStatus.Confirmed;
  }

  canOrderBePartiallyReceived({ status }: Order): boolean {
    return isOneOf(status, [
      OrderStatus.Confirmed,
      OrderStatus.Shipped,
      OrderStatus.PartialReceived,
      OrderStatus.Submitted,
    ]);
  }

  isOrderItemPartiallyChecked(order: Order, orderItem: OrderItem): boolean {
    return (
      order.status === OrderStatus.Confirmed ||
      order.status === OrderStatus.Submitted ||
      order.receivedItems.findIndex(({ id }) => id === orderItem.id) > -1
    );
  }

  @Span()
  onAddItemsClick(targetOrder: Order): void {
    let addOrderItemsDialog: AddOrderItemsDialogComponent;

    const query = new Query<ChannelItemRequestProps>({
      paging: QueryPaging.NoLimit,
      filtering: [
        { by: 'channelId', op: 'eq', match: targetOrder.channel.id },
        { by: 'state', op: 'neq', match: 'deleted' },
      ],
    });

    this.isAddItemsLoadingChanges$.next(true);

    this.channelItemsService
      .getMany(query)
      .pipe(
        takeUntil(this.destroyed$),
        map(({ data }) =>
          data
            .filter(({ id }) => targetOrder.orderedItems.findIndex(orderItem => orderItem.id === id) === -1)
            .map(channelItem => ({
              ...OrderItem.deserializeFromChannelItem(channelItem),
              quantity: this.changedPartialReceivedItems.get(channelItem.id)?.quantity ?? 0,
            })),
        ),
        tap(orderItems => {
          this.isAddItemsLoadingChanges$.next(false);
          addOrderItemsDialog = this.dialogService.openDialog(AddOrderItemsDialogComponent, {
            channel: targetOrder.channel,
            supplier: targetOrder.partyInfo.supplier,
            orderItems,
          });
        }),
        switchMap(() => addOrderItemsDialog.orderItemsAdded),
        tap(newlyAddedOrderItems => {
          const currentOrderItems = this.#orderItemsChange.getValue();

          currentOrderItems.set(targetOrder.id, targetOrder.orderedItems.concat(newlyAddedOrderItems));
          this.#orderItemsChange.next(currentOrderItems);

          newlyAddedOrderItems.forEach(orderItem => {
            this.changedPartialReceivedItems.set(orderItem.id, { ...orderItem, checked: true });
          });
        }),
      )
      .subscribe(() => {
        addOrderItemsDialog.closeDialog();
      });
  }

  canToggleSelection(order: Order): boolean {
    return this.rowSelection === GridSelectionMode.multiple && this.allowedSelectionStatuses.has(order.status);
  }

  areAllItemsSelected(): boolean {
    return this.ordersChange$.value.every(order => this.selectedOrders.has(order.id));
  }

  anyItemSelected(): boolean {
    return this.selectedOrders.size > 0;
  }

  @Span()
  onItemHeadCheck($event: boolean): void {
    if ($event) {
      this.ordersChange$.value.forEach(order => {
        if (this.canToggleSelection(order)) {
          const { id } = order;

          this.selectedOrders.add(id);
        }
      });
    } else {
      this.selectedOrders.clear();
    }

    this.handleRowSelection();
  }

  @Span()
  onItemCheck(order: Order): void {
    if (this.canToggleSelection(order)) {
      const { id } = order;

      this.selectedOrders.has(id) ? this.selectedOrders.delete(id) : this.selectedOrders.add(id);

      this.handleRowSelection();
    }
  }

  trackByProductId(_: number, orderItem: OrderItem): string {
    return orderItem.id;
  }

  onItemPriceChange(order: Order, orderItem: OrderItem, value: number, inputElement: InputComponent<number>): void {
    const supplier = order.partyInfo.supplier;

    if (supplier?.metadata?.partnershipType === SupplierPartnershipTypeEnum.Integrated) {
      this.snackbarService.open(
        `${supplier.name} is an Integrated Supplier. Kindly contact Supy or
        <a href="mailto:${supplier?.metadata?.partnershipContact ?? ''}" target="_blank" rel="noopener noreferrer">
        ${supplier.name} Support
        </a>
        to create or manage items for this Supplier.`,
        { variant: 'error' },
      );
      inputElement.writeValue(orderItem.price);
    } else {
      if (this.canOrderBePartiallyReceived(order)) {
        this.onChangePartialOrderItem(orderItem, value, inputElement, 'price');
      } else {
        this.onChangeReceivedOrderItem(orderItem, value, inputElement, 'price');
      }
    }
  }

  protected readonly getLocalizedName = getLocalizedName;
}
