import { Currency } from '@supy.api/dictionaries';

import { Branch, Channel, getApiDetailsDecorator, Supplier, User } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { sortOrderItems } from '../../../helpers';
import { EmailedOrderItem } from './emailed-order-item.entity';
import {
  EmailedOrderItemResponse,
  GetOrderByEmailResponse,
  OrderActivityResponse,
  OrderItemResponse,
  OrderModificationHistory,
  OrderStatus,
} from './order.model';
import { OrderItem } from './order-item.entity';

const ApiProperty = getApiDetailsDecorator<GetOrderByEmailResponse>();

export class EmailedOrder {
  private constructor(args: NonFunctionProperties<EmailedOrder>) {
    this.activities = args.activities;
    this.channel = args.channel;
    this.comment = args.comment;
    this.deliveryDate = args.deliveryDate;
    this.items = args.items;
    this.modificationHistory = args.modificationHistory;
    this.number = args.number;
    this.retailer = args.retailer;
    this.status = args.status;
    this.supplier = args.supplier;
    this.user = args.user;
  }

  @ApiProperty() readonly channel: Channel;
  @ApiProperty() readonly retailer: Branch;
  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly user: User;
  readonly activities: OrderActivityResponse[];
  readonly comment: Nullable<string>;
  readonly currency: Currency;
  readonly deliveryDate: Date;
  readonly items: EmailedOrderItem[];
  readonly modificationHistory: OrderModificationHistory[];
  readonly number: string;
  readonly status: OrderStatus;
  readonly orderedItems: OrderItem[];

  static deserialize(data: GetOrderByEmailResponse): EmailedOrder {
    return new EmailedOrder({
      retailer: data.retailer,
      channel: data.channel,
      comment: data.order.comment ?? null,
      supplier: data.supplier,
      deliveryDate: new Date(data.order.deliveryDate),
      number: data.order.number,
      user: data.user,
      activities: data.order.activities ?? [],
      orderedItems: data.order.ordered?.map(OrderItem.deserialize),
      status: data.order.status,
      currency: data.order.ordered.at(0).currency,
      modificationHistory: this.getModificationHistory({
        orderedItems: OrderItem.deserializeList(data.order.ordered),
        modifiedItems: data.order.modified ? OrderItem.deserializeList(data.order.modified) : [],
      }),
      items: this.deserializeItems({
        originalItems: data.products,
        orderedItems: data.order.ordered,
        modifiedItems: data.order.modified ?? [],
        receivedItems: data.order.received ?? [],
        isReceivedOrder: data.order.status === OrderStatus.Received,
      }),
    });
  }

  static deserializeItems({
    originalItems,
    orderedItems,
    modifiedItems,
    receivedItems,
    isReceivedOrder,
  }: {
    readonly originalItems: EmailedOrderItemResponse[];
    readonly orderedItems: OrderItemResponse[];
    readonly modifiedItems: OrderItemResponse[];
    readonly receivedItems: OrderItemResponse[];
    readonly isReceivedOrder: boolean;
  }): EmailedOrderItem[] {
    let items: EmailedOrderItem[];
    const originalItemsMap = new Map(originalItems.map(originalItem => [originalItem.productId, originalItem]));
    const orderedItemsMap = new Map(orderedItems.map(orderedItem => [orderedItem.productId, orderedItem]));
    const modifiedItemsMap = new Map(modifiedItems.map(modifiedItem => [modifiedItem.productId, modifiedItem]));

    if (isReceivedOrder) {
      items = receivedItems.map(receivedItem => {
        const originalItem = originalItemsMap.get(receivedItem.productId);
        const orderedQty = orderedItemsMap.get(receivedItem.productId)?.quantity ?? 0;

        return EmailedOrderItem.deserialize({
          ...receivedItem,
          appliedTax: originalItem.appliedTax,
          modifiedQty: modifiedItemsMap.get(receivedItem.productId)?.quantity ?? orderedQty,
          name_ar: originalItem?.name_ar ?? originalItem?.supplierItemName,
          orderedQty,
          receivedQty: receivedItem.quantity ?? 0,
          supplierItemName: originalItem?.supplierItemName ?? null,
        });
      });
    } else {
      items = orderedItems.map(orderedItem => {
        const modifiedItem = modifiedItemsMap.get(orderedItem.productId);
        const originalItem = originalItemsMap.get(orderedItem.productId);

        return EmailedOrderItem.deserialize({
          ...orderedItem,
          ...modifiedItem,
          appliedTax: originalItem.appliedTax,
          modifiedQty: modifiedItem?.quantity ?? orderedItem.quantity ?? 0,
          name_ar: originalItem?.name_ar ?? originalItem?.supplierItemName,
          orderedQty: orderedItem.quantity ?? 0,
          receivedQty: 0,
          supplierItemName: originalItem?.supplierItemName ?? null,
        });
      });
    }

    return sortOrderItems(items);
  }

  static getModificationHistory({
    orderedItems,
    modifiedItems,
  }: {
    readonly orderedItems: OrderItem[];
    readonly modifiedItems: OrderItem[];
  }): OrderModificationHistory[] {
    const orderedItemsMap = new Map<OrderItem['id'], OrderItem>(orderedItems.map(item => [item.id, item]));

    return modifiedItems.reduce<OrderModificationHistory[]>((acc, item) => {
      const orderedItemQuantity = orderedItemsMap.get(item.id)?.quantity ?? 0;

      if (orderedItemQuantity !== item.quantity) {
        acc.push({
          name: item.name,
          newValue: item.quantity,
          oldValue: orderedItemQuantity,
          productId: item.id,
          unit: item.unit,
        });
      }

      return acc;
    }, []);
  }
}
