import { formatDate } from '@angular/common';

import { getApiDetailsDecorator, SimpleUser, SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { RequisitionItem } from './requisition-item.entity';
import {
  RequisitionActivityResponse,
  RequisitionCategory,
  RequisitionResponse,
  RequisitionStatus,
  RequisitionTotals,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionResponse>();

type RequisitionArgs = Omit<
  Requisition,
  | 'isDiscardable'
  | 'createdByFullName'
  | 'updatedByFullName'
  | 'formattedDeliveryDate'
  | 'formattedRequestedOn'
  | 'branchName'
>;

export class Requisition {
  constructor(args: NonFunctionProperties<RequisitionArgs>) {
    this.id = args.id;
    this.activities = args.activities;
    this.approvedAt = args.approvedAt;
    this.approvedBy = args.approvedBy;
    this.branch = args.branch;
    this.categories = args.categories;
    this.comment = args.comment;
    this.createdAt = args.createdAt;
    this.createdBy = args.createdBy;
    this.deliveryDate = args.deliveryDate;
    this.hasDeletedItems = args.hasDeletedItems;
    this.items = args.items;
    this.location = args.location;
    this.number = args.number;
    this.status = args.status;
    this.totals = args.totals;
    this.updatedAt = args.updatedAt;
    this.updatedBy = args.updatedBy;

    // Computed properties
    this.branchName = `${this.branch.name} - ${this.location.name}`;
    this.createdByFullName = this.createdBy ? `${this.createdBy.firstName} ${this.createdBy.lastName}` : null;
    this.formattedDeliveryDate = this.deliveryDate ? formatDate(this.deliveryDate, 'dd/M/yyyy', 'en-GB') : null;
    this.formattedRequestedOn = this.createdAt ? formatDate(this.createdAt, 'hh:mm a - dd/M/yyyy', 'en-GB') : null;
    this.isDiscardable = this.status === RequisitionStatus.Pending;
    this.updatedByFullName = this.updatedBy ? `${this.updatedBy.firstName} ${this.updatedBy.lastName}` : null;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly activities: RequisitionActivityResponse[];
  @ApiProperty() readonly approvedAt: Nullable<Date>;
  @ApiProperty() readonly approvedBy: Nullable<SimpleUser>;
  @ApiProperty() readonly branch: SkeletonObjectType;
  @ApiProperty() readonly categories: RequisitionCategory[];
  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly createdAt: Nullable<Date>;
  @ApiProperty() readonly createdBy: Nullable<SimpleUser>;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly hasDeletedItems: boolean;
  @ApiProperty() readonly items: RequisitionItem[];
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly status: RequisitionStatus;
  @ApiProperty() readonly totals: RequisitionTotals;
  @ApiProperty() readonly updatedAt: Nullable<Date>;
  @ApiProperty() readonly updatedBy: Nullable<SimpleUser>;

  // Computed properties
  readonly branchName: string;
  readonly createdByFullName: Nullable<string>;
  readonly formattedDeliveryDate: Nullable<string>;
  readonly formattedRequestedOn: Nullable<string>;
  readonly isDiscardable: boolean;
  readonly updatedByFullName: Nullable<string>;

  static deserialize(data: RequisitionResponse): Requisition {
    return new Requisition({
      id: data.id,
      activities: data.activities ?? [],
      approvedAt: data.approvedAt ?? null,
      approvedBy: data.approvedBy ?? null,
      branch: data.branch,
      categories: data.categories,
      comment: data.comment ?? null,
      createdAt: data.createdAt ?? null,
      createdBy: data.createdBy ?? null,
      deliveryDate: data.deliveryDate,
      hasDeletedItems: data.hasDeletedItems,
      items: data.items?.map(RequisitionItem.deserialize) ?? [],
      location: data.location,
      number: data.number,
      status: data.status,
      totals: data.totals,
      updatedAt: data.updatedAt ?? null,
      updatedBy: data.updatedBy ?? null,
    });
  }
}
