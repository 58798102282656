import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

import { IconType } from '@supy/components';

@Component({
  selector: 'supy-retailer-order-details-widget',
  templateUrl: './retailer-order-details-widget.component.html',
  styleUrls: ['./retailer-order-details-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsWidgetComponent {
  protected readonly isInitialLoading = input<boolean>();
  protected readonly header = input<string>();
  protected readonly content = input<string>();
  protected readonly disabled = input<boolean>();
  protected readonly headerIcon = input<IconType>();

  @HostBinding('style.minHeight.rem')
  protected readonly minHeight = input<string>('5');
}
