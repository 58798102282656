import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { UnleashModule } from '@supy/common';

import { OrderResolver, OrdersResolver, SubmitOrderResolver } from './resolvers';
import { GrnsService, OrdersService, RequisitionsService, SupplierReturnsService } from './services';
import {
  CategoryOrdersState,
  CreditNotesState,
  GrnsState,
  OrdersState,
  RequisitionsByLocationState,
  RequisitionsByPackagingState,
  RequisitionsState,
  SupplierReturnsState,
} from './store';

@NgModule({
  providers: [
    TitleCasePipe,
    OrderResolver,
    OrdersResolver,
    SubmitOrderResolver,
    OrdersService,
    GrnsService,
    SupplierReturnsService,
    RequisitionsService,
  ],
  imports: [
    UnleashModule,
    NgxsModule.forFeature([
      OrdersState,
      CategoryOrdersState,
      GrnsState,
      CreditNotesState,
      SupplierReturnsState,
      RequisitionsState,
      RequisitionsByLocationState,
      RequisitionsByPackagingState,
    ]),
  ],
})
export class OrdersModule {}
