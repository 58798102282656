import { getApiDetailsDecorator, TaxRate } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { EmailedOrderItemResponse } from './order.model';

const ApiProperty = getApiDetailsDecorator<EmailedOrderItemResponse>();

export class EmailedOrderItem {
  private constructor(args: NonFunctionProperties<EmailedOrderItem>) {
    this.appliedTax = args.appliedTax;
    this.id = args.id;
    this.itemCode = args.itemCode;
    this.modifiedQty = args.modifiedQty;
    this.name = args.name;
    this.nameAr = args.nameAr;
    this.price = args.price;
    this.quantity = args.quantity;
    this.receivedQty = args.receivedQty;
    this.supplierItemName = args.supplierItemName;
    this.unit = args.unit;
    this.orderedQty = args.orderedQty;
  }

  @ApiProperty() readonly appliedTax: Nullable<TaxRate>;
  @ApiProperty({ key: 'productId' }) readonly id: string;
  @ApiProperty() readonly itemCode: Nullable<string>;
  @ApiProperty() readonly modifiedQty: number;
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly orderedQty: number;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly receivedQty: number;
  @ApiProperty() readonly supplierItemName: Nullable<string>;
  @ApiProperty() readonly unit: Nullable<string>;
  readonly nameAr: Nullable<string>;

  static deserialize(data: EmailedOrderItemResponse): EmailedOrderItem {
    return new EmailedOrderItem({
      appliedTax: data.appliedTax ?? null,
      id: data.productId,
      itemCode: data.itemCode ?? null,
      modifiedQty: data.modifiedQty ?? 0,
      name: data.name ?? null,
      nameAr: data.name_ar ?? null,
      orderedQty: data.orderedQty,
      price: data.price ?? 0,
      quantity: data.quantity ?? 0,
      receivedQty: data.receivedQty ?? 0,
      supplierItemName: data.supplierItemName ?? null,
      unit: data.unit ?? null,
    });
  }

  static deserializeList(data: EmailedOrderItemResponse[]): EmailedOrderItem[] {
    return data.map(this.deserialize);
  }
}
