import { ChangeDetectionStrategy, Component, effect, inject, Injector, input, OnInit, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IANATimezone } from '@supy.api/dictionaries';

import { Destroyable, getDefaultEndDate, getDefaultStartDate, Supplier } from '@supy/common';
import { DropdownTreeNode, FilterChange, FilterGroup, getWeeksQuartersRange } from '@supy/components';
import { Nullable } from '@supy/core';

import { OrderStatus } from '../../../core';
import { OrdersFilters } from '../../../store';
import { getOrdersFiltersConfig, OrdersMappedFilters } from './filters-config';
import { OrderTab, OrderTabValue } from './tabs';

@Component({
  selector: 'supy-retailer-orders-grid-filters',
  templateUrl: './retailer-orders-grid-filters.component.html',
  styleUrls: ['./retailer-orders-grid-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrdersGridFiltersComponent extends Destroyable implements OnInit {
  readonly #injector = inject(Injector);

  protected readonly filters = input.required<OrdersFilters>();
  protected readonly ianaTimeZone = input.required<IANATimezone>();
  protected readonly locations = input.required<DropdownTreeNode<string>[]>();
  protected readonly isB2b = input<boolean>();
  protected readonly suppliers = input.required<Supplier[]>();
  protected readonly tabs = input.required<OrderTab<OrderTabValue>[]>();
  protected readonly utcOffset = input.required<number>();
  protected readonly filtersChanged = output<Partial<OrdersFilters>>();
  protected readonly filtersCleared = output<void>();

  protected readonly tabFormControl = new FormControl<Nullable<string>>(null);
  protected readonly filtersGroup = new FilterGroup<FilterChange<OrdersMappedFilters>>(getOrdersFiltersConfig());
  protected readonly predefinedDateRanges = getWeeksQuartersRange();

  ngOnInit(): void {
    this.patchTab();
    this.patchPONumberFilter();
    this.patchBranchesFilter();
    this.patchSuppliersFilter();
    this.patchDateRangeFilter();
  }

  protected onFiltersChange({ branches, suppliers, dateRange, search }: FilterChange<OrdersMappedFilters>): void {
    const startDate = dateRange?.start ?? getDefaultStartDate();
    const endDate = dateRange?.end ?? getDefaultEndDate();

    this.filtersChanged.emit({
      branches,
      suppliers,
      lpoRestaurant: search,
      start: startDate.getTime(),
      end: endDate.getTime(),
    });
  }

  protected onSelectTab($event: Nullable<OrderStatus>): void {
    this.filtersChanged.emit({
      status: $event,
    });
  }

  private patchTab(): void {
    effect(
      () => {
        this.tabFormControl.patchValue(this.filters()?.status ?? null);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private patchPONumberFilter(): void {
    effect(
      () => {
        this.filtersGroup.updateSearchProperty({
          value: this.filters()?.lpoRestaurant ?? null,
        });
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private patchBranchesFilter(): void {
    effect(
      () => {
        const options = this.locations();

        this.filtersGroup.updateSelectionProperty('branches', {
          value: this.filters()?.branches,
          options,
          disabled: options.length < 1,
        });
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private patchSuppliersFilter(): void {
    effect(
      () => {
        const options = this.suppliers() ?? [];

        this.filtersGroup.updateSelectionProperty('suppliers', {
          value: this.filters()?.suppliers,
          options,
          disabled: options.length < 1,
        });
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private patchDateRangeFilter(): void {
    effect(
      () => {
        const filters = this.filters();

        this.filtersGroup.updateSelectionProperty('dateRange', {
          value: {
            start: filters.start ? new Date(Number(filters.start)) : null,
            end: filters.end ? new Date(Number(filters.end)) : null,
          },
        });
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }
}
