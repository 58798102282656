import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { BaseItem } from '@supy/inventory';
import { Packaging } from '@supy/packaging';

import {
  RequisitionCategory,
  RequisitionChannelItem,
  RequisitionItemResponse,
  RequisitionTotals,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionItemResponse>();

export class RequisitionItem {
  private constructor(args: NonFunctionProperties<RequisitionItem>) {
    this.id = args.id;
    this.packaging = args.packaging;
    this.retailerItem = args.retailerItem;
    this.category = args.category;
    this.selectedChannelItem = args.selectedChannelItem;
    this.channelItems = args.channelItems;
    this.quantity = args.quantity;
    this.totals = args.totals;
    this.category = args.category;
    this.isSupplierReplaced = args.isSupplierReplaced;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly packaging: Packaging;
  @ApiProperty() readonly retailerItem: BaseItem;
  @ApiProperty() readonly category: RequisitionCategory;
  @ApiProperty() readonly selectedChannelItem: RequisitionChannelItem;
  @ApiProperty() readonly channelItems: RequisitionChannelItem[];
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly totals: RequisitionTotals;
  @ApiProperty({ key: 'supplierReplaced' }) readonly isSupplierReplaced: boolean;

  static deserialize(data: RequisitionItemResponse): RequisitionItem {
    return new RequisitionItem({
      id: data.id,
      packaging: data.packaging,
      category: data.category,
      retailerItem: data.retailerItem,
      selectedChannelItem: data.selectedChannelItem,
      channelItems: data.channelItems ?? [],
      quantity: data.quantity,
      totals: data.totals,
      isSupplierReplaced: data.supplierReplaced ?? false,
    });
  }
}
