<header *supyContext="order() as order" class="retailer-order-details-header">
  <div class="retailer-order-details-header__wrapper">
    <div class="retailer-order-details-header__title">
      @if (isInitialLoading()) {
        <div class="retailer-order-details-header__title--initial-loading"></div>
      }
      <h5>
        {{ headerTitle() }}
      </h5>
      <supy-retailer-order-status-badge [status]="order?.status" [forInventory]="forInventory()" [forCk]="forCk()" />
    </div>
    <div class="retailer-order-details-header__icons">
      <ng-content />
      @if (order) {
        <supy-button
          type="icon"
          title="Previous"
          color="default"
          name="previous"
          [disabled]="previousDisabled()"
          (buttonClick)="previousClicked.emit(order)"
        >
          <supy-icon name="long-arrow-left-circle" />
        </supy-button>
        <supy-button
          type="icon"
          title="Next"
          color="default"
          name="next"
          [disabled]="nextDisabled()"
          (buttonClick)="nextClicked.emit(order)"
        >
          <supy-icon name="long-arrow-right-circle" />
        </supy-button>
        <supy-dropdown-toggle [dropdownRef]="supyDropdown" position="bottom-left" action>
          <supy-button color="default" type="icon" name="more-actions">
            <supy-icon name="dots" />
          </supy-button>
        </supy-dropdown-toggle>
      }
      <supy-dropdown #supyDropdown action>
        @if (!hidePrices()) {
          <supy-dropdown-item (click)="downloadPdfClicked.emit()">
            <supy-icon name="document-download" />
            <span i18n="@@common.actions.downloadPdf">Download PDF</span>
          </supy-dropdown-item>
        }
        @if (isRepeatOrderShown()) {
          <supy-dropdown-item (click)="repeatOrderClicked.emit()">
            <supy-icon name="refresh" />
            <span i18n="@@orderRepeat">Repeat Order</span>
          </supy-dropdown-item>
        }
      </supy-dropdown>
    </div>
  </div>
</header>
