import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { Order } from '../../../core';

@Component({
  selector: 'supy-retailer-order-details-header',
  templateUrl: './retailer-order-details-header.component.html',
  styleUrls: ['./retailer-order-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsHeaderComponent<T extends Pick<Order, 'id' | 'number' | 'status'>> {
  protected readonly hidePrices = input.required<boolean>();
  protected readonly forCk = input<boolean>();
  protected readonly isInitialLoading = input<boolean>();
  protected readonly forInventory = input<boolean>();
  protected readonly isRepeatOrderShown = input<boolean>();
  protected readonly nextDisabled = input.required<boolean>();
  protected readonly order = input.required<T>();
  protected readonly previousDisabled = input.required<boolean>();
  protected readonly nextClicked = output<T>();
  protected readonly previousClicked = output<T>();
  protected readonly downloadPdfClicked = output<void>();
  protected readonly repeatOrderClicked = output<void>();

  protected readonly headerTitle = computed(() => {
    const order = this.order();

    return order?.id
      ? $localize`:@@orders.details.headerInterpolation:Order #${order.number}:INTERPOLATION:`
      : $localize`:@@newOrder:New Order`;
  });
}
