<div
  *supyContext="{
    requestMetadata: requestMetadata(),
    responseMetadata: responseMetadata(),
  } as context"
  class="supy-retailer-orders-list"
>
  @if (isLoading()) {
    <supy-loading-overlay igxPreventDocumentScroll />
  }

  <supy-grid-poc
    class="supy-retailer-orders-list__grid"
    rowSelection="multiple"
    height="100%"
    domLayout="normal"
    [data]="orders()"
    [columnDefs]="columnDefs()"
    [disableSelectOnClick]="true"
    [defaultColDefs]="defaultColDefs"
    [isPaginated]="true"
    [pageIndex]="context.requestMetadata.page"
    [perPage]="context.requestMetadata.limit"
    [prevPageDisabled]="context.requestMetadata.page === 0"
    [nextPageDisabled]="context.responseMetadata.count < context.requestMetadata.limit"
    (cellClick)="onClickCell($event)"
    (pageChange)="pageChanged.emit($event)"
    (gridReady)="setColumnDefs()"
  />
</div>
