import { CellClickedEvent, ColDef, ICellRendererParams, ValueFormatterParams } from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { BaseResponseMetadata, PrecisionPipe, TimezonePipe } from '@supy/common';
import {
  GridPocComponent,
  IconStatusBadgeCellRendererComponent,
  IconStatusBadgeCellRendererContext,
  TextWithIconCellRendererComponent,
  TextWithIconCellRendererContext,
} from '@supy/components';

import {
  InventoryOrderStatusNameMapper,
  Order,
  OrderStatus,
  OrderStatusBadgeMapper,
  ProcurementOrderStatusNameMapper,
} from '../../../core';
import { OrdersRequestMetadata } from '../../../store';

@Component({
  selector: 'supy-retailer-orders-grid',
  templateUrl: './retailer-orders-grid.component.html',
  styleUrls: ['./retailer-orders-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe, TimezonePipe, PrecisionPipe],
})
export class RetailerOrdersGridComponent {
  readonly #injector = inject(Injector);
  readonly #datePipe = inject(DatePipe);
  readonly #precisionPipe = inject(PrecisionPipe);
  readonly #timezonePipe = inject(TimezonePipe);
  protected readonly grid = viewChild(GridPocComponent<Order>);
  protected readonly currency = input.required<Currency>();
  protected readonly currencyPrecision = input.required<number>();
  protected readonly isLoading = input.required<boolean>();
  protected readonly orders = input.required<Order[]>();
  protected readonly requestMetadata = input.required<OrdersRequestMetadata>();
  protected readonly responseMetadata = input.required<BaseResponseMetadata>();
  protected readonly forInventory = input<boolean>();
  protected readonly ianaTimeZone = input.required<IANATimezone>();
  protected readonly hidePrices = input<boolean>();
  protected readonly pageChanged = output<number>();
  protected readonly poNumberClicked = output<Order>();
  protected readonly cellClicked = output<Order>();

  protected readonly columnDefs = signal<ColDef<Order>[]>([]);
  protected readonly defaultColDefs = { cellStyle: { border: 'none', cursor: 'pointer' } };

  get selectedRows(): Order[] {
    return this.grid()
      ? this.grid()
          .api.getSelectedNodes()
          .map(({ data }) => data)
      : [];
  }

  protected setColumnDefs(): void {
    effect(
      () => {
        const ianaTimeZone = this.ianaTimeZone();

        this.columnDefs.set([
          {
            width: 50,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            hide: !this.forInventory(),
          },
          {
            headerName: $localize`:@@grid.headers.poNumber.label:PO #`,
            field: 'number',
            flex: 0.5,
            tooltipField: 'number',
            cellRenderer: TextWithIconCellRendererComponent,
            cellRendererParams: ({
              data,
              value,
            }: ICellRendererParams<Order, string, TextWithIconCellRendererContext>) => ({
              context: {
                text: value,
                callback: (): void => {
                  this.poNumberClicked.emit(data);
                },
                postfixIcons: data.isCreatedByCk
                  ? [
                      {
                        name: 'shop-add',
                        color: 'primary',
                        title: $localize`:@@orders.inventory.grid.orderCreatedByCk:Order Created by CK`,
                      },
                    ]
                  : [],
              },
            }),
          },
          {
            headerName: $localize`:@@common.branchAndLocation:Branch - Location`,
            field: 'displayedLocationName',
            flex: 0.75,
            tooltipField: 'displayedLocationName',
          },
          {
            headerName: $localize`:@@supplier:Supplier`,
            field: 'displayedSupplierName',
            flex: 0.75,
            tooltipField: 'displayedSupplierName',
          },
          {
            headerName: $localize`:@@common.submittedOn:Submitted On`,
            field: 'submittedAt',
            flex: 0.5,
            valueFormatter: ({ value }: ValueFormatterParams<Order, Date>) => {
              const date = this.#timezonePipe.transform(value, ianaTimeZone);

              return `${this.#datePipe.transform(date, 'dd/MM/yyyy')}  ${this.#datePipe.transform(date, 'h:mm a')}`;
            },
          },
          {
            headerName: $localize`:@@common.expDeliveryDate:Exp. Delivery Date`,
            field: 'deliveryDate',
            flex: 0.5,
            valueFormatter: ({ value }: ValueFormatterParams<Order, Date>) =>
              this.#datePipe.transform(this.#timezonePipe.transform(value, ianaTimeZone), 'dd/MM/yyyy'),
          },
          {
            headerName: $localize`:@@common.totalInterpolation:Total (${this.currency()}:INTERPOLATION:)`,
            flex: 0.25,
            type: 'rightAligned',
            hide: this.hidePrices(),
            valueFormatter: ({ data }: ValueFormatterParams<Order>) =>
              this.#precisionPipe.transform(
                data.modifiedItemsTotal || data.orderedItemsTotal,
                this.currencyPrecision(),
              ),
          },
          {
            headerName: $localize`:@@status:Status`,
            field: 'status',
            flex: 0.5,
            cellRenderer: IconStatusBadgeCellRendererComponent,
            cellRendererParams: ({
              value,
            }: ICellRendererParams<Order, OrderStatus, IconStatusBadgeCellRendererContext>) => ({
              context: {
                text: this.forInventory()
                  ? InventoryOrderStatusNameMapper[value]
                  : ProcurementOrderStatusNameMapper[value],
                status: OrderStatusBadgeMapper[value],
                width: '100%',
                weight: 'normal',
              },
            }),
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  protected onClickCell({ colDef, data }: CellClickedEvent<Order>): void {
    // Ignoring the click on PO # column to trigger its callback for opening the drawer.
    if (colDef.field !== 'number') {
      this.cellClicked.emit(data);
    }
  }
}
