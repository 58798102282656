import { formatDate } from '@angular/common';

import { getApiDetailsDecorator, isOneOf, SimpleUser } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { Packaging } from '@supy/packaging';

import {
  RequisitionByPackagingResponse,
  RequisitionCategory,
  RequisitionsByPackagingLocationResponse,
  RequisitionStatus,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionByPackagingResponse>();

type RequisitionByPackagingArgs = Omit<
  RequisitionByPackaging,
  'formattedRequestedOn' | 'formattedDeliveryDate' | 'isEditable'
>;

export class RequisitionByPackaging {
  private constructor(args: NonFunctionProperties<RequisitionByPackagingArgs>) {
    this.id = args.id;
    this.businessDeliveryDate = args.businessDeliveryDate;
    this.category = args.category;
    this.deliveryDate = args.deliveryDate;
    this.locations = args.locations;
    this.packaging = args.packaging;
    this.retailerId = args.retailerId;
    this.status = args.status;
    this.updatedAt = args.updatedAt;
    this.users = args.users;

    // Computed properties
    this.formattedDeliveryDate = this.deliveryDate ? formatDate(this.deliveryDate, 'dd/M/yyyy', 'en-GB') : null;
    this.formattedRequestedOn = this.updatedAt ? formatDate(this.updatedAt, 'hh:mm a - dd/M/yyyy', 'en-GB') : null;
    this.isEditable = isOneOf(this.status, [RequisitionStatus.Pending, RequisitionStatus.Approved]);
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly businessDeliveryDate: string;
  @ApiProperty() readonly category: RequisitionCategory;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly locations: RequisitionsByPackagingLocationResponse[];
  @ApiProperty() readonly packaging: Packaging;
  @ApiProperty() readonly retailerId: string;
  @ApiProperty() readonly status: RequisitionStatus;
  @ApiProperty() readonly updatedAt: Date;
  @ApiProperty() readonly users: SimpleUser[];

  // Computed properties
  readonly formattedDeliveryDate: Nullable<string>;
  readonly formattedRequestedOn: Nullable<string>;
  readonly isEditable: boolean;

  static deserialize(data: RequisitionByPackagingResponse): RequisitionByPackaging {
    return new RequisitionByPackaging({
      id: data.id,
      retailerId: data.retailerId,
      category: data.category,
      packaging: data.packaging,
      deliveryDate: data.deliveryDate,
      businessDeliveryDate: data.businessDeliveryDate,
      status: data.status,
      locations: data.locations ?? [],
      users: data.users,
      updatedAt: data.updatedAt,
    });
  }
}
