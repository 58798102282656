<div class="retailer-order-details-widget" [class.retailer-order-details-widget--disabled]="disabled()">
  @if (isInitialLoading()) {
    <div class="retailer-order-details-widget--initial-loading"></div>
  }
  @if (header(); as header) {
    <div class="retailer-order-details-widget__header">
      @if (headerIcon(); as headerIcon) {
        <supy-icon [name]="headerIcon" size="small" />
      }
      <h5 class="retailer-order-details-widget__header-title">{{ header }}</h5>
    </div>
  } @else {
    <div class="retailer-order-details-widget__header">
      <ng-content select="[widgetHeader]" />
    </div>
  }
  @if (content(); as content) {
    <p
      class="retailer-order-details-widget__content"
      [ngClass]="{ 'retailer-order-details-widget__content-with-margin': headerIcon() }"
      [title]="content"
    >
      {{ content }}
    </p>
  } @else {
    <ng-content />
  }
</div>
