import { map, Observable, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { BaseUnleashResolver, UnleashService } from '@supy/common';

import { Order } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class OrderResolver extends BaseUnleashResolver<Order> {
  private readonly ordersService = inject(OrdersService);
  protected readonly unleashService = inject(UnleashService);

  resolve(route: ActivatedRouteSnapshot): Promise<Order> | Observable<Order> {
    return this.ordersService.get(route.queryParamMap.get('orderId')).pipe(
      map(Order.deserialize),
      tap(({ retailerId }) => this.updateContextAndResolve({ userId: retailerId })),
    );
  }
}
