<div class="supy-order-items-grid" *supyContext="orderValue() as order">
  <supy-grid-poc
    [data]="orderItems()"
    [defaultColDefs]="defaultColDefs()"
    [columnDefs]="columnDefs()"
    (gridReady)="setColumnDefs()"
    [getRowClass]="getRowClass"
  />
  <supy-order-items-footer
    [showComments]="showComments"
    [currencyPrecision]="currencyPrecision"
    [rejectionReason]="rejectionReason()"
    [orderSubTotal]="orderSubTotal()"
    [orderVatValue]="orderVatValue()"
    [orderVatPercentage]="orderVatPercentage()"
    [orderTotalAfterVat]="orderTotalAfterVat()"
    [currency]="order?.currency"
    [comment]="order?.comment"
    [hidePrices]="hidePrices"
    [hideUnits]="hideUnits"
  />
</div>
