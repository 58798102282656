import {
  CreateRequisitionRequest,
  DiscardRequisitionsRequest,
  ProcessRequisitionsRequest,
  RequisitionFilters,
  UpdateRequisitionRequest,
} from '../../core';
import { RequisitionsRequestMetadata } from '../state';

export class RequisitionsGetMany {
  static readonly type = '[Requisitions] Get Many';
}

export class RequisitionsGetOne {
  static readonly type = '[Requisitions] Get One';

  constructor(readonly id: string) {}
}

export class RequisitionsCreate {
  static type = '[Requisitions] CreateRequisition';

  constructor(readonly payload: CreateRequisitionRequest) {}
}

export class RequisitionsUpdate {
  static type = '[Requisitions] UpdateRequisition';

  constructor(readonly payload: UpdateRequisitionRequest) {}
}

export class RequisitionsInitFilters {
  static readonly type = '[Requisitions] Init Filters';
}

export class RequisitionsSetFilters {
  static readonly type = '[Requisitions] Set Filters';

  constructor(readonly payload: RequisitionFilters) {}
}

export class RequisitionsPatchFilters {
  static readonly type = '[Requisitions] Patch Filters';

  constructor(readonly payload: Partial<RequisitionFilters>) {}
}

export class RequisitionsResetFilters {
  static readonly type = '[Requisitions] Reset Filters';

  constructor(readonly payload: Partial<RequisitionFilters>) {}
}

export class RequisitionsPatchRequestMetadata {
  static readonly type = '[Requisitions] Patch Request Metadata';

  constructor(readonly payload: Partial<RequisitionsRequestMetadata>) {}
}

export class RequisitionsDiscard {
  static readonly type = '[Requisitions] Discard';

  constructor(readonly id: string) {}
}

export class RequisitionsDiscardMany {
  static readonly type = '[Requisitions] Discard Many';

  constructor(readonly payload: DiscardRequisitionsRequest) {}
}

export class RequisitionsProcess {
  static readonly type = '[Requisitions] Process';

  constructor(readonly payload: ProcessRequisitionsRequest) {}
}
export class RequisitionsResetSequence {
  static readonly type = '[Requisitions] Reset Sequence';
}

export class RequisitionsResetDetailed {
  static readonly type = '[Requisitions] Reset Detailed';
}
