import produce from 'immer';
import { tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { CategoryOrder, CategoryOrderBasket, ProcessCategoryOrderResponse } from '../../core';
import { CategoryOrdersService } from '../../services';
import {
  CategoryOrdersCreate,
  CategoryOrdersGet,
  CategoryOrdersGetMany,
  CategoryOrdersProcess,
  CategoryOrdersResetBasket,
  CategoryOrdersUpdateBasket,
} from '../actions';

interface CategoryOrdersStoreState {
  readonly categoryOrders: CategoryOrder[];
  readonly categoryOrder: CategoryOrder;
  readonly basket: CategoryOrderBasket;
  readonly processedResponse?: ProcessCategoryOrderResponse;
}

const ORDERS_STATE_TOKEN = new StateToken<CategoryOrdersStoreState>('categoryOrders');

@State<CategoryOrdersStoreState>({
  name: ORDERS_STATE_TOKEN,
  defaults: {
    categoryOrders: [],
    categoryOrder: null,
    basket: { items: [], categoryId: null },
  },
})
@Injectable()
export class CategoryOrdersState {
  private readonly categoryOrdersService = inject(CategoryOrdersService);

  @Selector()
  static categoryOrders(state: CategoryOrdersStoreState) {
    return state.categoryOrders;
  }

  @Selector()
  static categoryOrder(state: CategoryOrdersStoreState) {
    return state.categoryOrder;
  }

  @Selector()
  static processedResponse(state: CategoryOrdersStoreState) {
    return state.processedResponse;
  }

  @Selector()
  static basket(state: CategoryOrdersStoreState) {
    return state.basket;
  }

  @Action(CategoryOrdersGetMany)
  getMany(ctx: StateContext<CategoryOrdersStoreState>, { query, byBranch }: CategoryOrdersGetMany) {
    return this.categoryOrdersService
      .getCategoryOrders(query, byBranch)
      .pipe(tap(({ data }) => ctx.patchState({ categoryOrders: data })));
  }

  @Action(CategoryOrdersGet)
  get(ctx: StateContext<CategoryOrdersStoreState>, { id }: CategoryOrdersGet) {
    return this.categoryOrdersService.getCategoryOrder(id).pipe(tap(order => ctx.patchState({ categoryOrder: order })));
  }

  @Action(CategoryOrdersCreate)
  create(ctx: StateContext<CategoryOrdersStoreState>, { args }: CategoryOrdersCreate) {
    return this.categoryOrdersService
      .createCategoryOrder({
        ...args,
        orderedPackages: ctx.getState().basket.items.map(({ channelItem, ...props }) => ({
          ...props,
          channelItem: { id: channelItem.id },
        })),
      })
      .pipe(
        tap(categoryOrder =>
          ctx.setState(
            produce(draft => {
              draft.categoryOrders.push(categoryOrder);
            }),
          ),
        ),
      );
  }

  @Action(CategoryOrdersProcess)
  process(ctx: StateContext<CategoryOrdersStoreState>, { payload }: CategoryOrdersProcess) {
    return this.categoryOrdersService
      .processCategoryOrder(payload)
      .pipe(tap(response => ctx.patchState({ processedResponse: response })));
  }

  @Action(CategoryOrdersUpdateBasket)
  updateBasket(ctx: StateContext<CategoryOrdersStoreState>, { args }: CategoryOrdersUpdateBasket) {
    ctx.setState(
      produce(draft => {
        draft.basket = {
          ...draft.basket,
          items: args.items ?? draft.basket.items,
          categoryId: args.categoryId ?? draft.basket.categoryId,
        };
      }),
    );
  }

  @Action(CategoryOrdersResetBasket)
  resetBasket(ctx: StateContext<CategoryOrdersStoreState>) {
    ctx.setState(
      produce(draft => {
        draft.basket.items = [];
        draft.basket.categoryId = null;
      }),
    );
  }
}
