import {
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  NewValueParams,
  RowClassParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { IGridEditDoneEventArgs } from '@infragistics/igniteui-angular';
import { Language } from '@supy.api/dictionaries';

import { isOneOf, PrecisionPipe } from '@supy/common';
import {
  ActionCellRendererActionTypes,
  ActionsCellRendererComponent,
  ActionsCellRendererContext,
  ARABIC_SELECT_OPTION,
  ENGLISH_SELECT_OPTION,
  GridPocComponent,
  InputCellEditorComponent,
  InputCellEditorContext,
} from '@supy/components';
import { TaxRate } from '@supy/settings';

import { EmailedOrder, EmailedOrderItem, OrderItem, OrderStatus, OrderUpdateStatus } from '../../../../core';
import {
  HeaderLanguageSelectCellRendererComponent,
  HeaderLanguageSelectCellRendererContext,
} from '../header-language-select-cell-renderer';

export interface IUpdateOrderQuantity {
  productId: string;
  quantity: number;
}

@Component({
  selector: 'supy-order-items-grid',
  templateUrl: './order-items-grid.component.html',
  styleUrls: ['./order-items-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemsGridComponent {
  @ViewChild(GridPocComponent, { static: true }) private readonly grid: GridPocComponent<EmailedOrderItem>;

  @Input() set order(value: EmailedOrder) {
    this.orderValue.set(value);
  }

  @Input() readonly hidePrices?: boolean;
  @Input() readonly hideUnits?: boolean;
  @Input() readonly showComments: boolean;
  @Input() readonly displayCode: boolean;
  @Input() readonly displayUom: boolean;

  @Input() set canPerformActions(value: boolean) {
    this.#canPerformActions.set(value);
  }

  @Input() set items(value: EmailedOrderItem[]) {
    if (value) {
      this.orderItems.set(structuredClone(value ?? []));

      this.#originalItemsBeforeChanges.clear();

      value.forEach(item => {
        this.#originalItemsBeforeChanges.set(item.id, item);
      });
    }
  }

  @Input() readonly showPreferredFlag: boolean;
  @Input() readonly currencyPrecision: number;

  @Output() readonly rowEditDone: EventEmitter<IGridEditDoneEventArgs> = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly updateStock: EventEmitter<{ rowData: OrderItem; returnInStock: boolean }> = new EventEmitter<{
    rowData: OrderItem;
    returnInStock: boolean;
  }>();

  @Output() readonly updateQuantity: EventEmitter<IUpdateOrderQuantity> = new EventEmitter<IUpdateOrderQuantity>();
  @Output() readonly startEdit: EventEmitter<number> = new EventEmitter<number>();

  readonly #injector = inject(Injector);
  readonly #precision = inject(PrecisionPipe);

  protected readonly defaultColDefs = signal<ColDef<EmailedOrderItem>>({
    suppressHeaderKeyboardEvent: () => true,
    cellStyle: { border: 'none' },
  });

  readonly #canPerformActions = signal<boolean>(false);

  readonly #originalItemsBeforeChanges = new Map<string, EmailedOrderItem>();

  protected readonly orderValue = signal<EmailedOrder | null>(null);

  protected readonly orderItems = signal<EmailedOrderItem[]>([]);

  protected readonly selectedLanguage = signal<Language>(Language.English);

  protected readonly columnDefs = signal<ColDef<EmailedOrderItem>[]>([]);

  protected readonly isReceivedOrder = computed(() =>
    isOneOf(this.orderValue().status, [OrderStatus.Received, OrderStatus.PartialReceived]),
  );

  protected readonly isModifiedOrder = computed(() => this.orderValue().status === OrderStatus.Confirmed);

  protected readonly hasConfirmedQty = computed(() =>
    this.orderValue().activities.some(update =>
      isOneOf(update.type, [OrderUpdateStatus.Confirmed, OrderUpdateStatus.Modified]),
    ),
  );

  protected readonly rejectionReason = computed(
    () => this.orderValue().activities.find(item => item.type === OrderUpdateStatus.Rejected)?.comment,
  );

  protected readonly isEnglishSelected = computed(() => this.selectedLanguage() === Language.English);

  protected readonly orderSubTotal = computed(() => {
    return this.orderItems().reduce((acc, item) => acc + this.getSubTotal(item), 0);
  });

  protected readonly orderVatValue = computed(() =>
    this.orderItems().reduce((acc, item) => acc + this.getAppliedTax(item), 0),
  );

  protected readonly orderVatPercentage = computed(
    () => +Number((this.orderVatValue() / this.orderSubTotal()) * 100).toFixed(2),
  );

  protected readonly orderTotalAfterVat = computed(() => this.orderSubTotal() + this.orderVatValue());

  protected readonly isTablet = computed(() => window.innerWidth < 768);

  private readonly getColumnWidth = computed(() => {
    if (this.isTablet()) {
      return this.#canPerformActions() ? 1.5 : 2.2;
    }

    return 1;
  });

  private readonly getQtyPriceColumnWidth = computed(() => (this.isTablet() ? 1.7 : 0.7));

  private readonly isConfirmedColumnHidden = computed(() =>
    this.isTablet() ? this.isReceivedOrder() || !this.hasConfirmedQty() : !this.hasConfirmedQty(),
  );

  get data(): EmailedOrderItem[] {
    return this.orderItems();
  }

  protected readonly getRowClass = ({ data }: RowClassParams<EmailedOrderItem>) => {
    if (this.isOutOfStock(data)) {
      if (!this.#canPerformActions()) {
        return 'supy-order-items-grid--out-of-stock-full';
      }

      return 'supy-order-items-grid--out-of-stock';
    }
  };

  protected setColumnDefs(): void {
    effect(
      () => {
        this.columnDefs.set([
          {
            headerName: 'item code',
            field: 'itemCode',
            hide: !this.displayCode,
            flex: this.getColumnWidth(),
          },
          {
            headerName: 'name',
            field: this.isEnglishSelected() ? 'supplierItemName' : 'nameAr',
            flex: this.isTablet() ? 3.5 : 2.4,
            wrapText: true,
            autoHeight: true,
            headerComponent: HeaderLanguageSelectCellRendererComponent,
            headerComponentParams: {
              context: {
                headerName: 'name',
                languages: [ENGLISH_SELECT_OPTION, ARABIC_SELECT_OPTION],
                valueChanged: value => this.onLanguageChange(value),
                value: this.selectedLanguage(),
              } as HeaderLanguageSelectCellRendererContext,
            },
          },
          {
            headerName: 'unit',
            field: 'unit',
            flex: this.getColumnWidth(),
            hide: this.isTablet() || this.hideUnits,
          },
          {
            headerName: 'ordered qty',
            field: 'orderedQty',
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value),

            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                precision: this.currencyPrecision,
                placeholder: `0.000`,
                numeric: true,
              } as InputCellEditorContext,
            },
            editable: this.isConfirmedColumnHidden() && !this.isReceivedOrder() && this.#canPerformActions(),
            flex: this.getQtyPriceColumnWidth(),
            onCellValueChanged: (event: NewValueParams<EmailedOrderItem, number>) =>
              ['modifiedQty', 'orderedQty'].forEach((key: 'modifiedQty' | 'orderedQty') => {
                this.onUpdateStock({
                  orderedItem: event.data,
                  key,
                  inStock: event.newValue,
                });
              }),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
            hide: this.isTablet() ? !this.isConfirmedColumnHidden() || this.isReceivedOrder() : false,
          },
          {
            headerName: 'confirmed qty',
            field: 'modifiedQty',
            editable: ({ data }: EditableCallbackParams<EmailedOrderItem>) =>
              !this.isReceivedOrder() && this.#canPerformActions() && !this.isOutOfStock(data),
            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                precision: this.currencyPrecision,
                placeholder: `0.000`,
                numeric: true,
              } as InputCellEditorContext,
            },
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value),
            flex: this.getQtyPriceColumnWidth(),
            hide: this.isConfirmedColumnHidden(),
            onCellValueChanged: (event: NewValueParams<EmailedOrderItem, number>) =>
              this.onUpdateStock({
                orderedItem: event.data,
                key: 'modifiedQty',
                inStock: event.newValue,
              }),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
          },
          {
            headerName: 'received qty',
            field: 'receivedQty',
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value),
            flex: this.getQtyPriceColumnWidth(),
            hide: !this.isReceivedOrder(),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
          },
          {
            headerName: `price (${this.orderValue()?.currency})`,
            field: 'price',
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value, this.currencyPrecision),
            flex: this.getQtyPriceColumnWidth(),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
            hide: this.hidePrices,
          },
          {
            headerName: 'sub-total',
            flex: this.getQtyPriceColumnWidth(),
            valueGetter: ({ data }: ValueGetterParams<EmailedOrderItem>) => this.getSubTotal(data),
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value, this.currencyPrecision),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
            hide: this.isTablet() || this.hidePrices,
          },
          {
            headerName: 'tax',
            flex: this.getColumnWidth(),
            field: 'appliedTax',
            hide: this.hidePrices,
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, TaxRate>) => {
              if (value) {
                return `${value?.rate}% - ${value?.taxCode}`;
              }

              return '-';
            },
          },
          {
            headerName: 'total',
            valueGetter: ({ data }: ValueGetterParams<EmailedOrderItem>) => this.getTotal(data),
            valueFormatter: ({ value }: ValueFormatterParams<EmailedOrderItem, string>) =>
              this.#precision.transform(value, this.currencyPrecision),
            flex: this.getQtyPriceColumnWidth(),
            cellStyle: {
              'text-align': 'right',
            },
            headerClass: 'supy-order-items-grid__right-aligned',
            hide: this.hidePrices,
          },
          {
            headerName: '',
            width: 50,
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: ({ data }: ICellRendererParams<EmailedOrderItem>) => {
              return {
                context: this.getActionsContext(data),
              };
            },
            hide: !this.#canPerformActions(),
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private getQtyValue(rowData: EmailedOrderItem): number {
    return this.isReceivedOrder() ? rowData.receivedQty : rowData.modifiedQty;
  }

  private getSubTotal(rowData: EmailedOrderItem): number {
    return rowData.price * this.getQtyValue(rowData);
  }

  private getTotal(rowData: EmailedOrderItem): number {
    return this.getSubTotal(rowData) + this.getAppliedTax(rowData);
  }

  private getAppliedTax(rowData: EmailedOrderItem): number {
    return this.getSubTotal(rowData) * ((rowData?.appliedTax?.rate ?? 0) / 100);
  }

  private isOutOfStock(rowData: EmailedOrderItem): boolean {
    return rowData.modifiedQty === 0;
  }

  protected onLanguageChange(value: Language): void {
    this.selectedLanguage.set(value);
  }

  private onUpdateStock({
    orderedItem,
    key,
    inStock = 0,
    returnInStock = false,
  }: {
    orderedItem: EmailedOrderItem;
    key: 'modifiedQty' | 'orderedQty';
    inStock?: number;
    returnInStock?: boolean;
  }): void {
    if (returnInStock) {
      inStock = orderedItem.orderedQty;
    }

    this.orderItems.update(items =>
      items.map(item => {
        if (item.id === orderedItem.id) {
          return {
            ...item,
            [key]: inStock,
          };
        }

        return item;
      }),
    );
  }

  private getActionsContext(rowData: EmailedOrderItem): ActionsCellRendererContext<EmailedOrderItem> {
    return {
      actions: [
        {
          type: ActionCellRendererActionTypes.More,
          moreActions: [
            {
              icon: 'close-circle',
              title: 'Out of stock',
              iconColor: 'error',
              callback: data =>
                this.onUpdateStock({
                  orderedItem: data,
                  key: 'modifiedQty',
                }),
            },
          ],
          hidden: this.isOutOfStock(rowData),
        },
        {
          icon: 'undo',
          iconColor: 'error',
          callback: () =>
            this.onUpdateStock({
              orderedItem: rowData,
              key: 'modifiedQty',
              returnInStock: true,
            }),
          hidden: !this.isOutOfStock(rowData),
        },
      ],
    };
  }
}
