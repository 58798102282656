import { ChangeDetectionStrategy, Component, input, output, Signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IANATimezone } from '@supy.api/dictionaries';

import { IconType } from '@supy/components';

export interface OrderDetailsWidget {
  readonly header: string;
  readonly headerIcon?: IconType;
  readonly content?: string;
  readonly isDateInput?: boolean;
  readonly control?: FormControl<Date | null>;
  readonly minValue?: Signal<Date>;
}

@Component({
  selector: 'supy-retailer-order-details-combined-widget',
  templateUrl: './retailer-order-details-combined-widget.component.html',
  styleUrls: ['./retailer-order-details-combined-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsCombinedWidgetComponent {
  protected readonly ianaTimeZone = input<IANATimezone>();
  protected readonly defaultDeliveryDate = input<Date>();
  protected readonly maximumDate = input<Date>();
  protected readonly deliveryDateChanged = output<Date>();
  protected readonly isInitialLoading = input<boolean>();
  protected readonly widgets = input.required<OrderDetailsWidget[]>();
}
