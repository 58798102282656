import { IANATimezone } from '@supy.api/dictionaries';

import {
  getApiDetailsDecorator,
  getDateInTimeZone,
  IdType,
  isExistent,
  LocalizedSkeletonObjectType,
  Retailer,
  SimpleUser,
  SkeletonObjectType,
  Supplier,
  SupplierPartnershipTypeEnum,
  SupplierType,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties, Nullable } from '@supy/core';

import { AggregatedLinkedOrder, AggregatedOrders, OrderStatus, SimpleChannel } from '../order';
import {
  GrnConsolidatedInvoice,
  GrnDocumentDiscrepancies,
  GrnMetadata,
  GrnResponse,
  GrnStatus,
  GrnUpdates,
} from './grn.model';
import { CreditNote, GrnCreditNote } from './grn-credit-note.entity';
import { GrnDocument } from './grn-document.entity';
import { GrnDocumentType } from './grn-document.model';
import { GrnItem } from './grn-item.entity';
import { GrnItemResponse, GrnItemType } from './grn-item.model';
import { GrnTotals } from './grn-totals.entity';

export enum GrnUpdateType {
  Drafted = 'drafted',
  Saved = 'saved',
  Pushed = 'pushed',
  Transferred = 'transferred',
  Posted = 'posted',
}

export enum GrnStatisticsCardType {
  UniqueGrns = 'invoices',
  TotalValue = 'total-value',
  PendingDocs = 'pending-docs',
  NegativeInvoices = 'negative-invoices',
  CreditNotes = 'credit-notes',
}

const ApiProperty = getApiDetailsDecorator<GrnResponse>();

type GrnArgs = Pick<
  Grn,
  | 'channel'
  | 'comment'
  | 'createdAt'
  | 'creditIssued'
  | 'creditNotes'
  | 'document'
  | 'consolidatedInvoice'
  | 'id'
  | 'isPreferred'
  | 'isSynced'
  | 'items'
  | 'linkedGrns'
  | 'linkedOrder'
  | 'location'
  | 'metadata'
  | 'outlet'
  | 'retailer'
  | 'status'
  | 'supplier'
  | 'totals'
  | 'updatedAt'
  | 'updates'
  | 'user'
>;

export class Grn {
  private constructor(args: NonFunctionProperties<GrnArgs>) {
    this.channel = args.channel;
    this.comment = args.comment;
    this.createdAt = args.createdAt;
    this.creditIssued = args.creditIssued ?? false;
    this.creditNotes = args.creditNotes;
    this.document = args.document;
    this.document = args.document;
    this.id = args.id;
    this.isPreferred = args.isPreferred ?? false;
    this.isSynced = args.isSynced ?? false;
    this.items = args.items;
    this.linkedGrns = args.linkedGrns;
    this.linkedOrder = args.linkedOrder ?? null;
    this.location = args.location;
    this.metadata = args.metadata ?? null;
    this.outlet = args.outlet;
    this.retailer = args.retailer;
    this.status = args.status;
    this.supplier = args.supplier;
    this.totals = args.totals;
    this.updatedAt = args.updatedAt;
    this.updates = args.updates ?? [];
    this.consolidatedInvoice = args.consolidatedInvoice;
    this.user = args.user;

    // Computed properties
    this.documentDiscrepancies = args.metadata?.autoGrn?.documentDiscrepancies;
    this.skippedItemCodes = args.metadata?.autoGrn?.skippedItemCodes ?? [];
    this.hasLinkedOrder = this.linkedOrder ? true : false;
    this.hasCkSupplier = this.supplier?.type === SupplierType.centralKitchen;
    this.hasInternalCkSupplier = this.hasCkSupplier && this.supplier?.metadata?.retailerId === this.retailer.id;
    this.hasExternalCkSupplier = this.hasCkSupplier && this.supplier?.metadata?.retailerId !== this.retailer.id;
    this.hasIntegratedSupplier = this.supplier?.metadata?.partnershipType === SupplierPartnershipTypeEnum.Integrated;
    this.hasOpenLinkedOrder = this.hasLinkedOrder && this.linkedOrder.status !== OrderStatus.Received;
    this.closedOn = this.updates.find(update => update.type === GrnUpdateType.Posted)?.createdAt ?? null;
    this.isDiscarded = this.status === GrnStatus.Discarded;
    this.isDisputed = this.status === GrnStatus.Disputed;
    this.isDrafted = this.status === GrnStatus.Draft;
    this.isIncoming = this.status === GrnStatus.Incoming;
    this.isPosted = this.status === GrnStatus.Posted;
    this.isSaved = this.status === GrnStatus.Saved;
    this.isConsolidatedInvoiceType = this.document.type === GrnDocumentType.ConsolidatedInvoice;
    this.isDeliveryNote = this.document.type === GrnDocumentType.DeliveryNote;
    this.isInvoice = this.document.type === GrnDocumentType.Invoice;
    this.isLocked = this.metadata?.isLocked ?? false;
    this.isAuto = this.isIncoming;
    this.isDisputeResolvable = this.isDisputed;
    this.isDiscardable = GrnStatusTransition[this.status]?.includes(GrnStatus.Discarded);
    this.isDeconsolidatable = this.isConsolidatedInvoiceType && this.isSaved;
    this.isDraftable =
      (!this.id || GrnStatusTransition[this.status]?.includes(GrnStatus.Draft)) && !this.isConsolidatedInvoiceType;
    this.isSavable = !this.id || GrnStatusTransition[this.status]?.includes(GrnStatus.Saved);
    this.isLockable = this.isSaved && !this.isLocked;
    this.isUnlockable = this.isSaved && this.isLocked;
    this.isLinkedOrderUpdatable = this.isSavable && this.hasOpenLinkedOrder;
    this.isOnlySavable = this.isSavable && !this.isLinkedOrderUpdatable;
    this.isPartialSavable = this.isLinkedOrderUpdatable;
    this.isClosable = this.isLinkedOrderUpdatable;
    this.isPostable = GrnStatusTransition[this.status]?.includes(GrnStatus.Posted);
    this.isCreditNoteAllocatable = !this.id || ((this.isSaved || this.isDrafted) && !this.isDeliveryNote);
    this.hasBeenConsolidated = isExistent(this.consolidatedInvoice);
    this.isStockUpdatable = !this.isPosted && !this.isConsolidatedInvoiceType && !this.hasBeenConsolidated;
    this.isDownloadable = this.isSaved || this.isDisputed || this.isPosted;
    this.isHighlightable =
      (this.isInvoice || this.isConsolidatedInvoiceType) &&
      (this.isIncoming || this.isDisputed || this.isDrafted || this.isSaved);
    this.isDisputable =
      this.isInvoice && !this.isDisputed && GrnStatusTransition[this.status]?.includes(GrnStatus.Disputed);
  }

  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly comment: Nullable<string>;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly creditIssued?: boolean;
  @ApiProperty() readonly creditNotes: GrnCreditNote[];
  @ApiProperty() readonly document: GrnDocument;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly isSynced?: boolean;
  @ApiProperty() readonly items: GrnItem[];
  @ApiProperty() readonly linkedGrns: IdType[];
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly metadata: Nullable<GrnMetadata>;
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;
  @ApiProperty() readonly retailer: Retailer;
  @ApiProperty() readonly status: GrnStatus;
  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly totals?: GrnTotals;
  @ApiProperty() readonly updatedAt: Date;
  @ApiProperty() readonly updates: GrnUpdates[];
  @ApiProperty() readonly user: SimpleUser;
  @ApiProperty() readonly consolidatedInvoice?: GrnConsolidatedInvoice;
  @ApiProperty({ key: 'linkedOrders' }) readonly linkedOrder: Nullable<AggregatedLinkedOrder>;
  @ApiProperty({ key: 'preferred' }) readonly isPreferred: boolean;

  // Computed properties
  readonly closedOn: Date | null;
  readonly documentDiscrepancies: GrnDocumentDiscrepancies;
  readonly hasCkSupplier: boolean;
  readonly hasIntegratedSupplier: boolean;
  readonly hasInternalCkSupplier: boolean;
  readonly hasExternalCkSupplier: boolean;
  readonly hasLinkedOrder: boolean;
  readonly hasOpenLinkedOrder: boolean;
  readonly isAuto: boolean;
  readonly isClosable: boolean;
  readonly isConsolidatedInvoiceType: boolean;
  readonly isCreditNoteAllocatable: boolean;
  readonly isDeliveryNote: boolean;
  readonly isDiscardable: boolean;
  readonly isDeconsolidatable: boolean;
  readonly isDiscarded: boolean;
  readonly isDisputable: boolean;
  readonly isDisputeResolvable: boolean;
  readonly isDisputed: boolean;
  readonly isDownloadable: boolean;
  readonly isDraftable: boolean;
  readonly isDrafted: boolean;
  readonly isHighlightable: boolean;
  readonly isIncoming: boolean;
  readonly isInvoice: boolean;
  readonly isLinkedOrderUpdatable: boolean;
  readonly isLockable: boolean;
  readonly isLocked: boolean;
  readonly isOnlySavable: boolean;
  readonly isPartialSavable: boolean;
  readonly isPostable: boolean;
  readonly isPosted: boolean;
  readonly isSavable: boolean;
  readonly isSaved: boolean;
  readonly isStockUpdatable: boolean;
  readonly isUnlockable: boolean;
  readonly skippedItemCodes: string[];
  readonly hasBeenConsolidated: boolean;

  static deserialize(data: GrnResponse): Grn {
    return new Grn({
      channel: data.channel,
      comment: data.comment,
      createdAt: data.createdAt ?? new Date(),
      creditIssued: data.creditIssued,
      creditNotes: data.creditNotes?.map(creditNote => GrnCreditNote.deserialize(creditNote)) ?? [],
      document: GrnDocument.deserialize(data.document),
      consolidatedInvoice: data.consolidatedInvoice ?? null,
      id: data.id,
      isPreferred: data.preferred,
      isSynced: data.isSynced,
      items: data.items.map(GrnItem.deserialize),
      linkedGrns: data.linkedGrns ?? [],
      linkedOrder: data.linkedOrders?.at(0),
      location: data.location,
      metadata: data.metadata,
      outlet: data.outlet,
      retailer: data.retailer,
      status: data.status,
      supplier: data.supplier,
      totals: GrnTotals.deserialize(data.totals),
      updatedAt: data.updatedAt,
      updates: data.updates ?? [],
      user: data.user,
    });
  }

  static deserializeExisting({ document, ianaTimeZone, items, channel, ...props }: GrnFromExistingArgs): Grn {
    return new Grn({
      ...props,
      ...(document && {
        document: {
          ...document,
          documentDate: getDateInTimeZone(document.documentDate, ianaTimeZone),
          paymentDueDate: getDateInTimeZone(document.paymentDueDate, ianaTimeZone),
        },
      }),
      channel,
      items: items.map(item =>
        item.type === GrnItemType.Item ? { ...item, prices: { ...item.prices, updatePrice: false } } : item,
      ),
    });
  }

  static deserializeFromReceivingOrders({
    channel,
    documentDate,
    ianaTimeZone,
    items,
    linkedOrders,
    location,
    outlet,
    retailer,
    supplier,
  }: GrnFromReceivingOrdersArgs): Grn {
    return new Grn({
      channel,
      comment: null,
      createdAt: new Date(),
      creditIssued: null,
      creditNotes: null,
      document: {
        attachments: [],
        number: null,
        type: GrnDocumentType.Invoice,
        documentDate: getDateInTimeZone(documentDate, ianaTimeZone),
      },
      id: null,
      isPreferred: false,
      isSynced: false,
      items,
      linkedGrns: [],
      linkedOrder: linkedOrders.at(0),
      location,
      metadata: null,
      outlet,
      retailer,
      status: GrnStatus.New,
      supplier,
      totals: null,
      updatedAt: null,
      updates: null,
      user: null,
    });
  }

  static deserializeFromReceivingWithoutOrder({
    supplier,
    location,
    outlet,
    channel,
    retailer,
  }: GrnFromReceivingWithoutOrderArgs): Grn {
    return new Grn({
      channel,
      comment: null,
      createdAt: new Date(),
      creditIssued: null,
      creditNotes: [],
      document: {
        attachments: [],
        number: null,
        type: GrnDocumentType.Invoice,
      },
      id: null,
      isPreferred: false,
      isSynced: false,
      items: [],
      linkedGrns: [],
      linkedOrder: null,
      location,
      metadata: null,
      outlet,
      retailer,
      status: GrnStatus.New,
      supplier,
      totals: null,
      updatedAt: null,
      updates: null,
      user: null,
    });
  }

  static deserializeFromConsolidating({
    supplier,
    location,
    outlet,
    channel,
    items,
    retailer,
  }: GrnFromConsolidatingArgs): Grn {
    return new Grn({
      channel,
      comment: null,
      createdAt: new Date(),
      creditIssued: null,
      creditNotes: [],
      document: {
        attachments: [],
        number: null,
        type: GrnDocumentType.ConsolidatedInvoice,
      },
      id: null,
      isPreferred: false,
      isSynced: false,
      items: items.map(GrnItem.deserialize),
      linkedGrns: [],
      linkedOrder: null,
      location,
      metadata: null,
      outlet,
      retailer,
      status: GrnStatus.New,
      supplier,
      totals: null,
      updatedAt: null,
      updates: null,
      user: null,
    });
  }

  static deserializeFromReceivingCreditNotes({ creditNotes, channel, retailer }: GrnFromReceivingCreditNotesArgs): Grn {
    const { supplier, location, outlet } = creditNotes.at(0);

    return new Grn({
      channel,
      comment: null,
      createdAt: new Date(),
      creditIssued: false,
      creditNotes,
      document: {
        attachments: [],
        number: null,
        type: GrnDocumentType.Invoice,
      },
      id: null,
      isPreferred: false,
      isSynced: false,
      items: creditNotes.map(GrnItem.fromCreditNote),
      linkedGrns: [],
      linkedOrder: null,
      location,
      metadata: null,
      outlet,
      retailer,
      status: GrnStatus.New,
      supplier,
      totals: null,
      updatedAt: null,
      updates: null,
      user: null,
    });
  }
}

export const GrnDocumentTypeNameMapper: Record<GrnDocumentType, string> = {
  [GrnDocumentType.Invoice]: $localize`:@@invoice:Invoice`,
  [GrnDocumentType.DeliveryNote]: $localize`:@@deliveryNote:Delivery Note`,
  [GrnDocumentType.ConsolidatedInvoice]: $localize`:@@grns.list.typeMapping.consolidatedInvoice:Consolidated Invoice`,
};

export const GrnStatusTransition: Record<GrnStatus, GrnStatus[]> = {
  [GrnStatus.Discarded]: [],
  [GrnStatus.Disputed]: [GrnStatus.Disputed, GrnStatus.Saved],
  [GrnStatus.Draft]: [GrnStatus.Draft, GrnStatus.Disputed, GrnStatus.Saved, GrnStatus.Discarded],
  [GrnStatus.Incoming]: [GrnStatus.Disputed, GrnStatus.Draft, GrnStatus.Saved, GrnStatus.Discarded],
  [GrnStatus.New]: [],
  [GrnStatus.Posted]: [],
  [GrnStatus.Saved]: [GrnStatus.Saved, GrnStatus.Posted],
};

export const GrnStatusNameMapper: Record<GrnStatus, string> = {
  [GrnStatus.Discarded]: $localize`:@@statusDiscarded:Discarded`,
  [GrnStatus.Disputed]: $localize`:@@statusDisputed:Disputed`,
  [GrnStatus.Draft]: $localize`:@@statusDrafted:Drafted`,
  [GrnStatus.Incoming]: $localize`:@@incoming:Incoming`,
  [GrnStatus.New]: $localize`:@@new:New`,
  [GrnStatus.Posted]: $localize`:@@posted:Posted`,
  [GrnStatus.Saved]: $localize`:@@saved:Saved`,
};

export const GrnStatusBadgeMapper: Record<GrnStatus, BadgeStatus> = {
  [GrnStatus.Discarded]: 'light-error',
  [GrnStatus.Disputed]: 'warn',
  [GrnStatus.Draft]: 'grey',
  [GrnStatus.Incoming]: 'primary',
  [GrnStatus.New]: 'primary',
  [GrnStatus.Posted]: 'success',
  [GrnStatus.Saved]: 'info',
};

type GrnFromExistingArgs = GrnArgs & {
  readonly ianaTimeZone: IANATimezone;
  readonly channel: SimpleChannel;
};
interface GrnFromReceivingCreditNotesArgs {
  readonly creditNotes: CreditNote[];
  readonly channel: SimpleChannel;
  readonly retailer: Retailer;
}

interface GrnFromReceivingOrdersArgs
  extends Pick<AggregatedOrders, 'supplier' | 'location' | 'linkedOrders' | 'items' | 'outlet' | 'documentDate'> {
  readonly channel: SimpleChannel;
  readonly ianaTimeZone: IANATimezone;
  readonly retailer: Retailer;
}

interface GrnFromReceivingWithoutOrderArgs extends Pick<Grn, 'location' | 'supplier' | 'outlet' | 'channel'> {
  readonly retailer: Retailer;
}

interface GrnFromConsolidatingArgs extends Pick<Grn, 'location' | 'supplier' | 'outlet' | 'channel'> {
  readonly items: GrnItemResponse[];
  readonly retailer: Retailer;
}
