<div class="retailer-order-details-combined-widget">
  @if (isInitialLoading()) {
    <div class="retailer-order-details-combined-widget--initial-loading"></div>
  }
  @for (widget of widgets(); track $index) {
    <div class="retailer-order-details-combined-widget__unit">
      <div class="retailer-order-details-combined-widget__header">
        @if (widget?.headerIcon; as headerIcon) {
          <supy-icon [name]="headerIcon" size="small" />
        }
        <h5 class="retailer-order-details-combined-widget__header-title">{{ widget.header }}</h5>
      </div>
      @if (widget.isDateInput) {
        <supy-date-input
          [value]="defaultDeliveryDate()"
          [ianaTimeZone]="ianaTimeZone()"
          [minValue]="widget.minValue()"
          [maxValue]="maximumDate()"
          (valueChange)="deliveryDateChanged.emit($event)"
        />
      } @else {
        <h6
          class="retailer-order-details-combined-widget__content"
          [ngClass]="{ 'retailer-order-details-combined-widget__content-with-margin': widget?.headerIcon }"
        >
          {{ widget.content }}
        </h6>
      }
    </div>
  }
</div>
