import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query } from '@supy/common';

import { REQUISITIONS_URI } from '../../config';
import {
  AggregatedRequisitionQueryProps,
  ApproveRequisitionsRequest,
  CreateRequisitionRequest,
  DiscardRequisitionsRequest,
  ProcessRequisitionsRequest,
  RequisitionByLocationResponse,
  RequisitionByPackagingResponse,
  RequisitionReplaceGroupedPackagingSupplierRequest,
  RequisitionResponse,
  RequisitionsByLocationGetDetailedRequest,
  RequisitionsByPackagingGetDetailedRequest,
  RequisitionsByPackagingQueryProps,
  RequisitionsQueryProps,
  UpdateRequisitionRequest,
} from '../../core';

@Injectable()
export class RequisitionsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(REQUISITIONS_URI);

  create(payload: CreateRequisitionRequest): Observable<IdType> {
    return this.#httpClient.post<IdType>(`${this.#uri}`, payload);
  }

  update(payload: UpdateRequisitionRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${payload.id}`, payload);
  }

  getMany(query: Query<RequisitionsQueryProps>): Observable<IQueryResponse<RequisitionResponse>> {
    return this.#httpClient.get<IQueryResponse<RequisitionResponse>>(this.#uri, { params: query.toQueryParams() });
  }

  getByLocationMany(
    query: Query<AggregatedRequisitionQueryProps>,
  ): Observable<IQueryResponse<RequisitionByLocationResponse>> {
    return this.#httpClient.get<IQueryResponse<RequisitionByLocationResponse>>(`${this.#uri}/aggregated`, {
      params: query.toQueryParams(),
    });
  }

  getByPackagingMany(
    query: Query<RequisitionsByPackagingQueryProps>,
  ): Observable<IQueryResponse<RequisitionByPackagingResponse>> {
    return this.#httpClient.get<IQueryResponse<RequisitionByPackagingResponse>>(`${this.#uri}/aggregated/packaging`, {
      params: query.toQueryParams(),
    });
  }

  getDetailed(id: string): Observable<RequisitionResponse> {
    return this.#httpClient.get<RequisitionResponse>(`${this.#uri}/${id}/detailed`);
  }

  getDetailedByLocation(payload: RequisitionsByLocationGetDetailedRequest): Observable<RequisitionByLocationResponse> {
    return this.#httpClient.post<RequisitionByLocationResponse>(`${this.#uri}/aggregated/detailed/get`, payload);
  }

  getDetailedByPackaging(
    payload: RequisitionsByPackagingGetDetailedRequest,
  ): Observable<RequisitionByPackagingResponse> {
    return this.#httpClient.post<RequisitionByPackagingResponse>(`${this.#uri}/aggregated/packaging/detailed`, payload);
  }

  replacePackagingSupplier(payload: RequisitionReplaceGroupedPackagingSupplierRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/aggregated/packaging/replace`, payload);
  }

  discard(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/discard`, {});
  }

  discardByLocation(payload: DiscardRequisitionsRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/discard`, payload);
  }

  process(payload: ProcessRequisitionsRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/process`, payload);
  }

  approve(payload: ApproveRequisitionsRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/approve`, payload);
  }
}
