import { Currency } from '@supy.api/dictionaries';

import {
  Branch,
  getApiDetailsDecorator,
  isOneOf,
  OutletData,
  Retailer,
  SimpleUser,
  Supplier,
  SupplierType,
} from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { Country } from '@supy/countries';
import { getLocalizedName, TaxRate } from '@supy/settings';

import { SimpleGrn } from '../grn';
import { deserializeOrderActivity, OrderActivity, SimpleChannel, StatefulOrderEntity } from './common-order.model';
import { DetailedOrderItem } from './detailed-order-item.entity';
import { CreationSourceEnum, DetailedOrderResponse, OrderInvoice, OrderStatus, OrderUpdateStatus } from './order.model';

const ApiProperty = getApiDetailsDecorator<DetailedOrderResponse>();

type DetailedOrderArgs = Omit<
  DetailedOrder,
  | 'comments'
  | 'currency'
  | 'discardedAt'
  | 'discardedBy'
  | 'displayedLocationName'
  | 'displayedSupplierName'
  | 'draftedBy'
  | 'hasItemsCreatedByCk'
  | 'hasAllItemsCreatedByCk'
  | 'hasLinkedGrns'
  | 'isClosable'
  | 'isConfirmed'
  | 'isCreatedByCk'
  | 'isDelivered'
  | 'isDiscardable'
  | 'isDrafted'
  | 'isEitherReceivedOrPartiallyReceived'
  | 'isPartiallyReceived'
  | 'isReceivable'
  | 'isReopenable'
  | 'isRepeatable'
  | 'isShipped'
  | 'isSubmittable'
  | 'isSummarizable'
  | 'isUpdatable'
  | 'modifiedItemsTotal'
  | 'orderedItemsTotal'
  | 'submittedBy'
>;

export class DetailedOrder {
  private constructor(args: NonFunctionProperties<DetailedOrderArgs>) {
    this.activities = args.activities;
    this.appliedTax = args.appliedTax;
    this.shipDate = args.shipDate;
    this.branch = args.branch;
    this.channel = args.channel;
    this.ckItems = args.ckItems;
    this.comment = args.comment;
    this.country = args.country;
    this.createdAt = args.createdAt;
    this.submittedAt = args.submittedAt;
    this.creationSource = args.creationSource;
    this.deliveryDate = args.deliveryDate;
    this.discount = args.discount;
    this.fees = args.fees;
    this.hasCkSupplier = args.hasCkSupplier;
    this.id = args.id;
    this.invoice = args.invoice;
    this.isClosed = args.isClosed;
    this.items = args.items;
    this.linkedGrns = args.linkedGrns;
    this.number = args.number;
    this.outlet = args.outlet;
    this.retailer = args.retailer;
    this.status = args.status;
    this.supplier = args.supplier;
    this.taxRateAmount = args.taxRateAmount;
    this.total = args.total;
    this.totalAfterTaxRate = args.totalAfterTaxRate;
    this.totalBeforeTaxRate = args.totalBeforeTaxRate;
    this.updatedAt = args.updatedAt;
    this.user = args.user;
    this.vat = args.vat;
    this.unlinkedCkItems = args.unlinkedCkItems;

    // Computed properties
    this.comments = this.activities.reduce<string[]>((acc, { comment }) => {
      if (comment) {
        acc.push(comment);
      }

      return acc;
    }, []);
    this.currency = this.country.currency;
    this.hasItemsCreatedByCk = this.supplier.isExposed && this.items.some(({ isCreatedByCk }) => isCreatedByCk);
    this.hasAllItemsCreatedByCk = this.supplier.isExposed && this.items.every(({ isCreatedByCk }) => isCreatedByCk);
    this.hasLinkedGrns = this.linkedGrns.length > 0;
    this.discardedBy = this.activities.find(({ action }) => action === OrderUpdateStatus.Discarded)?.user ?? null;
    this.draftedBy = this.activities.find(({ action }) => action === OrderUpdateStatus.Draft)?.user ?? null;
    this.submittedBy = this.activities.find(({ action }) => action == OrderUpdateStatus.Submitted)?.user ?? this.user;
    this.discardedAt = this.activities.find(({ action }) => action == OrderUpdateStatus.Discarded)?.createdAt ?? null;
    this.isSummarizable = !this.hasCkSupplier || this.supplier?.isExposed;
    this.isDrafted = this.status === OrderStatus.Draft;
    this.isDelivered = this.status === OrderStatus.Received;
    this.isPartiallyReceived = this.status === OrderStatus.PartialReceived;
    this.isConfirmed = this.status === OrderStatus.Confirmed;
    this.isRepeatable = isOneOf(this.status, [OrderStatus.Received, OrderStatus.Rejected]);
    this.isShipped = this.status === OrderStatus.Shipped;
    this.isEitherReceivedOrPartiallyReceived = isOneOf(this.status, [
      OrderStatus.Received,
      OrderStatus.PartialReceived,
    ]);
    this.isDiscardable = this.status === OrderStatus.Draft;
    this.isUpdatable = this.status === OrderStatus.Draft;
    this.isSubmittable = this.status === OrderStatus.Draft;
    this.isReopenable = this.status === OrderStatus.Received;
    this.displayedLocationName = `${getLocalizedName(this.outlet?.name)} - ${this.branch?.name}`;
    this.displayedSupplierName = this.channel?.displayName ?? this.supplier.name;
    this.isClosable =
      !this.isClosed &&
      (this.hasCkSupplier
        ? isOneOf(this.status, [OrderStatus.PartialReceived, OrderStatus.Shipped])
        : isOneOf(this.status, [OrderStatus.Confirmed, OrderStatus.PartialReceived, OrderStatus.Submitted]));
    this.isReceivable = this.hasCkSupplier
      ? this.isShipped
      : isOneOf(this.status, [OrderStatus.Confirmed, OrderStatus.PartialReceived, OrderStatus.Submitted]);
    this.orderedItemsTotal = this.total.ordered;
    this.modifiedItemsTotal = this.total.modified;
    this.isCreatedByCk = this.creationSource === CreationSourceEnum.CentralKitchen;
  }

  @ApiProperty() readonly activities: OrderActivity[];
  @ApiProperty() readonly appliedTax: Nullable<TaxRate>;
  @ApiProperty() readonly shipDate: Nullable<Date>;
  @ApiProperty() readonly submittedAt: Nullable<Date>;
  @ApiProperty() readonly branch: Branch;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly ckItems: DetailedOrderItem[];
  @ApiProperty() readonly comment: Nullable<string>;
  @ApiProperty() readonly country: Country;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly creationSource: Nullable<CreationSourceEnum>;
  @ApiProperty() readonly deliveryDate: Date;
  @ApiProperty() readonly discount: number;
  @ApiProperty() readonly fees: number;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly invoice: OrderInvoice;
  @ApiProperty() readonly items: DetailedOrderItem[];
  @ApiProperty() readonly linkedGrns: SimpleGrn[];
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly outlet: Nullable<OutletData>;
  @ApiProperty() readonly retailer: Retailer;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly total: StatefulOrderEntity;
  @ApiProperty() readonly updatedAt: number;
  @ApiProperty() readonly user: SimpleUser;
  @ApiProperty() readonly vat: number;
  readonly hasCkSupplier: boolean;
  readonly isClosed: boolean;
  readonly taxRateAmount: number;
  readonly totalAfterTaxRate: number;
  readonly totalBeforeTaxRate: number;

  // Computed properties
  readonly comments: string[];
  readonly currency: Currency;
  readonly discardedAt: Nullable<Date>;
  readonly discardedBy: Nullable<SimpleUser>;
  readonly displayedLocationName: string;
  readonly displayedSupplierName: string;
  readonly draftedBy: Nullable<SimpleUser>;
  readonly hasItemsCreatedByCk: boolean;
  readonly hasAllItemsCreatedByCk: boolean;
  readonly hasLinkedGrns: boolean;
  readonly isClosable: boolean;
  readonly isConfirmed: boolean;
  readonly isCreatedByCk: boolean;
  readonly isDelivered: boolean;
  readonly isDiscardable: boolean;
  readonly isDrafted: boolean;
  readonly isEitherReceivedOrPartiallyReceived: boolean;
  readonly isPartiallyReceived: boolean;
  readonly isReceivable: boolean;
  readonly isReopenable: boolean;
  readonly isRepeatable: boolean;
  readonly isShipped: boolean;
  readonly isSubmittable: boolean;
  readonly isSummarizable: boolean;
  readonly isUpdatable: boolean;
  readonly modifiedItemsTotal: number;
  readonly orderedItemsTotal: number;
  readonly submittedBy: Nullable<SimpleUser>;
  readonly unlinkedCkItems: DetailedOrderItem[];

  static deserialize(
    data: DetailedOrderResponse,
    { suppliers, taxRates, defaultOnReceivingTax }: DeserializeArgs,
  ): DetailedOrder {
    const supplierDefaultTaxId = suppliers.find(({ id }) => id === data.supplier?.id)?.defaultTaxId;
    const supplierTaxRate = supplierDefaultTaxId
      ? taxRates.find(({ id }) => id === supplierDefaultTaxId)
      : defaultOnReceivingTax;
    const items = DetailedOrderItem.deserializeList(data.items, { supplierTaxRate, taxRates });
    const ckItemIds = new Set<DetailedOrderItem['ckItemId']>(items.map(({ ckItemId }) => ckItemId));
    const ckItems = data.ckItems ? DetailedOrderItem.deserializeList(data.ckItems, { supplierTaxRate, taxRates }) : [];
    const unlinkedCkItems = ckItems
      .filter(({ id }) => !ckItemIds.has(id))
      .map(item => ({ ...item, isCreatedByCk: true }));
    const hasCkSupplier = data.supplier.type === SupplierType.centralKitchen;

    return new DetailedOrder({
      activities: data.activities?.map(deserializeOrderActivity)?.reverse() ?? [],
      appliedTax: data.appliedTax ?? null,
      shipDate: data.shipDate ?? null,
      submittedAt: data.submittedAt ?? null,
      branch: data.branch,
      channel: data.channel,
      ckItems,
      comment: data.comment ?? null,
      country: data.country,
      createdAt: data.createdAt,
      creationSource: data.creationSource ?? null,
      deliveryDate: data.deliveryDate,
      discount: data.discount ?? 0,
      fees: data.fees ?? 0,
      hasCkSupplier,
      id: data.id,
      invoice: data.invoice,
      isClosed: data.metadata?.closed ?? false,
      items: hasCkSupplier && items.length !== ckItems.length ? items.concat(unlinkedCkItems) : items,
      linkedGrns: data.linkedGrns ? SimpleGrn.deserializeList(data.linkedGrns) : [],
      number: data.number,
      outlet: data.outlet ?? null,
      retailer: data.retailer,
      status: data.status,
      supplier: data.supplier,
      taxRateAmount: items.reduce((acc, { taxRateAmount }) => acc + taxRateAmount, 0),
      total: data.total,
      totalAfterTaxRate: items.reduce((acc, { totalAfterTaxRate }) => acc + totalAfterTaxRate, 0),
      totalBeforeTaxRate: items.reduce((acc, { totalBeforeTaxRate }) => acc + totalBeforeTaxRate, 0),
      updatedAt: data.updatedAt,
      user: data.user,
      vat: data.vat ?? 0,
      unlinkedCkItems,
    });
  }
}

interface DeserializeArgs {
  readonly suppliers: Supplier[];
  readonly taxRates: TaxRate[];
  readonly defaultOnReceivingTax: TaxRate;
}
