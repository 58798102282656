import produce from 'immer';
import { map, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
} from '@supy/common';
import { SettingsState } from '@supy/settings';

import {
  AggregatedRequisitionQueryProps,
  RequisitionByLocation,
  RequisitionFilters,
  RequisitionStatus,
} from '../../core';
import { RequisitionsService } from '../../services';
import {
  AggregatedRequisitionApprove,
  AggregatedRequisitionDiscard,
  AggregatedRequisitionGetMany,
  AggregatedRequisitionInitFilters,
  AggregatedRequisitionPatchFilters,
  AggregatedRequisitionPatchRequestMetadata,
  AggregatedRequisitionProcess,
  AggregatedRequisitionResetFilters,
  AggregatedRequisitionResetSequence,
  AggregatedRequisitionSetFilters,
  RequisitionByLocationGetDetailed,
} from '../actions';

const AGGREGATED_REQUISITIONS_STATE_TOKEN = new StateToken<RequisitionsByLocationStateModel>('requisitionsByLocation');

export interface RequisitionsByLocationStateModel extends EntityListStateModel<RequisitionByLocation> {
  readonly filters: RequisitionFilters;
  readonly detailedRequisition: RequisitionByLocation;
  readonly requestMetadata: RequisitionsByLocationRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

export interface RequisitionsByLocationRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
}

const DEFAULT_FILTERS: RequisitionFilters = {
  locations: [],
  status: RequisitionStatus.Pending,
  start: null,
  end: null,
};

const DEFAULT_REQUEST_META: RequisitionsByLocationRequestMetadata = {
  limit: 40,
  page: 0,
  retailerId: null,
};

const RESPONSE_META_DEFAULT: BaseResponseMetadata = { total: 0, count: 0 };

@State<RequisitionsByLocationStateModel>({
  name: AGGREGATED_REQUISITIONS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    detailedRequisition: null,
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_META,
    responseMetadata: RESPONSE_META_DEFAULT,
  },
})
@Injectable()
export class RequisitionsByLocationState extends EntityListState {
  readonly #requisitionsService = inject(RequisitionsService);
  readonly #store = inject(Store);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  @Selector()
  static requisitions(state: RequisitionsByLocationStateModel) {
    return EntityListState.all(state);
  }

  static requisition(
    { status, location, deliveryDate }: Pick<RequisitionByLocation, 'location' | 'deliveryDate' | 'status'>,
    next?: boolean,
  ) {
    return createSelector([RequisitionsByLocationState], (state: RequisitionsByLocationStateModel) => {
      let index = RequisitionsByLocationState.findRequisitoinIndex({ status, location, deliveryDate }, state.entities);

      return state.entities?.[next === undefined ? index : next ? ++index : --index];
    });
  }

  @Selector()
  static detailedRequisition(state: RequisitionsByLocationStateModel) {
    return state.detailedRequisition;
  }

  @Selector()
  static filters(state: RequisitionsByLocationStateModel) {
    return EntityListState.currentFilters<RequisitionFilters>(state);
  }

  @Selector()
  static isFirst(state: RequisitionsByLocationStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: RequisitionsByLocationStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static requestMetadata(state: RequisitionsByLocationStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: RequisitionsByLocationStateModel) {
    return state.responseMetadata;
  }

  @Action(AggregatedRequisitionGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<RequisitionsByLocationStateModel>) {
    return this.#requisitionsService.getByLocationMany(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({
        data: data.map(requisition => RequisitionByLocation.deserialize(requisition)),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(RequisitionByLocationGetDetailed)
  getDetailed(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: RequisitionByLocationGetDetailed) {
    return this.#requisitionsService.getDetailedByLocation(payload).pipe(
      map(requisition => RequisitionByLocation.deserialize(requisition)),
      tap(requisition => {
        ctx.patchState({ detailedRequisition: requisition });

        const entities = ctx.getState().entities ?? [];

        super.setSequence(ctx, {
          id: requisition.id,
          isFirst:
            entities?.length > 1 ? RequisitionsByLocationState.findRequisitoinIndex(requisition, entities) === 0 : true,
          isLast:
            entities?.length > 1
              ? RequisitionsByLocationState.findRequisitoinIndex(requisition, entities) === entities?.length - 1
              : true,
        });
      }),
    );
  }

  @Action(AggregatedRequisitionInitFilters)
  initFilters(ctx: StateContext<RequisitionsByLocationStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(AggregatedRequisitionSetFilters)
  setFilters(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: AggregatedRequisitionSetFilters) {
    super.setFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new AggregatedRequisitionPatchRequestMetadata({ page: 0 }), new AggregatedRequisitionGetMany()]);
  }

  @Action(AggregatedRequisitionPatchFilters)
  patchFilters(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: AggregatedRequisitionPatchFilters) {
    super.patchFilter(ctx, payload, DEFAULT_FILTERS);

    ctx.dispatch([new AggregatedRequisitionPatchRequestMetadata({ page: 0 }), new AggregatedRequisitionGetMany()]);
  }

  @Action(AggregatedRequisitionResetFilters)
  resetFilters(
    ctx: StateContext<RequisitionsByLocationStateModel>,
    { payload, skipFetching }: AggregatedRequisitionResetFilters,
  ) {
    super.resetFilter(ctx, {
      ...DEFAULT_FILTERS,
      ...payload,
    });

    if (skipFetching) {
      return;
    }

    ctx.dispatch([new AggregatedRequisitionPatchRequestMetadata({ page: 0 }), new AggregatedRequisitionGetMany()]);
  }

  @Action(AggregatedRequisitionPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<RequisitionsByLocationStateModel>,
    { payload }: AggregatedRequisitionPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(AggregatedRequisitionApprove)
  approve(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: AggregatedRequisitionApprove) {
    return this.#requisitionsService.approve(payload).pipe(
      tap(() => {
        ctx.dispatch([new AggregatedRequisitionGetMany()]);
      }),
    );
  }

  @Action(AggregatedRequisitionProcess)
  process(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: AggregatedRequisitionProcess) {
    return this.#requisitionsService.process(payload).pipe(
      tap(() => {
        ctx.dispatch([new AggregatedRequisitionGetMany()]);
      }),
    );
  }

  @Action(AggregatedRequisitionDiscard)
  discard(ctx: StateContext<RequisitionsByLocationStateModel>, { payload }: AggregatedRequisitionDiscard) {
    return this.#requisitionsService.discardByLocation(payload).pipe(
      tap(() => {
        ctx.dispatch([new AggregatedRequisitionGetMany()]);
      }),
    );
  }

  @Action(AggregatedRequisitionResetSequence)
  resetSequenceHandler(ctx: StateContext<RequisitionsByLocationStateModel>) {
    return super.resetSequence(ctx);
  }

  static findRequisitoinIndex(
    { deliveryDate, status, location: { id } }: Pick<RequisitionByLocation, 'status' | 'location' | 'deliveryDate'>,
    entities: RequisitionByLocation[],
  ): number {
    return (
      entities?.findIndex(
        ({ location: { id: locationId }, deliveryDate: date, status: reqStatus }) =>
          locationId === id && new Date(date).getTime() === new Date(deliveryDate).getTime() && reqStatus === status,
      ) ?? 0
    );
  }

  private getQuery(
    state: RequisitionsByLocationStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<AggregatedRequisitionQueryProps> {
    const { start, end, status, locations } = state.filters;

    const qb = new QueryBuilder<AggregatedRequisitionQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'deliveryDate',
          dir: 'desc',
        },
      ],
    });

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        op: 'in',
        match: locations,
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        op: 'eq',
        match: status,
      });
    }

    if (start && end) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'deliveryDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'deliveryDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    if (state.requestMetadata?.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        op: 'eq',
        match: state.requestMetadata.retailerId,
      });
    }

    return qb.build();
  }
}
