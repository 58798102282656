import { IdType, LocalizedData } from '@supy/common';
import { BaseActivity } from '@supy/components';

import { OrderActivityResponse, OrderUpdateStatus } from './order.model';

export interface CalculatedField {
  readonly value: number;
  readonly percentage: number;
}

export enum ReceiveOrderType {
  Save = 'save',
  Close = 'close',
}

export interface StatefulOrderEntity {
  readonly ordered: number;
  readonly received?: number;
  readonly modified?: number;
  readonly returned?: number;
  readonly shipped?: number;
  readonly confirmed?: number;
}

export interface SimpleChannel extends IdType {
  readonly displayName?: string;
}

export interface OrderItemQuantity extends StatefulOrderEntity {
  readonly document?: number;
  readonly stock?: number;
  readonly par?: number;
}

export interface SimpleEntity {
  readonly id: string;
  readonly name: string | LocalizedData;
}

export interface ProductRetailerItem {
  readonly id: string;
  readonly code?: string;
  readonly taxCode?: string;
}

export enum PercentageValueType {
  Percentage = 'percentage',
  Amount = 'amount',
}

export interface PercentageValue {
  readonly value: number;
  readonly type: PercentageValueType;
}

export interface OrderActivity extends Omit<BaseActivity, 'action'> {
  readonly action: OrderUpdateStatus;
  readonly comment?: string;
}

export function deserializeOrderActivity(data: OrderActivityResponse): OrderActivity {
  return {
    action: data.type,
    comment: data.comment ?? null,
    createdAt: data.date,
    user: data.user,
  };
}
