import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';

import {
  RequisitionCategory,
  RequisitionChannelItem,
  RequisitionItemByLocationQuantityLevels,
  RequisitionItemByLocationRequest,
  RequisitionItemByLocationResponse,
  RequisitionRetailerItem,
  RequisitionTotals,
  SimpleRequisition,
} from './requisitions.model';

const ApiProperty = getApiDetailsDecorator<RequisitionItemByLocationResponse>();

export class RequisitionItemByLocation {
  private constructor(args: NonFunctionProperties<RequisitionItemByLocation>) {
    this.id = args.id;
    this.packaging = args.packaging;
    this.retailerItem = args.retailerItem;
    this.category = args.category;
    this.selectedChannelItem = args.selectedChannelItem;
    this.channelItems = args.channelItems ?? [];
    this.requisition = args.requisition;
    this.quantity = args.quantity;
    this.totals = args.totals;
    this.parLevel = args.parLevel;
    this.stockLevel = args.stockLevel;
    this.supplierReplaced = args.supplierReplaced;
    this.quantityLevels = args.quantityLevels;
    this.convertedParLevel = Math.ceil(
      (args.quantityLevels.par - args.quantityLevels.stock) / args.packaging.quantity.baseUnit,
    );
  }

  @ApiProperty() readonly packaging: Packaging;
  @ApiProperty() readonly retailerItem: RequisitionRetailerItem;
  @ApiProperty() readonly category: RequisitionCategory;
  @ApiProperty() readonly selectedChannelItem: RequisitionChannelItem;
  @ApiProperty() readonly channelItems: RequisitionChannelItem[];
  @ApiProperty() readonly requisition: SimpleRequisition;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly totals: RequisitionTotals;
  @ApiProperty() readonly parLevel: number;
  @ApiProperty() readonly stockLevel: number;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly supplierReplaced?: boolean;
  @ApiProperty() readonly quantityLevels?: RequisitionItemByLocationQuantityLevels;

  readonly convertedParLevel?: number;

  static deserialize(data: RequisitionItemByLocationResponse): RequisitionItemByLocation {
    return new RequisitionItemByLocation({
      id: data.id,
      packaging: data.packaging,
      retailerItem: data.retailerItem,
      category: data.category,
      selectedChannelItem: data.selectedChannelItem,
      channelItems: data.channelItems,
      requisition: data.requisition,
      quantity: data.quantity,
      totals: data.totals,
      parLevel: data.parLevel,
      stockLevel: data.stockLevel,
      supplierReplaced: data.supplierReplaced,
      quantityLevels: data.quantityLevels,
    });
  }

  static serialize(item: RequisitionItemByLocation): RequisitionItemByLocationRequest {
    return {
      id: item.id,
      quantity: item.quantity,
      requisitionId: item.requisition.id,
      selectedChannelItemId: item.selectedChannelItem.id,
    };
  }

  static serializeArray(items: RequisitionItemByLocation[]): RequisitionItemByLocationRequest[] {
    return items.map(item => RequisitionItemByLocation.serialize(item));
  }
}
