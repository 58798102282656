import { Branch, getApiDetailsDecorator, LocalizedSkeletonObjectType, Supplier } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { GrnItem } from '../grn';
import { AggregatedLinkedOrder } from './aggregated-linked-order.entity';
import { AggregatedOrdersResponse } from './aggregated-orders.model';
import { SimpleChannel } from './common-order.model';

const ApiProperty = getApiDetailsDecorator<AggregatedOrdersResponse>();

export class AggregatedOrders {
  private constructor(args: NonFunctionProperties<AggregatedOrders>) {
    this.supplier = args.supplier;
    this.location = args.location;
    this.channel = args.channel;
    this.linkedOrders = args.linkedOrders;
    this.items = args.items;
    this.outlet = args.outlet;
    this.documentDate = args.documentDate;
  }

  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly location: Branch;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly linkedOrders: AggregatedLinkedOrder[];
  @ApiProperty() readonly items: GrnItem[];
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;
  @ApiProperty() readonly documentDate: Date;

  static deserialize(data: AggregatedOrdersResponse): AggregatedOrders {
    return new AggregatedOrders({
      supplier: data.supplier,
      location: data.location,
      channel: data.channel,
      linkedOrders: data.linkedOrders ? AggregatedLinkedOrder.deserializeList(data.linkedOrders) : [],
      items: data.items ? GrnItem.deserializeList(data.items) : [],
      outlet: data.outlet,
      documentDate: data.documentDate,
    });
  }
}
